import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import React from 'react'
import { Dimensions, FlatList, Platform, TouchableOpacity, View } from 'react-native'
import { ms, mvs, ScaledSheet } from 'react-native-size-matters'
import ModalScreen, { getModalStyleDefinition } from '~components/ModalScreen'
import { Text } from '~components/Themed'
import { isSmallDevice } from '~constants/Layout'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { MediaSourceType, RootStackScreenProps } from '~types'

type Props = RootStackScreenProps<'MediaSourceModal'>

type MediaSource = {
  key: MediaSourceType
  name: string
  icon: IconProp
}

export default function MediaSourceScreen({ navigation, route: { params } }: Props): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const sources: MediaSource[] = []

  if (Platform.OS !== 'web' || (Platform.OS === 'web' && Modernizr.capture)) {
    sources.push({ key: 'camera', name: 'Camera', icon: ['fal', 'camera'] })
  }

  sources.push({ key: 'device', name: 'Device', icon: ['fal', 'photo-video'] })

  sources.push({
    key: 'community',
    name: Dimensions.get('window').width > 640 ? 'Chatta\nCommunity' : 'Chatta Community',
    icon: ['fal', 'hands-heart'],
  })

  sources.push({
    key: 'internet',
    name: Dimensions.get('window').width > 640 ? 'Search the\ninternet' : 'Search the internet',
    icon: ['fal', 'globe'],
  })

  return (
    <ModalScreen
      ContainerComponent={React.Fragment}
      height={Dimensions.get('window').width > 600 ? mvs(400, 0.8) : undefined}>
      <FlatList
        contentContainerStyle={[styles.content]}
        style={styles.list}
        scrollEnabled={false}
        numColumns={isSmallDevice ? 1 : sources.length}
        columnWrapperStyle={isSmallDevice ? undefined : styles.column}
        data={sources}
        ItemSeparatorComponent={() => <View style={styles.listVerticalSeparator} />}
        ListHeaderComponent={() => (
          <View style={styles.titleContainer}>
            <Text style={styles.title}>Media Source</Text>
            <Text style={styles.subtitle}>
              Take a photo, add images from your library or the search the Chatta Community or
              internet
            </Text>
          </View>
        )}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={styles.sourceContainer}
            onPress={() =>
              navigation.navigate({
                name: 'ChatScreen',
                params: {
                  source: item.key,
                  sourceTarget: params.target,
                },
                merge: true,
              })
            }>
            <FontAwesomeIcon
              color={theme.primary}
              style={styles.sourceIcon}
              size={ms(24, 0.9)}
              icon={item.icon}
            />
            <Text style={[styles.sourceText]}>{item.name}</Text>
          </TouchableOpacity>
        )}
      />
    </ModalScreen>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    ...getModalStyleDefinition(theme),
    list: {
      alignSelf: 'center',
    },
    listVerticalSeparator: {
      height: Dimensions.get('window').width > 640 ? '20@mvs' : '10@mvs',
    },
    sourceContainer: {
      backgroundColor: theme.white,
      borderWidth: 1,
      borderColor: theme[300],
      borderRadius: '5@ms',
      marginHorizontal: '10@ms',
      width: Dimensions.get('window').width > 640 ? '90@ms' : undefined,
      flexDirection: Dimensions.get('window').width > 640 ? 'column' : 'row',
      padding: Dimensions.get('window').width > 640 ? '15@ms' : '10@ms',
      alignItems: 'center',
    },
    sourceIcon: {
      marginTop: Dimensions.get('window').width > 640 ? '10@ms' : undefined,
      marginBottom: Dimensions.get('window').width > 640 ? '20@ms' : undefined,
      marginRight: Dimensions.get('window').width > 640 ? undefined : '10@ms',
    },
    sourceText: {
      fontSize: '10@ms',
      textAlign: 'center',
      fontWeight: '700',
      color: theme[800],
    },
    column: {
      justifyContent: 'center',
    },
  })
