import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore'
import * as React from 'react'
import { snapshotToData } from './helper'
import useIsEqualRef from './util/useIsEqualRef'
import usePaginationValue, { PaginationHook } from './util/usePaginationValue'

const { useEffect, useMemo } = React

const DEFAULT_LIMIT = 20

export const usePagination = <T = FirebaseFirestoreTypes.DocumentSnapshot>(
  query?: FirebaseFirestoreTypes.Query | null,
  options?: {
    snapshotListenOptions?: FirebaseFirestoreTypes.SnapshotListenOptions
    limit?: number
  },
): PaginationHook<T> => {
  const {
    loaded,
    loadingMore,
    limit,
    error,
    setError,
    setValue,
    reest,
    value,
    after,
    loadMore,
    hasMore,
  } = usePaginationValue()

  const ref = useIsEqualRef(query, reest)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const stepLimit = options?.limit || DEFAULT_LIMIT
    let queryLimited = ref.current

    // If not the first query then pass startAt and add an extra limit (startAt is inclusive but we could have duplicates)
    if (after !== null) {
      queryLimited = queryLimited.startAt(after).limit(stepLimit + 1)
    } else {
      queryLimited = queryLimited.limit(stepLimit)
    }

    const snapshotOption = options?.snapshotListenOptions
    const listener = snapshotOption
      ? queryLimited.onSnapshot(snapshotOption, setValue(stepLimit), setError)
      : queryLimited.onSnapshot(setValue(stepLimit), setError)

    return () => listener()
  }, [ref.current, after])

  return [
    value,
    {
      loaded,
      loadingMore,
      hasMore,
      loadMore,
    },
    error,
  ]
}

export const usePaginationData = <T>(
  query?: FirebaseFirestoreTypes.Query | null,
  options?: {
    idField?: string
    limit?: number
    snapshotListenOptions?: FirebaseFirestoreTypes.SnapshotListenOptions
  },
): PaginationHook<T> => {
  const idField = options ? options.idField : undefined

  const [snapshot, fields, error] = usePagination(query, {
    snapshotListenOptions: options?.snapshotListenOptions,
    limit: options?.limit,
  })
  const values = useMemo(
    () => (snapshot ? snapshot.map((doc) => snapshotToData(doc, idField)) : undefined) as T[],
    [snapshot, idField],
  )
  return [values, fields, error]
}
