import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { AnyAction, createAction, createReducer } from '@reduxjs/toolkit'
import React, { useState } from 'react'
import {
  BackHandler,
  Dimensions,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  ScaledSize,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
} from 'react-native'
import { ms, ScaledSheet } from 'react-native-size-matters'
import ChattaLogo from '~components/ChattaLogo'
import { Dropdown } from '~components/Dropdown'
import { FormButton } from '~components/FormButton'
import { FormInput } from '~components/FormInput'
import SocialSignIn from '~components/SocialSignIn'
import { Text } from '~components/Themed'
import { ValidationError } from '~errors/ValidationError'
import { auth } from '~providers/firebase'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { MainStackScreenProps, RegisterMetadata } from '~types'
import { useAxiosUnauthenticated } from '~utils/fetch'
import opacity from '~utils/opacity'
type ViewType = 'type' | 'more-info' | 'details'

type UserType =
  | 'teacher'
  | 'student'
  | 'guardian'
  | 'teaching-assistant'
  | 'senior-leader'
  | 'headteacher'

export interface State {
  view: ViewType
  type?: UserType
  areaOfInterest?: string
  challenge?: string
  schoolName?: string
  phoneNumber?: string
  disabled: boolean
}

export const initialState: State = {
  view: 'type',
  disabled: true,
}

export const setView = createAction<ViewType, 'setView'>('setView')
export const setType = createAction<UserType, 'setType'>('setType')
export const setAreaOfInterest = createAction<string, 'setAreaOfInterest'>('setAreaOfInterest')
export const setChallenge = createAction<string, 'setChallenge'>('setChallenge')
export const setSchoolName = createAction<string, 'setSchoolName'>('setSchoolName')
export const setPhoneNumber = createAction<string, 'setPhoneNumber'>('setPhoneNumber')

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setView, (state, action) => {
      state.view = action.payload
    })
    .addCase(setType, (state, action) => {
      state.type = action.payload
    })
    .addCase(setAreaOfInterest, (state, action) => {
      state.areaOfInterest = action.payload
    })
    .addCase(setChallenge, (state, action) => {
      state.challenge = action.payload
    })
    .addCase(setSchoolName, (state, action) => {
      state.schoolName = action.payload
    })
    .addCase(setPhoneNumber, (state, action) => {
      state.phoneNumber = action.payload
    })
    .addMatcher(
      () => true,
      (state) => {
        switch (state.view) {
          case 'type':
            state.disabled = state.type === undefined
            break
          case 'more-info':
            state.disabled =
              state.areaOfInterest === undefined ||
              state.challenge === undefined ||
              state.schoolName === undefined ||
              state.schoolName.length === 0
            break
        }
      },
    ),
)

type Props = MainStackScreenProps<'Register'>

const store = React.createContext<[State, React.Dispatch<AnyAction>]>([initialState, () => {}])
const { Provider } = store
const useRegisterContext = (): [State, React.Dispatch<AnyAction>] => React.useContext(store)

const dismissKeyboard = Platform.select({
  web: () => {},
  default: Keyboard.dismiss,
})

const PickType = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useRegisterContext()

  const next = () => {
    if (
      state.type &&
      ['teacher', 'teaching-assistant', 'senior-leader', 'headteacher'].indexOf(state.type) >= 0
    ) {
      stateDispatch(setView('more-info'))
    } else {
      stateDispatch(setView('details'))
    }
  }

  const roles = [
    'Student',
    'Guardian',
    'Teacher',
    'Teaching Assistant',
    'Senior Leader',
    'Headteacher',
  ].map((v) => {
    return {
      label: v,
      value: v.replace(/\s+/g, '-').toLowerCase(),
    }
  })

  return (
    <>
      <Text style={styles.largeTitle}>Let's get started!</Text>
      <Text style={styles.subtitle}>Only a few steps to creating your Chatta account.</Text>
      <Text style={styles.title}>Are you a…</Text>

      <View style={styles.typeContainer}>
        <Dropdown
          list={roles}
          value={state.type}
          setValue={(value) => stateDispatch(setType(value))}
        />
      </View>

      <FormButton
        onPress={next}
        style={styles.button}
        textStyle={styles.buttonText}
        title={'Continue'}
        disabled={state.disabled}
        inactiveButtonStyle={styles.inactiveButtonStyle}
      />
    </>
  )
}

const MoreInfo = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useRegisterContext()

  const details = () => stateDispatch(setView('details'))

  const areasOfInterest = ['Early Years', 'Primary', 'Secondary', 'Special', 'Other'].map((v) => {
    return {
      label: v,
      value: v.toLowerCase(),
    }
  })

  const challenges = ['Language', 'Vocabulary', 'Disadvantaged Area', 'EAL', 'SEND', 'Writing'].map(
    (v) => {
      return {
        label: v,
        value: v.toLowerCase(),
      }
    },
  )

  return (
    <>
      <Text style={styles.title}>About you…</Text>

      <View style={styles.typeContainer}>
        <Dropdown
          list={areasOfInterest}
          value={state.areaOfInterest}
          placeholder="Area of interest…"
          setValue={(value) => stateDispatch(setAreaOfInterest(value))}
        />
        <Dropdown
          list={challenges}
          value={state.challenge}
          placeholder="Your specific challenge"
          setValue={(value) => stateDispatch(setChallenge(value))}
        />
        <FormInput
          style={styles.input}
          value={state.schoolName}
          placeholder="Your school / organisation name"
          onChangeText={(value) => stateDispatch(setSchoolName(value))}
        />
        <FormInput
          style={styles.input}
          value={state.phoneNumber}
          placeholder="Phone number (optional)"
          keyboardType="phone-pad"
          onChangeText={(value) => stateDispatch(setPhoneNumber(value))}
        />
      </View>

      <FormButton
        onPress={details}
        style={styles.button}
        textStyle={styles.buttonText}
        title={'Continue'}
        disabled={state.disabled}
        inactiveButtonStyle={styles.inactiveButtonStyle}
      />
    </>
  )
}

const CreateAccount = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useRegisterContext()
  const navigation = useNavigation<Props['navigation']>()

  const [registrationSuccess, setRegistrationSuccess] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const [{ data, loading, error }, execute] = useAxiosUnauthenticated(
    {
      url: '/api/v1/register',
      method: 'POST',
    },
    { manual: true },
  )

  const canSubmit =
    name.length > 0 &&
    email.length > 0 &&
    password.length >= 8 &&
    password === passwordConfirmation &&
    !loading &&
    !registrationSuccess

  const metadata: RegisterMetadata = {
    sign_up_role: state.type,
    area_of_interest: state.areaOfInterest,
    specific_challenge: state.challenge,
    customer_name: state.schoolName,
    phone_number: state.phoneNumber,
  }

  const register = async () => {
    try {
      const res = await execute({
        data: {
          name,
          email,
          password,
          password_confirmation: passwordConfirmation,
          metadata,
        },
      })

      if (res.status === 200) {
        setRegistrationSuccess(true)
        await auth().signInWithCustomToken(res.data.token)
      }
    } catch (error) {
      console.info(error)
    }
  }

  const errorPassword = React.useMemo(() => {
    if (error instanceof ValidationError) {
      return error.errors['password']
    }

    const errors = []

    if (password !== passwordConfirmation) {
      errors.push('The password confirmation does not match.')
    }

    if (password.length < 8) {
      errors.push('The password must be at least 8 characters.')
    }

    return errors.length > 0 ? errors : undefined
  }, [error, password, passwordConfirmation])

  const isMobile = Dimensions.get('window').width < 640

  return (
    <View style={styles.columns}>
      <View>
        <Text style={styles.title}>Create Account</Text>
        <FormInput
          style={styles.input}
          value={name}
          placeholder="Full name"
          onChangeText={setName}
          error={error instanceof ValidationError ? error.errors['name'] : undefined}
        />
        <FormInput
          style={styles.input}
          value={email}
          placeholder="Email"
          onChangeText={setEmail}
          autoCapitalize="none"
          keyboardType="email-address"
          autoCorrect={false}
          error={error instanceof ValidationError ? error.errors['email'] : undefined}
        />
        <FormInput
          style={styles.input}
          value={password}
          placeholder="Password"
          onChangeText={setPassword}
          secureTextEntry={true}
          error={errorPassword}
        />
        <FormInput
          style={styles.input}
          value={passwordConfirmation}
          placeholder="Confirm Password"
          onChangeText={setPasswordConfirmation}
          secureTextEntry={true}
        />

        <FormButton
          style={styles.button}
          textStyle={styles.buttonText}
          title={loading ? 'Loading...' : 'Create account'}
          disabled={!canSubmit}
          onPress={register}
          inactiveButtonStyle={styles.inactiveButtonStyle}
        />
      </View>
      <View style={styles.columnSeparator}></View>
      <View>
        {!isMobile && <Text style={styles.title}>Or Sign-in using</Text>}
        <SocialSignIn
          color={theme[400]}
          seperator={isMobile}
          seperatorText="Or Sign-in using:"
          metadata={metadata}
        />
      </View>
    </View>
  )
}

export default function LoginScreen({ navigation }: Props): JSX.Element {
  const { mode, theme } = useTheme()
  const styles = getStyles(theme)
  const windowDimensions = useWindowDimensions()

  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value: [State, React.Dispatch<AnyAction>] = [state, dispatch]

  const [dimensions, setDimensions] = useState({
    window: Dimensions.get('window'),
    screen: Dimensions.get('screen'),
  })

  const onChange = ({ window, screen }: { window: ScaledSize; screen: ScaledSize }) => {
    setDimensions({ window, screen })
  }

  const onBackPress = React.useCallback(() => {
    switch (state.view) {
      case 'type':
        navigation.navigate('Login')
        return true
      case 'more-info':
        dispatch(setView('type'))
        return true
      case 'details':
        dispatch(
          setView(
            state.type &&
              ['teacher', 'teaching-assistant', 'senior-leader', 'headteacher'].indexOf(
                state.type,
              ) >= 0
              ? 'more-info'
              : 'type',
          ),
        )
        return true
      default:
        return false
    }
  }, [navigation, state.type, state.view])

  React.useEffect(() => {
    Dimensions.addEventListener('change', onChange)
    return () => {
      Dimensions.removeEventListener('change', onChange)
    }
  })

  useFocusEffect(
    React.useCallback(() => {
      BackHandler.addEventListener('hardwareBackPress', onBackPress)

      return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress)
    }, [onBackPress]),
  )

  const view = React.useMemo(() => {
    switch (state.view) {
      case 'type':
        return <PickType />
      case 'more-info':
        return <MoreInfo />
      default:
        return <CreateAccount />
    }
  }, [state.view])

  return (
    <View style={styles.container}>
      {windowDimensions.width < 640 && (
        <SafeAreaView>
          <TouchableOpacity onPress={onBackPress}>
            <FontAwesomeIcon
              size={24}
              icon={['fal', 'arrow-left']}
              color={theme.pink[800]}
              style={styles.backButtonMobile}
            />
          </TouchableOpacity>
        </SafeAreaView>
      )}
      <View style={styles.background}></View>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.container}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <TouchableWithoutFeedback onPress={dismissKeyboard}>
            <View style={styles.form}>
              <View style={styles.inner}>
                {windowDimensions.width > 640 && (
                  <View style={styles.navigationHeader}>
                    <TouchableOpacity style={styles.backButtonContainer} onPress={onBackPress}>
                      <FontAwesomeIcon
                        size={ms(12)}
                        icon={['fal', 'chevron-left']}
                        color={theme.pink[800]}
                      />
                      <Text style={styles.backText}>Back</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                      <Text style={styles.backToLoginText}>Already using Chatta? Log in here</Text>
                    </TouchableOpacity>
                    <View style={styles.flexOne}></View>
                  </View>
                )}
                <View style={styles.content}>
                  <View style={styles.formBackground}>
                    <View style={styles.logo}>
                      <ChattaLogo solid={true} />
                    </View>
                    <Provider value={value}>{view}</Provider>
                  </View>
                  {windowDimensions.width < 640 && (
                    <View style={styles.signUpBackground}>
                      <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                        <Text style={[styles.loginText]}>Already using Chatta? Log in here</Text>
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    container: {
      flex: 1,
    },
    inner: {
      flex: 1,
      alignSelf: 'center',
      paddingHorizontal: '20@mvs',
      paddingVertical: '20@mvs',
      alignItems: 'center',
    },
    form: {
      flex: 1,
    },
    input: {
      width: '260@ms0.3',
      backgroundColor: theme.white,
    },
    button: {
      width: '260@ms0.3',
      alignSelf: 'center',
    },
    buttonText: {
      color: theme.white,
    },
    title: {
      fontSize: '18@ms',
      fontWeight: '600',
      color: theme.text,
      marginBottom: '10@s',
      textAlign: 'center',
    },
    largeTitle: {
      fontSize: '22@ms',
      fontWeight: '600',
      color: theme[600],
      marginBottom: '4@s',
      textAlign: 'center',
    },
    subtitle: {
      fontSize: '10@ms0.6',
      color: theme[600],
      marginBottom: '10@s',
      textAlign: 'center',
    },
    background: {
      flex: 1,
      position: 'absolute',
      top: Dimensions.get('screen').height * 0.55,
      bottom: 0,
      width: '100%',
      backgroundColor: theme[300],
    },
    cancelButton: {
      textAlign: 'center',
      color: theme.primary,
      paddingVertical: 5,
      marginTop: 15,
    },
    forgotPasswordText: {
      textAlign: 'center',
      color: theme.white,
    },
    formBackground: {
      alignItems: 'center',
      width: Dimensions.get('window').width < 640 ? '300@ms' : '480@ms0.5',
      backgroundColor: theme[100],
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
      paddingHorizontal: '20@ms',
      paddingVertical: '10@vs',
      paddingTop: '45@ms0.4',
      elevation: 1,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 4,
      shadowColor: opacity(theme.black, 0.16),
      marginTop: '30@mvs0.4',
      borderBottomLeftRadius: Dimensions.get('window').width < 640 ? 0 : '5@ms',
      borderBottomRightRadius: Dimensions.get('window').width < 640 ? 0 : '5@ms',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    typeContainer: {
      flexDirection: 'column',
      flex: 1,
      marginBottom: '10@s',
      width: '100%',
      alignItems: 'center',
    },
    logo: {
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      position: 'absolute',
      top: '-32@ms0.4',
    },
    backToLoginText: {
      color: theme.primary,
      fontSize: '12@ms',
    },
    signUpBackground: {
      width: Dimensions.get('window').width < 640 ? '300@ms' : '480@ms0.5',
      backgroundColor: theme.white,
      borderBottomLeftRadius: '5@ms',
      borderBottomRightRadius: '5@ms',
      fontWeight: '400',
      elevation: 1,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 4,
      shadowColor: opacity(theme.black, 0.16),
    },
    loginText: {
      textAlign: 'center',
      color: theme.primary,
      fontSize: '10@ms0.4',
      fontWeight: '400',
      paddingHorizontal: '8@ms',
      paddingVertical: '8@mvs',
    },
    content: {
      flexGrow: 1,
      marginTop: '50@mvs',
    },
    instructions: {
      marginBottom: '10@s',
      fontSize: '12@ms0.4',
    },
    error: {
      textAlign: 'center',
      color: theme.primary,
      marginBottom: '10@s',
      fontSize: '12@ms0.4',
    },
    inputDescription: {
      textAlign: 'left',
      marginBottom: '16@mvs',
      fontSize: '11@ms0.4',
      fontStyle: 'italic',
      color: theme[500],
    },
    inactiveButtonStyle: {
      backgroundColor: theme[400],
      borderColor: theme[400],
    },
    columns: {
      flexDirection: Dimensions.get('window').width < 640 ? 'column' : 'row',
    },
    columnSeparator: {
      marginHorizontal: '10@ms',
      width: 1,
      backgroundColor: theme[300],
    },
    navigationHeader: {
      flexDirection: 'row',
      width: Dimensions.get('window').width < 880 ? Dimensions.get('window').width : '100%',
      alignItems: 'center',
    },
    backText: {
      color: theme.pink[800],
      fontSize: '12@ms',
    },
    backButtonContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    flexOne: {
      flex: 1,
    },
    backButtonMobile: {
      marginTop: '10@s',
      marginLeft: '10@s',
    },
  })
