import React, { useEffect, useState } from 'react'
import { Status } from '../types'
import DiagnosticsResult from '../DiagnosticsResult'
import { auth as firebaseAuth } from '~/providers/firebase'

export default function FirebaseAuthenticationChecker(): JSX.Element {
  const [status, setStatus] = useState<Status>('inprogress')
  const [error, setError] = useState<Error>()

  useEffect(() => {
    let cancel = false

    async function auth() {
      try {
        await firebaseAuth().signInWithCustomToken('ping.ping.ping')

        // Impossible
        if (!cancel) {
          setStatus('failed')
          setError(undefined)
        }
      } catch (error) {
        if (!cancel) {
          if (error.code === 'auth/invalid-custom-token') {
            setStatus('success')
            setError(undefined)
          } else {
            setStatus('failed')
            setError(error)
          }
        }
      }
    }

    auth()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <DiagnosticsResult
      title="Firebase Authentication"
      description="Connect to https://www.googleapis.com"
      status={status}
      error={error}
    />
  )
}
