import React, { Component } from 'react'
import ReactNative, {
  Animated,
  LayoutChangeEvent,
  NativeModules,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from 'react-native'
import { createResponder } from 'react-native-gesture-responder'
import {
  fitCenterRect,
  getTransform,
  Rect,
  Transform,
  transformedRect,
} from 'react-native-view-transformer-next/library/transform/TransformUtils'
import { CacheableImage } from '~components/CacheableImage'
import { isSmallDevice } from '~constants/Layout'
import { MAX_IMAGE_SCALE, MIN_IMAGE_SCALE } from '~constants/Settings'
import { SetImageAnimationProps } from '~redux/chat/types'
import { defaultColors } from '~theme'
import WebImage from './WebImage.web'

const CLAMP_PERCENTAGE = 0.2

type ChatImageProps = {
  index: number
  isSelected: boolean
  image: string
  scale?: number
  translateX?: number
  translateY?: number
  containerSize: {
    width: number
    height: number
  }
  selectImage: (index: number) => void
  contentAspectRatio: number
  onLayout: (e: LayoutChangeEvent) => void
  updateAnimationData: (
    payload: Pick<SetImageAnimationProps, 'scale' | 'translateX' | 'translateY'>,
  ) => void
  isTemplate: boolean
}

type ChatImageState = {
  source: { uri: string }
  scale: number
  translateX: number
  translateY: number
  width: number
  height: number
  pageX: number
  pageY: number
  scaledWidth: number
  scaledHeight: number
}

type Transform = {
  scale?: number
  translateX?: number
  translateY?: number
}

export default class ChatImage extends Component<ChatImageProps, ChatImageState> {
  mounted = false
  viewPortRect: Rect

  panResponder: any

  constructor(props: ChatImageProps) {
    super(props)

    this.state = {
      source: { uri: this.props.image },
      scale: this.props.scale || 1,
      translateX: (this.props.translateX || 0) * this.props.containerSize.width,
      translateY: (this.props.translateY || 0) * this.props.containerSize.height,

      width: 0,
      height: 0,
      pageX: 0,
      pageY: 0,
      scaledWidth: 0,
      scaledHeight: 0,
    }
    this.viewPortRect = new Rect() // A holder to avoid new too much

    this.contentRect = this.contentRect.bind(this)
    this.transformedContentRect = this.transformedContentRect.bind(this)
  }

  componentDidMount(): void {
    this.mounted = true
    this.panResponder = createResponder({
      onStartShouldSetResponder: (evt, gestureState) =>
        !this.props.isTemplate && (!isSmallDevice || this.props.isSelected),
      onMoveShouldSetResponderCapture: (evt, gestureState) =>
        !this.props.isTemplate && (!isSmallDevice || this.props.isSelected),

      onResponderGrant: () => {
        this.measureLayout()
      },

      onResponderMove: (evt, gestureState) => {
        let dx = gestureState.moveX - gestureState.previousMoveX
        let dy = gestureState.moveY - gestureState.previousMoveY

        let transform: Transform = {}
        if (gestureState.previousPinch && gestureState.pinch) {
          const scaleBy = gestureState.pinch / gestureState.previousPinch
          const pivotX = gestureState.moveX - this.state.pageX
          const pivotY = gestureState.moveY - this.state.pageY

          const rect = transformedRect(
            transformedRect(this.contentRect(), this.currentTransform()),
            new Transform(scaleBy, dx, dy, {
              x: pivotX,
              y: pivotY,
            }),
          )
          transform = getTransform(this.contentRect(), rect)
        } else {
          if (Math.abs(dx) > 2 * Math.abs(dy)) {
            dy = 0
          } else if (Math.abs(dy) > 2 * Math.abs(dx)) {
            dx = 0
          }
          transform.translateX = this.state.translateX + dx / this.state.scale
          transform.translateY = this.state.translateY + dy / this.state.scale
        }

        this.updateTransform(transform)
        return true
      },

      onResponderRelease: () => {
        const data = {
          scale: this.state.scale,
          translateX: this.state.translateX / this.props.containerSize.width,
          translateY: this.state.translateY / this.props.containerSize.height,
        }
        this.props.updateAnimationData(data)
      },
    })
  }

  componentWillUnmount(): void {
    this.mounted = false
  }

  componentDidUpdate(prevProps: ChatImageProps, prevState: ChatImageState): void {
    if (!this.mounted) {
      return
    }

    if (prevState.source.uri !== this.props.image) {
      this.setState({
        source: { uri: this.props.image },
      })
    }

    if (this.props.containerSize !== prevProps.containerSize) {
      const ratioW = this.props.containerSize.width / prevState.width
      const ratioH = this.props.containerSize.height / prevState.height
      const best = Math.min(ratioW, ratioH)

      const targetWidth = Math.floor(prevState.width * best || 0)
      const targetHeight = Math.floor(prevState.height * best || 0)

      this.setState({
        translateX: (this.props.translateX || 0) * this.props.containerSize.width,
        translateY: (this.props.translateY || 0) * this.props.containerSize.height,
        scaledWidth: targetWidth,
        scaledHeight: targetHeight,
      })
    }

    if (
      this.props.scale !== prevProps.scale ||
      this.props.translateX !== prevProps.translateX ||
      this.props.translateY !== prevProps.translateY
    ) {
      this.setState({
        scale: this.props.scale || 1,
        translateX: (this.props.translateX || 0) * this.props.containerSize.width,
        translateY: (this.props.translateY || 0) * this.props.containerSize.height,
      })
    }
  }

  onLoad = (e) => {
    if (Platform.OS === 'web') {
      if (e.nativeEvent.target) {
        this.imageLoaded({
          width: e.nativeEvent.target.naturalWidth,
          height: e.nativeEvent.target.naturalHeight,
        })
      } else if (e.nativeEvent.hasOwnProperty('path') && e.nativeEvent.path[0]) {
        this.imageLoaded(e.nativeEvent.path[0])
      }
    }
  }

  imageLoaded = ({ width, height }): void => {
    if (!this.mounted) {
      return
    }

    // Calculate size/width that is contained in the container
    const ratioW = this.props.containerSize.width / width
    const ratioH = this.props.containerSize.height / height
    const best = Math.min(ratioW, ratioH)

    const targetWidth = Math.floor(width * best || 0)
    const targetHeight = Math.floor(height * best || 0)

    this.setState({
      width,
      height,
      scaledWidth: targetWidth,
      scaledHeight: targetHeight,
    })

    this.measureLayout()
  }

  updateTransform = (transform: Required<Transform>): void => {
    if (!this.mounted) {
      return
    }

    this.setState((state) => {
      const scale = Math.min(
        Math.max(transform.scale || state.scale, MIN_IMAGE_SCALE),
        MAX_IMAGE_SCALE,
      )

      const clamp = {
        width:
          Math.max(
            0,
            Math.floor((this.props.containerSize.width + state.scaledWidth * scale) * 0.5) -
              this.props.containerSize.width * CLAMP_PERCENTAGE,
          ) / scale,
        height:
          Math.max(
            0,
            Math.floor((this.props.containerSize.height + state.scaledHeight * scale) * 0.5) -
              this.props.containerSize.height * CLAMP_PERCENTAGE,
          ) / scale,
      }

      return {
        ...transform,
        scale,
        translateX: Math.min(Math.max(transform.translateX, -clamp.width), clamp.width),
        translateY: Math.min(Math.max(transform.translateY, -clamp.height), clamp.height),
      }
    })
  }

  currentTransform = (): Transform => {
    return new Transform(this.state.scale, this.state.translateX, this.state.translateY)
  }

  onLayout = (e: LayoutChangeEvent): void => {
    this.measureLayout()

    this.props.onLayout && this.props.onLayout(e)
  }

  measureLayout = (): void => {
    if (!this.mounted) {
      return
    }

    if (Platform.OS === 'web') {
    } else {
      const handle = ReactNative.findNodeHandle(this.component)
      if (handle) {
        NativeModules.UIManager.measure(handle, (x, y, width, height, pageX, pageY) => {
          if (typeof pageX === 'number' && typeof pageY === 'number') {
            // avoid undefined values on Android devices
            if (this.state.pageX !== pageX || this.state.pageY !== pageY) {
              if (!this.mounted) {
                return
              }
              this.setState({
                pageX,
                pageY,
              })
            }
          }
        })
      }
    }
  }

  selectImage = (): void => {
    if (this.props.isSelected) {
      this.props.selectImage(-1)
    } else {
      this.props.selectImage(this.props.index)
    }
  }

  updateViewPortRect(): Rect {
    this.viewPortRect.set(0, 0, this.state.width, this.state.height)
    return this.viewPortRect
  }

  contentRect(): Rect {
    let rect = this.updateViewPortRect().copy()
    if (this.props.contentAspectRatio && this.props.contentAspectRatio > 0) {
      rect = fitCenterRect(this.props.contentAspectRatio, rect)
    }
    return rect
  }

  transformedContentRect(): Rect {
    let rect = transformedRect(this.updateViewPortRect(), this.currentTransform())
    if (this.props.contentAspectRatio && this.props.contentAspectRatio > 0) {
      rect = fitCenterRect(this.props.contentAspectRatio, rect)
    }
    return rect
  }

  render(): JSX.Element {
    const { props } = this
    return (
      <Animated.View
        ref={(c) => (this.component = c)}
        {...this.panResponder}
        onLayout={this.onLayout}
        style={{
          width: this.state.scaledWidth * this.state.scale,
          height: this.state.scaledHeight * this.state.scale,
          transform: [
            { translateX: this.state.translateX * this.state.scale },
            { translateY: this.state.translateY * this.state.scale },
          ],
        }}>
        <TouchableOpacity
          activeOpacity={0.4}
          onPress={this.selectImage}
          disabled={props.isTemplate}>
            {Platform.OS === 'web' ? <WebImage 
            source={this.state.source}
            style={[
              {
                width: this.state.scaledWidth * this.state.scale,
                height: this.state.scaledHeight * this.state.scale,
              },
              props.isSelected ? styles.selectedObject : {},
            ]}
            resizeMode="contain"
            onLoadFinished={this.imageLoaded}
            onLoad={this.onLoad} /> : 
          <CacheableImage
            source={this.state.source}
            style={[
              {
                width: this.state.scaledWidth * this.state.scale,
                height: this.state.scaledHeight * this.state.scale,
              },
              props.isSelected ? styles.selectedObject : {},
            ]}
            resizeMode="contain"
            onLoadFinished={this.imageLoaded}
            onLoad={this.onLoad}
          />}
        </TouchableOpacity>
      </Animated.View>
    )
  }
}

const styles = StyleSheet.create({
  selectedObject: {
    borderWidth: 3,
    borderColor: defaultColors.primary,
  },
})
