import React, { CSSProperties, useEffect } from 'react';


function WebImage({source, onLoad, style = []}): JSX.Element | null {  
  const uri = source?.uri || null
  if (!uri) return null;

  const styled = style.flat().reduce((accumulator, current) => {
      Object.assign(accumulator, current)
      return accumulator;
  }, Object.assign({}, {}));

  return (
      <img
        src={uri || undefined}
        key={uri}
        onLoad={(event) => {
          if (onLoad !== undefined) {
            onLoad(event, event.target)
          }
        }}
        style={styled}
        draggable={false}
      />
  )
}

export default WebImage;