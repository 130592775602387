import * as React from 'react'
import { Text, TouchableOpacity, TouchableOpacityProps, View } from 'react-native'
import { ColorSchemeName } from 'react-native'
import { ScaledSheet } from 'react-native-size-matters'
import { CommunityImageSearchResource, SearchEngineImageSearchResource } from '~api/types'
import useComponentSize from '~hooks/useComponentSize'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { CacheableImage } from './CacheableImage'
import { Tag } from './Tag'

export interface ImageSearchItemProps extends TouchableOpacityProps {
  item: SearchEngineImageSearchResource | CommunityImageSearchResource
}

export function ImageSearchItem(props: ImageSearchItemProps): JSX.Element {
  const { mode, theme } = useTheme()
  const styles = getStyles(mode, theme)
  const [size, onLayout] = useComponentSize()
  const { style, item, ...rest } = props

  const aspect = React.useMemo(() => {
    const aspectRatio = item.width / item.height

    const { width = 0, height = 0 } = size
    if (width === 0) {
      return { height, width: height * aspectRatio }
    } else {
      return { height: width / aspectRatio, width }
    }
  }, [item.height, item.width, size])

  const aspectStyle = {
    height: aspect.height,
    flex: 1,
  }

  const isCommunity = (
    item: SearchEngineImageSearchResource | CommunityImageSearchResource,
  ): item is CommunityImageSearchResource => {
    return Array.isArray((item as CommunityImageSearchResource).tags)
  }

  const renderInfo = isCommunity(item) ? (
    <View style={styles.tags}>
      {item.tags.map((tag) => (
        <Tag key={`${item.id}-${tag.name}`} title={tag.name} />
      ))}
    </View>
  ) : (
    <>
      <Text style={styles.text}>
        {item.width} x {item.height}
      </Text>
      <Text style={styles.text}>{item.hostPageDomainFriendlyName}</Text>
    </>
  )

  if (isCommunity(item)) {
    // Only image touchable (may wish to make tags clickable in the future)
    return (
      <View onLayout={onLayout} style={style}>
        <View style={aspectStyle}>
          <TouchableOpacity style={styles.pressable} {...rest}>
            <CacheableImage source={{ uri: item.thumbnail }} style={aspectStyle} />
          </TouchableOpacity>
        </View>
        <View style={styles.pressable}>{renderInfo}</View>
      </View>
    )
  }

  // All touchable
  return (
    <View onLayout={onLayout} style={style}>
      <TouchableOpacity style={styles.pressable} {...rest}>
        <View style={aspectStyle}>
          <CacheableImage source={{ uri: item.thumbnail }} style={aspectStyle} />
        </View>
        <View style={styles.info}>{renderInfo}</View>
      </TouchableOpacity>
    </View>
  )
}

const getStyles = (mode: ColorSchemeName, theme: Theme) =>
  ScaledSheet.create({
    pressable: {
      flex: 1,
      backgroundColor: theme.white,
      marginHorizontal: '2.5@mvs0.8',
    },
    text: {
      fontSize: '10@ms0.2',
      color: theme[400],
    },
    info: {
      flexGrow: 1,
      paddingVertical: '5@mvs',
      paddingHorizontal: '5@ms',
      backgroundColor: theme.white,
    },
    tags: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  })
