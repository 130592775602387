import React, { useEffect, useState } from 'react'
import { Status } from '../types'
import DiagnosticsResult from '../DiagnosticsResult'

export default function ApiChecker(): JSX.Element {
  const [status, setStatus] = useState<Status>('inprogress')
  const [error, setError] = useState<Error>()

  useEffect(() => {
    let cancel = false

    async function ping() {
      try {
        const result = await fetch(`${process.env.EXPO_API_HOSTNAME}/api/v1/ping`)

        if (
          result.status !== 200 ||
          JSON.stringify({ ping: 'pong' }) !== JSON.stringify(await result.json())
        ) {
          throw new Error(
            `Unexpected API response [code=${result.status} data=${JSON.stringify(
              await result.json(),
            )}]`,
          )
        }

        if (!cancel) {
          setStatus('success')
          setError(undefined)
        }
      } catch (error) {
        if (!cancel) {
          setStatus('failed')
          setError(error)
        }
      }
    }

    ping()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <DiagnosticsResult
      title="Chatta API"
      description={`Connect to ${process.env.EXPO_API_HOSTNAME}`}
      status={status}
      error={error}
    />
  )
}
