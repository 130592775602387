import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { SWRConfig, SWRConfiguration } from 'swr'
import { Fetcher } from 'swr/dist/types'
import { fetchUnauthenticated } from '~utils/fetch'
import LoginScreen from '~screens/LoginScreen'
import RegisterScreen from '~screens/RegisterScreen'
import { Platform } from 'react-native'
import DiagnosticsScreen from '~screens/diagnostics/DiagnosticsScreen'

const Stack = createStackNavigator()

export default function UnauthenticatedStack(): JSX.Element {
  const swrConfig: Partial<SWRConfiguration<any, any, Fetcher<any>>> = {
    fetcher: fetchUnauthenticated,
    shouldRetryOnError: false,
  }

  return (
    <SWRConfig value={swrConfig}>
      <Stack.Navigator initialRouteName="Login">
        <Stack.Screen name="Login" component={LoginScreen} options={{ header: () => null }} />
        <Stack.Screen name="Register" component={RegisterScreen} options={{ header: () => null }} />
        {Platform.OS !== 'web' && <Stack.Screen name="Diagnostics" component={DiagnosticsScreen} />}
      </Stack.Navigator>
    </SWRConfig>
  )
}
