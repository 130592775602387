import React from 'react'
import {
  Text as DefaultText,
  TextInput as DefaultTextInput,
  View as DefaultView,
} from 'react-native'
import { useTheme } from '~theme/ThemeManager'

export type TextProps = DefaultText['props']
export type ViewProps = DefaultView['props']
export type TextInputProps = DefaultTextInput['props']

export function Text(props: DefaultText['props']): JSX.Element {
  const { style, ...otherProps } = props
  const { theme } = useTheme()

  return <DefaultText style={[{ color: theme.black }, style]} {...otherProps} />
}

export function View(props: DefaultView['props']): JSX.Element {
  const { style, ...otherProps } = props
  const { mode, theme } = useTheme()

  return (
    <DefaultView
      style={[{ backgroundColor: mode === 'dark' ? theme.white : 'transparent' }, style]}
      {...otherProps}
    />
  )
}

export function ViewDark(props: DefaultView['props']): JSX.Element {
  const { style, ...otherProps } = props
  const { theme } = useTheme()

  return <DefaultView style={[{ backgroundColor: theme[200] }, style]} {...otherProps} />
}

export const TextInput = React.forwardRef<DefaultTextInput, DefaultTextInput['props']>(
  (props, ref) => {
    const { style, ...otherProps } = props
    const { theme } = useTheme()

    return (
      <DefaultTextInput
        ref={ref}
        style={[{ borderColor: theme[200], color: theme.text }, style]}
        placeholderTextColor={theme[300]}
        {...otherProps}
      />
    )
  },
)
