import { AnyAction, createAction, createReducer } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { mvs, ScaledSheet } from 'react-native-size-matters'
import { CacheableImage } from '~components/CacheableImage'
import { FormButton } from '~components/FormButton'
import { FormInput } from '~components/FormInput'
import Loading from '~components/Loading'
import ModalScreen, { getModalStyleDefinition, ModalFooter } from '~components/ModalScreen'
import SortableGrid from '~components/SortableGrid'
import TabbedMenu from '~components/TabbedMenu'
import { Text, View } from '~components/Themed'
import { firestore } from '~providers/firebase'
import ChatService from '~services/chat'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { ExistingChat, RootStackScreenProps } from '~types'

type Props = RootStackScreenProps<'EditChatModal'>

export type EditChatViewType = 'details' | 'order'

export interface State {
  path: string
  view: EditChatViewType
  isLoading: boolean
  chat?: ExistingChat
}

export const initialState: State = {
  path: '',
  view: 'details',
  isLoading: true,
  chat: undefined,
}

export const setView = createAction<EditChatViewType>('setView')
export const setLoading = createAction<boolean>('setLoading')
export const setChat = createAction<ExistingChat>('setChat')

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setView, (state, action) => {
      state.view = action.payload
    })
    .addCase(setLoading, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(setChat, (state, action) => {
      state.chat = action.payload
      state.isLoading = false
    }),
)
const store = React.createContext<[State, React.Dispatch<AnyAction>]>([initialState, () => {}])
const { Provider } = store
const useEditChatContext = (): [State, React.Dispatch<AnyAction>] => React.useContext(store)

export default function EditChatScreen({ navigation, route: { params } }: Props): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    path: params.path,
  })
  const value: [State, React.Dispatch<AnyAction>] = [state, dispatch]

  const labels: EditChatViewType[] = ['details', 'order']
  const [name, setName] = useState('')

  const renderActiveTab = () => {
    switch (state.view) {
      case 'order':
        return <Order />
      case 'details':
      default:
        return (
          <KeyboardAwareScrollView>
            <View style={styles.inner}>
              <View style={styles.content}>
                <View style={styles.titleContainer}>
                  <Text style={styles.title}>Edit Chat</Text>
                </View>
                <View>
                  <FormInput
                    label="Name of chat"
                    value={name}
                    onChangeText={(text) => setName(text)}
                    maxLength={128}
                  />
                </View>
              </View>
            </View>
          </KeyboardAwareScrollView>
        )
    }
  }

  useEffect(() => {
    dispatch(setLoading(true))
    let cancel = false

    const subscriber = firestore()
      .doc(params.path)
      .onSnapshot((snapshot) => {
        if (!snapshot.exists) {
          navigation.goBack()
        } else if (!cancel) {
          const chat = ChatService.chatFromSnapshot(snapshot)
          dispatch(setChat(chat))
        }
      }, navigation.goBack)

    return () => {
      cancel = true
      subscriber()
    }
  }, [dispatch, navigation, params.path])

  const editChat = async () => {
    dispatch(setLoading(true))

    await ChatService.updateChat(state.path, {
      name,
    })

    navigation.goBack()
  }

  useEffect(() => {
    setName(state.chat?.name ?? '')
  }, [state.chat, setName])

  return (
    <Provider value={value}>
      <ModalScreen
        ContainerComponent={React.Fragment}
        Footer={
          <ModalFooter>
            <View></View>
            <FormButton
              title="Update Chat"
              onPress={editChat}
              disabled={state.isLoading || name.length === 0 || name === state.chat?.name}
            />
          </ModalFooter>
        }>
        <TabbedMenu<EditChatViewType>
          labels={labels}
          active={state.view}
          onPress={(index: number) => dispatch(setView(labels[index]))}
        />
        {!state.chat ? <Loading /> : renderActiveTab()}
      </ModalScreen>
    </Provider>
  )
}

const Order = () => {
  const { theme } = useTheme()
  const [{ chat }, dispatch] = useEditChatContext()
  const styles = getStyles(theme)

  const sections = Object.entries(chat?.sections ?? {})
    .sort(([, a], [, b]) => (a.order ?? 0) - (b.order ?? 0))
    .map(([idx, section]) => ({
      key: idx,
      source: section.image.src ? { uri: section.image.src } : undefined,
    }))

  const onDragRelease = async ({
    itemOrder,
  }: {
    itemOrder: {
      key: string
      order: number
    }[]
  }) => {
    if (!chat) {
      return
    }

    // Skip if the same order
    const original = sections.map((o) => o.key)
    const updated = itemOrder.map((o) => o.key)
    if (JSON.stringify(original) === JSON.stringify(updated)) {
      return
    }

    const updates = itemOrder.reduce((accumulator: Record<string, number>, item) => {
      accumulator[`sections.${item.key}.order`] = item.order
      return accumulator
    }, {})

    await firestore().doc(chat.path).update(updates)
  }

  return (
    <View style={styles.inner}>
      <View style={styles.content}>
        <Text style={styles.subtitle}>Drag chat frames to reorder</Text>
        <View style={styles.orderContainer}>
          <Text></Text>
          <SortableGrid itemsPerRow={chat?.layout.columns} onDragRelease={onDragRelease}>
            {sections.map(({ key, source }) => {
              return (
                <View key={key} style={styles.chatSectionContainer}>
                  <View style={styles.chatSection}>
                    {source && (
                      <CacheableImage
                        source={source}
                        style={{ width: '100%', height: '100%' }}
                        resizeMode="contain"
                      />
                    )}
                  </View>
                </View>
              )
            })}
          </SortableGrid>
        </View>
      </View>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    ...getModalStyleDefinition(theme),
    label: {
      fontSize: '10@ms0.3',
      fontWeight: '500',
      marginBottom: '3@mvs',
      color: theme[500],
    },
    orderContainer: {
      width: Math.floor(Math.max(Dimensions.get('window').width / 3.5, mvs(280))),
      alignSelf: 'center',
    },
    chatSectionContainer: {
      flex: 1,
      padding: '2@mvs',
    },
    chatSection: {
      flex: 1,
      borderColor: theme[200],
      borderWidth: 1,
      backgroundColor: theme.white,
    },
  })
