import { useSelector } from 'react-redux'
import { ApplicationState } from '~redux'
import { Profile } from '~types'

type PermissionResult = {
  status: 'allowed' | 'denied'
  message?: string
}

const usePermission = (
  permission: keyof Profile['permissions'],
  current: any = undefined,
): PermissionResult => {
  const profile = useSelector((state: ApplicationState) => state.auth.profile)

  const restriction = profile.permissions[permission]

  switch (permission) {
    case 'restrictions_max_classes': {
      const status =
        restriction === -1 || (current ?? profile.count_classes) < restriction
          ? 'allowed'
          : 'denied'

      if (status === 'allowed') {
        return { status }
      }

      return {
        status,
        message:
          restriction > 1
            ? `Sorry you are only allowed to create ${restriction} folders.`
            : `Sorry you are only allowed to create ${restriction} folder.`,
      }
    }
    case 'restrictions_max_chats_per_class': {
      if (current === undefined) {
        throw new Error('Current folder chats not provided')
      }
      const status = restriction === -1 || current < restriction ? 'allowed' : 'denied'

      if (status === 'allowed') {
        return { status }
      }

      return {
        status,
        message:
          restriction > 1
            ? `Sorry you are only allowed to create ${restriction} chats per folder.`
            : `Sorry you are only allowed to create ${restriction} chat per folder.`,
      }
    }
    case 'restrictions_max_students_per_classes': {
      if (current === undefined) {
        throw new Error('Current members belonging to folder not provided')
      }

      const status = restriction === -1 || current < restriction ? 'allowed' : 'denied'

      if (status === 'allowed') {
        return { status }
      }

      return {
        status,
        message:
          restriction > 1
            ? `Sorry you are only allowed ${restriction} members per folder.`
            : `Sorry you are only allowed ${restriction} member per folder.`,
      }
    }
    default: {
      throw new Error(`Permission "${permission} not handled"`)
    }
  }
}

export default usePermission
