import * as React from 'react'
import { mvs } from 'react-native-size-matters'
import Svg, { Path, SvgProps } from 'react-native-svg'

const IconCreateAChatAlt = (props: SvgProps): JSX.Element => (
  <Svg width={mvs(90, 0.8)} height={mvs(90, 0.8)} viewBox="0 0 98 107.922" {...props}>
    <Path
      data-name="Path 1169"
      d="M79.932 47.584a19.985 19.985 0 0 1-19.983-19.883H8.265A5.179 5.179 0 0 0 3.1 32.864V82.82a5.179 5.179 0 0 0 5.164 5.164H76a5.179 5.179 0 0 0 5.163-5.164v-35.3c-.41.025-.817.062-1.234.062"
      fill="#e2e2e8"
    />
    <Path
      data-name="Path 1170"
      d="M79.932 47.584c-.061 0-.12-.009-.181-.009V82.82A3.753 3.753 0 0 1 76 86.569H8.264a3.752 3.752 0 0 1-3.747-3.749V32.864a3.752 3.752 0 0 1 3.747-3.748h51.757c-.038-.5-.077-1.007-.077-1.52 0-.442.038-.875.066-1.31H8.264a6.585 6.585 0 0 0-6.577 6.578V82.82a6.585 6.585 0 0 0 6.577 6.581H76a6.585 6.585 0 0 0 6.579-6.578V47.388a19.958 19.958 0 0 1-2.649.2"
      fill="#444155"
    />
    <Path
      data-name="Path 1171"
      d="M79.932 47.584c-.061 0-.12-.009-.181-.009V82.82A3.753 3.753 0 0 1 76 86.569H8.264a3.752 3.752 0 0 1-3.747-3.749V32.864a3.752 3.752 0 0 1 3.747-3.748h51.757c-.038-.5-.077-1.007-.077-1.52 0-.442.038-.875.066-1.31H8.264a6.585 6.585 0 0 0-6.577 6.578V82.82a6.585 6.585 0 0 0 6.577 6.581H76a6.585 6.585 0 0 0 6.579-6.578V47.388a19.958 19.958 0 0 1-2.647.196Z"
      fill="none"
      stroke="#444155"
      strokeMiterlimit={10}
      strokeWidth={0.765}
    />
    <Path
      data-name="Path 1172"
      d="M96.327 27.596a16.4 16.4 0 1 1-16.395-16.395 16.395 16.395 0 0 1 16.4 16.395"
      fill="#e2e2e8"
    />
    <Path
      data-name="Path 1173"
      d="M79.932 45.768A18.067 18.067 0 1 1 98 27.701a18.064 18.064 0 0 1-18.068 18.067m0-33.8a15.737 15.737 0 1 0 15.736 15.733 15.736 15.736 0 0 0-15.736-15.737m9.325 16.174a.877.877 0 0 1-.875.874h-7.139v7.14a.877.877 0 0 1-.875.874H79.5a.877.877 0 0 1-.875-.874v-7.14h-7.144a.878.878 0 0 1-.875-.874v-.875a.878.878 0 0 1 .875-.874h7.139v-7.14a.877.877 0 0 1 .875-.874h.873a.877.877 0 0 1 .875.874v7.14h7.139a.877.877 0 0 1 .875.874Z"
      fill="#444155"
    />
    <Path
      data-name="Line 66"
      fill="none"
      stroke="#444155"
      strokeMiterlimit={10}
      strokeWidth={2.83}
      d="M42.134 27.7v60.283"
    />
    <Path
      data-name="Line 67"
      fill="none"
      stroke="#444155"
      strokeMiterlimit={10}
      strokeWidth={2.83}
      d="M3.101 57.842h78.065"
    />
    <Path
      data-name="Path 1174"
      d="m10.772 10.776-1.658 6.632a.3.3 0 0 1-.592 0l-1.658-6.632L.233 9.119a.305.305 0 0 1 0-.593l6.634-1.657L8.525.233a.3.3 0 0 1 .592 0l1.661 6.636 6.633 1.657a.306.306 0 0 1 0 .593Z"
      fill="#fff"
    />
    <Path
      data-name="Path 1175"
      d="M3.371 3.763a.274.274 0 0 1 .386-.386l2.372 1.9-.172.686-.686.172Zm2.589 7.924.172.685-2.369 1.9a.274.274 0 0 1-.386-.386l1.893-2.373Zm5.729-5.729-.174-.686 2.371-1.895a.274.274 0 0 1 .386.386l-1.9 2.37Zm2.583 7.925a.274.274 0 0 1-.386.386l-2.371-1.9.174-.685.684-.173Z"
      fill="#dfdee5"
    />
    <Path
      data-name="Path 1176"
      d="m89.4 103.539-1.058 4.235a.2.2 0 0 1-.379 0l-1.058-4.235-4.235-1.058a.2.2 0 0 1 0-.379l4.239-1.059 1.058-4.237a.2.2 0 0 1 .378 0l1.061 4.237 4.236 1.059a.2.2 0 0 1 0 .379Z"
      fill="#fff"
    />
    <Path
      data-name="Path 1177"
      d="M84.674 99.061a.176.176 0 0 1 .247-.247l1.514 1.213-.109.438-.438.109Zm1.654 5.06.11.438-1.514 1.212a.175.175 0 0 1-.247-.246l1.21-1.515Zm3.658-3.659-.109-.438 1.512-1.21a.176.176 0 0 1 .247.247l-1.212 1.513Zm1.65 5.062a.176.176 0 0 1-.247.247l-1.512-1.213.109-.438.438-.11Z"
      fill="#dfdee5"
    />
    <Path
      data-name="Path 1178"
      d="m80.382 97.06-.611 2.441a.112.112 0 0 1-.217 0l-.61-2.437-2.436-.608a.113.113 0 0 1 0-.219l2.438-.609.61-2.438a.112.112 0 0 1 .217 0l.609 2.438 2.439.609a.113.113 0 0 1 0 .219Z"
      fill="#fff"
    />
    <Path
      data-name="Path 1179"
      d="M77.662 94.484a.1.1 0 0 1 .142-.142l.872.7-.065.252-.251.063Zm.951 2.912.063.252-.87.7a.1.1 0 0 1-.142-.142l.695-.872Zm2.105-2.1-.063-.253.87-.7a.1.1 0 0 1 .142.142l-.7.871Zm.949 2.912a.1.1 0 0 1-.142.142l-.87-.7.063-.252.252-.063Z"
      fill="#dfdee5"
    />
  </Svg>
)

export default IconCreateAChatAlt
