import React from 'react'
import { View, Text, Dimensions } from 'react-native'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { Status } from './types'
import { ScaledSheet } from 'react-native-size-matters'

type Props = {
  title: string
  description: string
  status: Status
  error?: Error
}

export default function DiagnosticsResult({
  title,
  description,
  status,
  error,
}: Props): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)

  const circleClass = (() => {
    switch (status) {
      case 'success':
        return styles.circleSuccess
      case 'inprogress':
        return styles.circleWarning
      case 'failed':
        return styles.circleFailed
    }
  })()

  const statusTitle = status.replace(/^\w/, (c) => c.toUpperCase())

  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.description}>{description}</Text>
        {error && <Text style={styles.error}>Error: {error?.message}</Text>}
      </View>
      <View style={styles.resultContainer}>
        <View style={[styles.circle, circleClass]} />
      </View>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    container: {
      flexDirection: 'row',
      backgroundColor: theme.white,
      borderRadius: '5@s',
      marginBottom: '5@s',
    },
    inner: {
      width: 0,
      flexGrow: 1,
      flex: 1,
      paddingHorizontal: '5@ms',
      paddingVertical: '10@mvs',
    },
    resultContainer: {
      flexShrink: 0,
      paddingHorizontal: '5@ms',
      paddingVertical: '10@mvs',
      justifyContent: 'center',
    },
    circle: {
      width: '20@s',
      height: '20@s',
      borderRadius: '20@s',
    },
    circleSuccess: {
      backgroundColor: '#10B981',
    },
    circleWarning: {
      backgroundColor: '#F59E0B',
    },
    circleFailed: {
      backgroundColor: '#EF4444',
    },
    title: {
      fontSize: '12@ms',
      fontWeight: '700',
    },
    description: {
      fontSize: '10@ms0.4',
    },
    error: {
      color: '#EF4444',
      fontSize: '10@ms0.4',
    },
  })
