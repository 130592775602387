import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import moment from 'moment'
import { Dispatch } from 'redux'
import { ApplicationState } from '~redux'
import { remove, upload } from '~redux/storage/actions'
import { AudioRecordingType, ExistingChat } from '~types'
import { removeOfflineAction } from '../offline/actions'
import {
  AttachAudioProps,
  AttachAudioRejectProps,
  AttachImageProps,
  AttachImageRejectProps,
  ChatIdentifier,
  RemoveAudioProps,
  RemoveImageProps,
  SetImageAnimationProps,
} from './types'

export const setChat = createAsyncThunk<
  ExistingChat & {
    isAuthor: boolean
  },
  ExistingChat,
  {
    // Optional fields for defining thunkApi field types
    state: ApplicationState
  }
>('@@chat/SET_CHAT', (payload, { getState }) => {
  return {
    ...payload,
    isAuthor: payload.author_id === getState().auth.user?.claims.sub,
  }
})

export const setSelectedObject = createAction<number>('@@chat/SELECT_OBJECT')

export const attachImageCommit = createAction(
  '@@chat/ATTACH_IMAGE_COMMIT',
  ({ chat, index, uri }: AttachImageProps) => {
    return {
      // UploadResult will be provided
      payload: {
        path: '',
        filename: '',
        url: '',
      },
      meta: {
        index,
        chat,
        local: uri,
      },
    }
  },
)

export const attachImageRollback = createAction(
  '@@chat/ATTACH_IMAGE_ROLLBACK',
  ({ chat, index, uri }: AttachImageProps) => {
    return {
      // UploadResult will be provided
      payload: undefined,
      meta: {
        index,
        chat,
        local: uri,
      },
    }
  },
)

// TODO: Might be nice to have a helper to create the commit/rollback actions automatically (pass payload to meta)

export const setChatImage = createAction(
  '@@chat/ATTACH_IMAGE_REQUEST',
  (payload: AttachImageProps) => {
    const args = {
      ...payload,
      filename: `image_${payload.index}_${moment().unix()}.jpg`,
    }

    return {
      payload: args,
      meta: {
        offline: {
          effect: upload(
            {
              path: args.chat.path,
              filename: args.filename,
              uri: args.uri,
            },
            `${args.chat.path}/@@chat/ATTACH_IMAGE_REQUEST/${args.index}`,
          ),
          commit: attachImageCommit(payload),
          rollback: attachImageRollback(payload),
        },
      },
    }
  },
)

export const detachAudioRequest = createAction<RemoveAudioProps>('@@chat/DETACH_AUDIO_REQUEST')
export const detachAudioCommit = createAction<RemoveAudioProps>('@@chat/DETACH_AUDIO_COMMIT')

export const removeChatAudio = (payload: RemoveAudioProps) => {
  return (dispatch: Dispatch): void => {
    dispatch(detachAudioRequest(payload))

    // Could be put on the DETACH_AUDIO_REQUEST
    dispatch(
      removeOfflineAction(
        `${payload.chat.path}/@@chat/ATTACH_IMAGE_REQUEST/${payload.index}/${payload.type}`,
      ),
    )

    dispatch(detachAudioCommit(payload))
  }
}

export const detachImageRequest = createAction<RemoveImageProps>('@@chat/DETACH_IMAGE_REQUEST')
export const detachImageCommit = createAction<RemoveImageProps>('@@chat/DETACH_IMAGE_COMMIT')

export const removeChatImage = (payload: RemoveImageProps) => {
  return (dispatch: Dispatch): void => {
    dispatch(detachImageRequest(payload))

    // Could be put on the DETACH_IMAGE_REQUEST
    dispatch(
      removeOfflineAction(`${payload.chat.path}/@@chat/ATTACH_IMAGE_REQUEST/${payload.index}`),
    )

    dispatch(detachImageCommit(payload))
  }
}

export const attachAudioCommit = createAction(
  '@@chat/ATTACH_AUDIO_COMMIT',
  ({
    chat,
    index,
    uri,
    extension, // Includes period
    type,
  }: AttachAudioProps) => {
    return {
      // UploadResult will be provided
      payload: {
        path: '',
        filename: '',
        url: '',
      },
      meta: {
        index,
        chat,
        local: uri,
        type,
        extension,
      },
    }
  },
)

export const attachAudioRollback = createAction(
  '@@chat/ATTACH_AUDIO_ROLLBACK',
  ({
    chat,
    index,
    uri,
    extension, // Includes period
    type,
  }: AttachAudioProps) => {
    return {
      // UploadResult will be provided
      payload: undefined,
      meta: {
        index,
        chat,
        local: uri,
        type,
      },
    }
  },
)

export const setChatAudio = createAction(
  '@@chat/ATTACH_AUDIO_REQUEST',
  (payload: AttachAudioProps) => {
    const args = {
      ...payload,
      filename: `audio_${payload.type}_${payload.index}_${moment().unix()}${payload.extension}`,
    }

    return {
      payload: args,
      meta: {
        offline: {
          effect: upload(
            {
              path: args.chat.path,
              filename: args.filename,
              uri: args.uri,
            },
            `${args.chat.path}/'@@chat/ATTACH_AUDIO_REQUEST/${args.index}/${args.type}`,
          ),
          commit: attachAudioCommit(payload),
          rollback: attachAudioRollback(payload),
        },
      },
    }
  },
)

export const attachImageFulfilled = createAction(
  '@@chat/ATTACH_IMAGE_FULFILLED',
  (chat: ChatIdentifier, index: number, local: string, remote: string) => {
    return {
      payload: {
        chat,
        index,
        local,
        remote,
      },
    }
  },
)

export const attachImageReject = createAction(
  '@@chat/ATTACH_IMAGE_REJECT',
  (payload: AttachImageRejectProps) => ({
    payload: {
      ...payload,
    },
    meta: {
      offline: {
        effect: remove(payload),
      },
    },
  }),
)

export const attachAudioFulfilled = createAction(
  '@@chat/ATTACH_AUDIO_FULFILLED',
  (
    chat: ChatIdentifier,
    index: number,
    local: string,
    remote: string,
    type: AudioRecordingType,
  ) => {
    return {
      payload: {
        chat,
        index,
        local,
        remote,
        type,
      },
    }
  },
)

export const attachAudioReject = createAction(
  '@@chat/ATTACH_AUDIO_REJECT',
  (payload: AttachAudioRejectProps) => ({
    payload: {
      ...payload,
    },
    meta: {
      offline: {
        effect: remove(payload),
      },
    },
  }),
)

export const detachImageFulfilled = createAction('@@chat/DETACH_IMAGE_FULFILLED')

export const detachAudioFulfilled = createAction('@@chat/DETACH_AUDIO_FULFILLED')

export const setImageAnimation = createAction(
  '@@chat/SET_IMAGE_ANIMATION',
  (payload: SetImageAnimationProps) => {
    const { index, scale, translateX, translateY } = payload

    return {
      payload: {
        path: payload.chat.path,
        index,
        scale,
        translateX,
        translateY,
      },
    }
  },
)
