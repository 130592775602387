import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useFocusEffect } from '@react-navigation/native'
import React, { useCallback, useState } from 'react'
import { FlatList, ListRenderItem, TouchableOpacity } from 'react-native'
import { mvs, ScaledSheet } from 'react-native-size-matters'
import { useDispatch, useSelector } from 'react-redux'
import { ChatNavigationItem } from '~components/ChatNavigationItem'
import IconCreateAChatAlt from '~components/IconCreateAChatAlt'
import { NavigationItem } from '~components/NavigationItem'
import { View } from '~components/Themed'
import useColumns from '~hooks/useColumns'
import usePermission from '~hooks/usePermission'
import { firestore } from '~providers/firebase'
import { ApplicationState } from '~redux'
import { addRecentClass, removeRecentClass } from '~redux/recent/actions'
import ChatService from '~services/chat'
import ClassService from '~services/class'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { ClassTabsScreenProps, ExistingChat } from '~types'
import { usePagination } from '~utils/react-firebase-pagination-hooks'

type Props = ClassTabsScreenProps<'ClassChats'>

export default function ClassScreen({ navigation, route }: Props): JSX.Element {
  const { theme } = useTheme()
  const dispatch = useDispatch()
  const styles = getStyles(theme)
  const [teachers, setTeachers] = useState<string[]>([])
  const [chatsCount, setChatsCount] = useState(0)
  const [isTeacher, setIsTeacher] = useState<boolean>(false)
  const user_id = useSelector((state: ApplicationState) => state.auth.user?.claims.sub)
  const permission_create_chat = usePermission('restrictions_max_chats_per_class', chatsCount)
  const columns = useColumns()
  const classId = route.params.id

  const [items, { loaded, hasMore, loadingMore, loadMore }, error] = usePagination(
    ClassService.listChats(classId),
    {
      limit: 20,
    },
  )

  React.useEffect(() => {
    if (error) {
      dispatch(removeRecentClass(classId))
      navigation.goBack()

      console.log(error, classId)
    }
  }, [navigation, error, dispatch, classId])

  React.useLayoutEffect(() => {
    navigation.getParent()?.setOptions({
      headerRight: () => (
        <View style={styles.navIconsContainer}>
          {isTeacher && (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('ClassSettingsModal', {
                  id: classId,
                })
              }}
              style={styles.navIconsButton}>
              <FontAwesomeIcon color={theme.primary} size={24} icon={['far', 'cog']} />
            </TouchableOpacity>
          )}
        </View>
      ),
    })
  }, [
    navigation,
    classId,
    theme,
    styles,
    isTeacher,
    permission_create_chat.status,
    permission_create_chat.message,
  ])

  useFocusEffect(
    useCallback(() => {
      let didCancel = false

      const subscriber = firestore()
        .collection('classes')
        .doc(classId)
        .onSnapshot(
          (snapshot) => {
            if (!didCancel) {
              if (!snapshot.exists) {
                dispatch(removeRecentClass(classId))
                navigation.goBack()
              } else {
                const data = snapshot.data()

                const id = snapshot.id
                const name = data?.name ?? ''
                navigation.getParent()?.setOptions({
                  title: name,
                })
                setTeachers(data?.teachers ?? [])
                setIsTeacher(user_id && (data?.teachers ?? []).includes(user_id))
                setChatsCount(data?.chats_count ?? 0)
                if (!didCancel) {
                  dispatch(
                    addRecentClass({
                      id,
                      name,
                      role: isTeacher ? 'teacher' : 'student',
                    }),
                  )
                }
              }
            }
          },
          (error) => {
            dispatch(removeRecentClass(classId))
            navigation.goBack()
          },
        )

      return () => {
        didCancel = true
        subscriber()
      }
    }, [dispatch, navigation, classId, user_id]),
  )

  const renderItem: ListRenderItem<ExistingChat> = ({ item }) => {
    const id = item.id

    if (id === 'create') {
      const showCreateChat = () => {
        if (permission_create_chat.status === 'allowed') {
          navigation.navigate('NewChatModal', {
            class: { id: classId },
          })
        } else {
          navigation.navigate('PermissionDeniedModal', {
            message: permission_create_chat.message,
          })
        }
      }

      return (
        <NavigationItem
          title="Create a chat"
          background={
            <IconCreateAChatAlt
              style={styles.alignSelfCenter}
              width={mvs(80, 0.6)}
              height={mvs(80, 0.6)}
            />
          }
          backgroundContainerStyle={styles.altBackgroundContainerStyle}
          textContainerStyle={styles.altTextContainerStyle}
          onPress={showCreateChat}
        />
      )
    }

    return <ChatNavigationItem chat={item} role={isTeacher ? 'teacher' : 'student'} />
  }

  const data = React.useMemo(() => {
    const list = items.map(ChatService.chatFromSnapshot)
    list.sort((a, b) => b.updated_at.localeCompare(a.updated_at))
    return [
      ...(isTeacher
        ? [
            {
              id: 'create',
            },
          ]
        : []),
      ...list,
    ]
  }, [isTeacher, items])

  return (
    <>
      <FlatList
        key={`class-${columns}`}
        contentContainerStyle={styles.listContainer}
        data={data}
        numColumns={columns}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
        onEndReached={() => {
          if (loaded && hasMore && !loadingMore) {
            loadMore()
          }
        }}
        onEndReachedThreshold={0.5}
      />
    </>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    listContainer: {
      paddingVertical: '20@mvs',
      paddingHorizontal: '10@ms',
    },
    navIconsContainer: {
      flexDirection: 'row',
      paddingEnd: 10,
    },
    navIconsButton: {
      marginLeft: 15,
      padding: 10,
    },
    itemSeparator: {
      height: '16@ms',
    },
    alignSelfCenter: {
      alignSelf: 'center',
    },
    altBackgroundContainerStyle: {
      backgroundColor: theme[300],
      borderColor: theme[300],
    },
    altTextContainerStyle: {
      backgroundColor: theme[100],
      borderColor: theme[100],
    },
  })
