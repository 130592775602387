import * as React from 'react'
import { mvs } from 'react-native-size-matters'
import Svg, { Path, SvgProps } from 'react-native-svg'

const IconFolderLarge = (props: SvgProps): JSX.Element => (
  <Svg width={mvs(90, 0.8)} height={mvs(90, 0.8)} viewBox="0 0 98 107.922" {...props}>
    <Path
      data-name="Path 1264"
      d="M85.17 32.83h-36.7L36.24 20.6H11.77a9.18 9.18 0 0 0-9.18 9.18v48.94a9.18 9.18 0 0 0 9.18 9.18h73.4a9.18 9.18 0 0 0 9.18-9.18V42.01a9.18 9.18 0 0 0-9.18-9.18Z"
      fill="#f8f7f9"
    />
    <Path
      data-name="Path 1265"
      d="M85.17 89.48h-73.4A10.783 10.783 0 0 1 1 78.7V29.77A10.78 10.78 0 0 1 11.77 19H36.9l12.23 12.23h36.04a10.783 10.783 0 0 1 10.77 10.78v36.7a10.789 10.789 0 0 1-10.77 10.78ZM11.77 22.2a7.588 7.588 0 0 0-7.58 7.58v48.94a7.588 7.588 0 0 0 7.58 7.58h73.4a7.588 7.588 0 0 0 7.58-7.58V42.01a7.588 7.588 0 0 0-7.58-7.58H47.81L35.58 22.2H11.77Z"
      fill="#9c99b0"
    />
  </Svg>
)

export default IconFolderLarge
