import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import React from 'react'
import { View, Text } from 'react-native'
import { ColorSchemeName } from 'react-native'
import { ms, ScaledSheet } from 'react-native-size-matters'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'

type Props = {
  title?: string
  description?: string
}

export default function RenderError({ title, description }: Props): JSX.Element {
  const { mode, theme } = useTheme()
  const styles = getStyles(mode, theme)

  return (
    <View>
      <FontAwesomeIcon
        icon={['fal', 'frown']}
        size={ms(40)}
        color={theme[400]}
        style={styles.icon}
      />
      <Text style={styles.titleText}>{title}</Text>
      <Text style={styles.descriptionText}>{description}</Text>
    </View>
  )
}

const getStyles = (mode: ColorSchemeName, theme: Theme) =>
  ScaledSheet.create({
    icon: {
      marginBottom: '15@mvs',
      alignSelf: 'center',
    },
    titleText: {
      fontSize: '22@ms',
      fontWeight: '700',
      color: theme[800],
      textAlign: 'center',
      marginBottom: '10@mvs',
    },
    descriptionText: {
      fontSize: '12@ms',
      fontWeight: '300',
      color: theme[800],
      textAlign: 'center',
    },
  })
