import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { DefaultHeaderLeft } from '~routers/components/DefaultHeaderLeft'
import ChattaCommunityCategoriesScreen from '~screens/ChattaCommunityCategoriesScreen'
import ChattaCommunityScreen from '~screens/ChattaCommunityScreen'
import ChattaCommunityTemplateScreen from '~screens/ChattaCommunityTemplateScreen'
import { useTheme } from '~theme/ThemeManager'
import { CommunityStackParamList } from '~types'

const CommunityStack = createStackNavigator<CommunityStackParamList>()

export default function CommunityNavigator(): JSX.Element {
  const { theme } = useTheme()

  return (
    <CommunityStack.Navigator
      screenOptions={{
        headerShown: true,
        headerStyle: {
          backgroundColor: theme[100],
        },
        headerTitleStyle: {
          color: theme.text,
        },
        headerTintColor: theme.primary,
        headerLeft: (props) => <DefaultHeaderLeft {...props} />,
      }}
      initialRouteName="ChattaCommunityCategories">
      <CommunityStack.Screen
        name="ChattaCommunityCategories"
        component={ChattaCommunityCategoriesScreen}
        options={{
          title: 'Community',
        }}
      />
      <CommunityStack.Screen
        name="ChattaCommunity"
        component={ChattaCommunityScreen}
        options={({ route }) => ({
          headerTitle: route.params?.name ?? '',
        })}
      />
      <CommunityStack.Screen
        name="ChattaCommunityTemplateScreen"
        component={ChattaCommunityTemplateScreen}
      />
    </CommunityStack.Navigator>
  )
}
