import { AuthError, AuthResponse } from 'msal'
import React from 'react'
import MicrosoftLogin from 'react-microsoft-login'
import { Text, View } from 'react-native'
import { ScaledSheet } from 'react-native-size-matters'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { RegisterMetadata } from '~types'
import onGoogleButtonPress from './onGoogleButtonPress.web'
import onMicrosoftButtonPress from './onMicrosoftButtonPress.web'
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google'

const clientId = process.env.EXPO_WEB_AUTHENTICATION_GOOGLE_CLIENT_ID || ''

type Props = {
  color?: string
  seperatorText?: string
  seperator?: boolean
  metadata?: RegisterMetadata
}

export default function SocialSignIn(props: Props): JSX.Element {
  const { mode, theme } = useTheme()
  const styles = getStyles(theme)
  const { color = theme.white, seperatorText = 'OR', seperator = false, metadata } = props

  const responseGoogleSuccess = async (response: CredentialResponse) => {
    if (response.credential) {
      await onGoogleButtonPress(response.credential, metadata)
    }
  }

  const responseMicrosoft = async (error: AuthError | null, result?: AuthResponse) => {
    if (error === null && result !== undefined) {
      const token = result.idToken.rawIdToken
      await onMicrosoftButtonPress(token, metadata)
    }
  }

  return (
    <>
      {seperator && (
        <View style={styles.seperatorContainer}>
          <View style={[styles.rule, { backgroundColor: color }]}></View>
          <Text style={[styles.seperatorText, { color }]}>{seperatorText}</Text>
          <View style={[styles.rule, { backgroundColor: color }]}></View>
        </View>
      )}

      <View style={styles.button}>
        <GoogleOAuthProvider clientId={clientId}>
          <GoogleLogin onSuccess={responseGoogleSuccess} width={215} />
        </GoogleOAuthProvider>
      </View>

      <View style={[styles.button, styles.buttonLast]}>
        <MicrosoftLogin
          clientId={process.env.EXPO_AZURE_CLIENT_ID}
          graphScopes={['openid', 'profile']}
          prompt={'select_account'}
          authCallback={responseMicrosoft}
        />
      </View>
    </>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    seperatorContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: '10@mvs',
    },
    seperatorText: {
      color: theme.white,
      marginHorizontal: 5,
    },
    rule: {
      height: 1,
      backgroundColor: theme.white,
      flexGrow: 1,
    },
    button: {
      width: 215,
      alignSelf: 'center',
      marginBottom: '5@mvs',
    },
    buttonLast: {
      marginBottom: 0,
    },
  })
