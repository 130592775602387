import * as React from 'react'
import { mvs } from 'react-native-size-matters'
import Svg, { Path, SvgProps } from 'react-native-svg'

const IconJoinClassLargeAlt = (props: SvgProps): JSX.Element => (
  <Svg width={mvs(90, 0.8)} height={mvs(90, 0.8)} viewBox="0 0 97.819 111.966" {...props}>
    <Path
      data-name="Path 1158"
      d="M82.99 36.106H46.551L34.4 23.96H10.11A9.111 9.111 0 0 0 1 33.073v48.584a9.111 9.111 0 0 0 9.11 9.11h72.88a9.111 9.111 0 0 0 9.11-9.11V45.216a9.111 9.111 0 0 0-9.11-9.11"
      fill="#e2e2e8"
    />
    <Path
      data-name="Path 1159"
      d="M82.99 36.106H46.551L34.4 23.96H10.11A9.111 9.111 0 0 0 1 33.073v48.584a9.111 9.111 0 0 0 9.11 9.11h72.88a9.111 9.111 0 0 0 9.11-9.11V45.216a9.111 9.111 0 0 0-9.11-9.11Z"
      fill="none"
      stroke="#444155"
      strokeMiterlimit={10}
      strokeWidth={2}
    />
    <Path
      data-name="Path 1160"
      d="M65.21 65.061a17.01 17.01 0 1 1-17.009-17.009A17.009 17.009 0 0 1 65.21 65.061"
      fill="#f0f0f3"
    />
    <Path
      data-name="Path 1161"
      d="m11.693 11.179-1.72 6.881a.317.317 0 0 1-.615 0l-1.72-6.881-6.879-1.72a.316.316 0 0 1 0-.615l6.883-1.718L9.361.242a.316.316 0 0 1 .615 0l1.723 6.885 6.882 1.717a.317.317 0 0 1 0 .615Z"
      fill="#fff"
    />
    <Path
      data-name="Path 1162"
      d="M4.015 3.904a.284.284 0 0 1 .4-.4l2.462 1.969-.179.711-.711.179Zm2.686 8.22.179.711-2.459 1.97a.284.284 0 0 1-.4-.4l1.963-2.46Zm5.944-5.943-.181-.708 2.46-1.966a.284.284 0 0 1 .4.4l-1.969 2.46Zm2.679 8.222a.284.284 0 0 1-.4.4l-2.46-1.969.181-.711.71-.178Z"
      fill="#dfdee5"
    />
    <Path
      data-name="Path 1163"
      d="m93.269 107.418-1.1 4.394a.2.2 0 0 1-.392 0l-1.1-4.394-4.394-1.1a.2.2 0 0 1 0-.394l4.4-1.1 1.1-4.4a.2.2 0 0 1 .392 0l1.1 4.4 4.394 1.1a.2.2 0 0 1 0 .394Z"
      fill="#fff"
    />
    <Path
      data-name="Path 1164"
      d="M88.365 102.773a.182.182 0 0 1 .256-.256l1.572 1.258-.114.454-.454.114Zm1.715 5.249.114.456-1.571 1.258a.182.182 0 0 1-.256-.257l1.255-1.571Zm3.8-3.8-.113-.454 1.569-1.255a.182.182 0 0 1 .256.256l-1.258 1.571Zm1.712 5.252a.182.182 0 0 1-.256.256l-1.569-1.258.113-.454.454-.114Z"
      fill="#dfdee5"
    />
    <Path
      data-name="Path 1165"
      d="m83.912 100.697-.628 2.528a.116.116 0 0 1-.226 0l-.632-2.528-2.532-.627a.117.117 0 0 1 0-.227l2.53-.632.632-2.53a.116.116 0 0 1 .226 0l.632 2.53 2.53.632a.117.117 0 0 1 0 .227Z"
      fill="#fff"
    />
    <Path
      data-name="Path 1166"
      d="M81.091 98.024a.104.104 0 0 1 .146-.148l.9.724-.067.26-.26.067Zm.985 3.021.065.26-.9.724a.105.105 0 0 1-.148-.148l.721-.9Zm2.184-2.184-.065-.262.9-.723a.106.106 0 0 1 .149.148l-.724.9Zm.986 3.021a.106.106 0 0 1-.149.148l-.9-.724.065-.262.262-.065Z"
      fill="#dfdee5"
    />
    <Path
      data-name="Path 1167"
      d="m15.991 63.755 32.766-.022-7.189-7.178a1.341 1.341 0 0 1 1.893-1.9l9.48 9.467a1.338 1.338 0 0 1 0 1.893l-9.469 9.481a1.341 1.341 0 1 1-1.9-1.893l7.18-7.189-32.76.021a1.34 1.34 0 0 1-.95-2.285 1.368 1.368 0 0 1 .945-.394"
      fill="#444155"
    />
    <Path
      data-name="Path 1168"
      d="M61.454 51.79a18.754 18.754 0 1 1-29.949 21.824 1.345 1.345 0 0 1 .583-1.8 1.4 1.4 0 0 1 1.8.58 16.079 16.079 0 1 0 2.939-18.692 15.68 15.68 0 0 0-2.946 4.05 1.34 1.34 0 0 1-2.385-1.223 18.767 18.767 0 0 1 29.958-4.739"
      fill="#444155"
    />
  </Svg>
)

export default IconJoinClassLargeAlt
