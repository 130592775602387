import React, { useEffect, useState } from 'react'
import { Status } from '../types'
import { firestore } from '../../../providers/firebase'
import DiagnosticsResult from '../DiagnosticsResult'

export default function FirebaseFirestoreChecker(): JSX.Element {
  const [status, setStatus] = useState<Status>('inprogress')
  const [error, setError] = useState<Error>()

  useEffect(() => {
    let cancel = false

    async function ping() {
      try {
        // Create
        const ref = await firestore().collection('diagnostics').add({
          ping: 'pong',
        })

        // Get
        const doc = await ref.get({ source: 'server' })
        if (JSON.stringify({ ping: 'pong' }) !== JSON.stringify(doc.data())) {
          throw new Error(`Unexpected response [doc=${JSON.stringify(doc.data())}]`)
        }

        // Delete
        await ref.delete()

        if (!cancel) {
          setStatus('success')
          setError(undefined)
        }
      } catch (error) {
        if (!cancel) {
          setStatus('failed')
          setError(error)
        }
      }
    }

    ping()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <DiagnosticsResult
      title="Firebase Firestore"
      description="Read/write/delete operation on database"
      status={status}
      error={error}
    />
  )
}
