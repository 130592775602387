import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import React from 'react'
import ClassRepliesScreen from '~screens/ClassRepliesScreen'
import ClassScreen from '~screens/ClassScreen'
import { ClassesStackScreenProps } from '~types'

const Navigator = createMaterialTopTabNavigator()

type Props = ClassesStackScreenProps<'Class'>

export default function ClassTabsNavigator({ route }: Props): JSX.Element {
  const params = {
    id: route.params?.params?.id ?? route.params?.id,
    name: route.params?.params?.name ?? route.params?.name,
  }

  return (
    <Navigator.Navigator screenOptions={{ lazy: true, swipeEnabled: true }}>
      <Navigator.Screen
        name="ClassChats"
        component={ClassScreen}
        options={{ title: 'Folder Chats' }}
        initialParams={params}
      />
      <Navigator.Screen
        name="ClassChatsReplies"
        component={ClassRepliesScreen}
        options={{ title: 'My Replies' }}
        initialParams={params}
      />
    </Navigator.Navigator>
  )
}
