import * as React from 'react'
import { mvs } from 'react-native-size-matters'
import Svg, { Path, SvgProps } from 'react-native-svg'

type Props = {
  solid?: boolean
} & SvgProps

function ChattaLogo({ solid, ...props }: Props): JSX.Element {
  return (
    <Svg
      data-name="Chatta Logo Small"
      width={mvs(116, 0.4)}
      height={mvs(64, 0.4)}
      viewBox="0 0 155 85"
      {...props}>
      {solid && <Path d="M24.113 78V65.176H6V5h143v60.176H48.9V78z" fill="#fff" />}
      <Path
        data-name="Path 8"
        d="M31.086 74.158H42.46V57.433h101.681V10.844H10.86v46.589h20.226zM53.314 85H20.226V68.276H0V0h155v68.276H53.314z"
        fill="#e5007d"
      />
      <Path
        data-name="Path 9"
        d="M35.182 40.729a1.111 1.111 0 01.415-.195 1.675 1.675 0 01.306-.039 1.073 1.073 0 01.485.109 1.313 1.313 0 01.454.469l1.752 2.645a2.3 2.3 0 01.148.281.667.667 0 01.071.251.8.8 0 01-.227.587 3.031 3.031 0 01-.492.4 11.819 11.819 0 01-2.809 1.432 9.613 9.613 0 01-3.232.539 10.174 10.174 0 01-2.715-.359 9.986 9.986 0 01-2.433-1.025 10.444 10.444 0 01-2.066-1.6 9.786 9.786 0 01-1.6-2.073 10.609 10.609 0 01-1.025-2.462 10.607 10.607 0 010-5.532 10.437 10.437 0 011.025-2.465 9.892 9.892 0 011.6-2.065 10.179 10.179 0 017.214-2.966 10.51 10.51 0 013.153.454 9.762 9.762 0 012.715 1.346l-.046-.047a1.847 1.847 0 01.547.477 1.115 1.115 0 01.2.68 1.092 1.092 0 01-.187.58l-1.393 2.363a1.255 1.255 0 01-1.064.766 1.09 1.09 0 01-.345-.07 1.928 1.928 0 01-.454-.243 7.129 7.129 0 00-1.478-.743 4.855 4.855 0 00-1.683-.289 4.124 4.124 0 00-1.814.4 4.283 4.283 0 00-1.417 1.087 5.137 5.137 0 00-.915 1.6 5.773 5.773 0 000 3.842 4.76 4.76 0 00.915 1.557 4.276 4.276 0 003.231 1.424 4.46 4.46 0 001.675-.313 7.449 7.449 0 001.486-.8"
        fill="#e5007d"
      />
      <Path
        data-name="Path 10"
        d="M47.313 29.064a6.18 6.18 0 01.72-.866 4.732 4.732 0 01.993-.764 5.648 5.648 0 011.3-.546 5.929 5.929 0 011.652-.211 6.967 6.967 0 015.563 2.559 8.283 8.283 0 011.486 2.772 11.023 11.023 0 01.509 3.385v10.25a1.364 1.364 0 01-.258.9 1.02 1.02 0 01-.822.305h-3.617a1.4 1.4 0 01-.923-.227 1.184 1.184 0 01-.25-.884V35.393a3.024 3.024 0 00-.259-1.252 3.243 3.243 0 00-.7-1.009 3.183 3.183 0 00-1.025-.673 3.219 3.219 0 00-1.244-.243 2.812 2.812 0 00-1.1.228 3.114 3.114 0 00-.97.641 3.6 3.6 0 00-.72.993 3.335 3.335 0 00-.336 1.284v10.327a1.482 1.482 0 01-.1.533.847.847 0 01-.305.407 1.439 1.439 0 01-.384.171 1.614 1.614 0 01-.414.048h-3.538a1.1 1.1 0 01-1.268-1.2V22.262a1.235 1.235 0 01.3-.86 1.253 1.253 0 01.97-.329h3.537a1.638 1.638 0 01.8.25.857.857 0 01.305.407 1.4 1.4 0 01.1.5z"
        fill="#e5007d"
      />
      <Path
        data-name="Path 11"
        d="M66.789 36.927a5.685 5.685 0 00.321 1.932 4.592 4.592 0 00.915 1.557 4.344 4.344 0 003.289 1.424 4.918 4.918 0 001.949-.383 4.972 4.972 0 001.564-1.041 4.73 4.73 0 001.041-1.557 5.1 5.1 0 000-3.842 5.075 5.075 0 00-1.041-1.6 4.932 4.932 0 00-1.564-1.087 4.76 4.76 0 00-1.949-.4 4.254 4.254 0 00-1.854.4 4.338 4.338 0 00-1.432 1.087 4.936 4.936 0 00-.915 1.6 5.667 5.667 0 00-.321 1.91m15.413 8.763a1.383 1.383 0 01-.094.508.777.777 0 01-.313.384.787.787 0 01-.406.219 2.134 2.134 0 01-.407.046h-3.083a1.373 1.373 0 01-.532-.086.822.822 0 01-.329-.242 1.435 1.435 0 01-.211-.383 27.944 27.944 0 01-.18-.493l-.453-1.174q-.423.423-.923.9a8.569 8.569 0 01-1.1.884 6.64 6.64 0 01-1.307.681 4.119 4.119 0 01-1.5.273 10.2 10.2 0 01-2.731-.36 10.049 10.049 0 01-4.5-2.62 10.2 10.2 0 01-2.614-4.539 10.462 10.462 0 01-.36-2.761 10.266 10.266 0 012.974-7.3 10.132 10.132 0 017.229-2.965 5.3 5.3 0 011.785.29 6.115 6.115 0 011.478.759 7.317 7.317 0 011.205 1.063 11.7 11.7 0 01.962 1.206l.611-1.894q.156-.39.258-.634a1.115 1.115 0 01.219-.36.579.579 0 01.289-.156 2.324 2.324 0 01.485-.039h2.332a2.067 2.067 0 01.407.047.773.773 0 01.406.219 1 1 0 01.313.391 1.258 1.258 0 01.094.5z"
        fill="#e5007d"
      />
      <Path
        data-name="Path 12"
        d="M86.565 23.876a.854.854 0 01.321-.728 3.629 3.629 0 01.916-.462l3.552-1.236a.773.773 0 01.148-.015h.133a.821.821 0 01.775.336 1.752 1.752 0 01.195.884l-.016 4.929h2.614a1.135 1.135 0 01.814.3 1.171 1.171 0 01.313.891v3.021a1.291 1.291 0 01-.313.962 1.12 1.12 0 01-.814.306h-2.614l.016 12.581a1.139 1.139 0 01-.344.9 1.39 1.39 0 01-.954.3H87.77a1.065 1.065 0 01-1.205-1.2l-.015-12.581h-1.143q-1.2 0-1.205-1.268v-3.021a1.059 1.059 0 011.205-1.189h1.143z"
        fill="#e5007d"
      />
      <Path
        data-name="Path 13"
        d="M100.099 23.876a.854.854 0 01.321-.728 3.664 3.664 0 01.916-.462l3.552-1.236a.79.79 0 01.148-.015h.133a.821.821 0 01.775.336 1.741 1.741 0 01.2.884l-.016 4.929h2.614a1.133 1.133 0 01.813.3 1.171 1.171 0 01.313.891v3.021a1.291 1.291 0 01-.313.962 1.117 1.117 0 01-.813.306h-2.614l.016 12.581a1.143 1.143 0 01-.344.9 1.4 1.4 0 01-.955.3h-3.537a1.065 1.065 0 01-1.2-1.2l-.015-12.581H98.95q-1.2 0-1.205-1.268v-3.021a1.059 1.059 0 011.205-1.189h1.143z"
        fill="#e5007d"
      />
      <Path
        data-name="Path 14"
        d="M116.294 36.927a5.709 5.709 0 00.321 1.932 4.592 4.592 0 00.915 1.557 4.344 4.344 0 003.286 1.424 4.915 4.915 0 001.949-.383 4.972 4.972 0 001.564-1.041 4.715 4.715 0 001.041-1.557 5.1 5.1 0 000-3.842 5.058 5.058 0 00-1.041-1.6 4.932 4.932 0 00-1.564-1.087 4.756 4.756 0 00-1.949-.4 4.254 4.254 0 00-1.854.4 4.338 4.338 0 00-1.432 1.087 4.936 4.936 0 00-.915 1.6 5.691 5.691 0 00-.321 1.91m15.414 8.763a1.384 1.384 0 01-.1.508.772.772 0 01-.313.384.787.787 0 01-.406.219 2.134 2.134 0 01-.407.046h-3.083a1.373 1.373 0 01-.532-.086.822.822 0 01-.329-.242 1.435 1.435 0 01-.211-.383 27.944 27.944 0 01-.18-.493l-.453-1.174q-.423.423-.923.9a8.5 8.5 0 01-1.1.884 6.64 6.64 0 01-1.307.681 4.119 4.119 0 01-1.5.273 10.2 10.2 0 01-2.731-.36 10.042 10.042 0 01-2.44-1.025 9.942 9.942 0 01-2.058-1.595 10.2 10.2 0 01-2.614-4.539 10.462 10.462 0 01-.359-2.761 10.259 10.259 0 012.973-7.3 10.132 10.132 0 017.229-2.965 5.286 5.286 0 011.784.29 6.1 6.1 0 011.479.759 7.276 7.276 0 011.205 1.063 11.7 11.7 0 01.962 1.206l.611-1.894q.156-.39.258-.634a1.132 1.132 0 01.219-.36.584.584 0 01.289-.156 2.324 2.324 0 01.485-.039h2.332a2.067 2.067 0 01.407.047.773.773 0 01.406.219.99.99 0 01.313.391 1.258 1.258 0 01.1.5z"
        fill="#e5007d"
      />
    </Svg>
  )
}

export default ChattaLogo
