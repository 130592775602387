import React from 'react'
import { FlatList } from 'react-native'
import { ms, ScaledSheet } from 'react-native-size-matters'
import { useSelector } from 'react-redux'
import { ChatNavigationItem } from '~components/ChatNavigationItem'
import Loading from '~components/Loading'
import RenderError from '~components/RenderError'
import { View } from '~components/Themed'
import useColumns from '~hooks/useColumns'
import { ApplicationState } from '~redux'
import ChatService from '~services/chat'
import ClassService from '~services/class'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { ClassTabsScreenProps } from '~types'
import { usePagination } from '~utils/react-firebase-pagination-hooks'

type Props = ClassTabsScreenProps<'ClassChatsReplies'>

export default function ClassRepliesScreen({ navigation, route }: Props): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const user_id = useSelector((state: ApplicationState) => state.auth.user?.claims.sub)
  const classId = route.params.id
  const columns = useColumns()

  const [items, { loaded, hasMore, loadingMore, loadMore }, error] = usePagination(
    ClassService.listReplies(user_id, classId),
    {
      limit: 20,
    },
  )

  const renderItem = ({ item }) => {
    const chat = ChatService.chatFromSnapshot(item)

    return <ChatNavigationItem chat={chat} role={'student'} isReply={true} />
  }

  if (!loaded) {
    return (
      <View style={styles.emptyContainer}>
        <Loading />
      </View>
    )
  }

  if (!items.length) {
    return (
      <View style={styles.emptyContainer}>
        <RenderError title="Oops, nothing here!" description="No replies have been found." />
      </View>
    )
  }

  return (
    <>
      <FlatList
        key={`class-${columns}`}
        contentContainerStyle={styles.listContainer}
        data={items}
        numColumns={columns}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        ItemSeparatorComponent={() => <View style={{ height: ms(20) }} />}
        onEndReached={() => {
          if (loaded && hasMore && !loadingMore) {
            loadMore()
          }
        }}
        onEndReachedThreshold={0.5}
      />
    </>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    listContainer: {
      paddingVertical: '20@mvs',
      paddingHorizontal: '10@ms',
    },
    header: {
      paddingHorizontal: '10@ms',
      paddingBottom: '20@mvs',
    },
    subtitle: {
      fontSize: '12@ms0.4',
      fontWeight: '300',
    },
    navIconsContainer: {
      flexDirection: 'row',
      paddingEnd: 10,
    },
    navIconsButton: {
      marginLeft: 15,
      padding: 10,
    },
    emptyContainer: {
      paddingHorizontal: '10@ms',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
