import 'firebase/analytics'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/remote-config'
import 'firebase/storage'
import firebaseConfig from '../../google-services-web.json'

const app = firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth
export const functions = app.functions.bind(app)
export const firestore = firebase.firestore
export const storage = firebase.storage
export const crashlytics = () => ({
  setUserId: () => {},
})
export const remoteConfig: () => firebase.remoteConfig.RemoteConfig = () =>
  firebase.remoteConfig(app)
export const analytics: () => firebase.analytics.Analytics = () => firebase.analytics(app)
