import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import React from 'react'
import { Platform } from 'react-native'
import { ScaledSheet } from 'react-native-size-matters'
import { DefaultHeaderLeft } from '~routers/components/DefaultHeaderLeft'
import DashboardScreen from '~screens/DashboardScreen'
import SettingsScreen from '~screens/SettingsScreen'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { MainStackParamList } from '~types'
import ClassNavigator from './ClassNavigator'
import CommunityNavigator from './CommunityNavigator'

const Navigator = createBottomTabNavigator<MainStackParamList>()

export default function MainNavigator(): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)

  return (
    <Navigator.Navigator
      screenOptions={{
        headerStyle: styles.headerStyle,
        headerTitleStyle: styles.headerTitleStyle,
        headerTintColor: theme.primary,
        headerLeft: (props) => <DefaultHeaderLeft {...props} />,
        tabBarStyle: styles.tabBarStyle,
      }}>
      <Navigator.Screen
        name="Dashboard"
        component={DashboardScreen}
        options={{ tabBarIcon: (props) => <FontAwesomeIcon icon={['fal', 'home']} {...props} /> }}
      />
      <Navigator.Screen
        name="Classes"
        component={ClassNavigator}
        options={{
          title: 'My folders',
          tabBarIcon: (props) => <FontAwesomeIcon icon={['fal', 'folders']} {...props} />,
          headerShown: false,
        }}
      />
      <Navigator.Screen
        name="Community"
        component={CommunityNavigator}
        options={{
          title: 'Community',
          tabBarIcon: (props) => <FontAwesomeIcon icon={['fal', 'hands-heart']} {...props} />,
          headerShown: false,
        }}
      />
      <Navigator.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          tabBarIcon: (props) => <FontAwesomeIcon icon={['fal', 'wrench']} {...props} />,
          unmountOnBlur: true,
        }}
      />
    </Navigator.Navigator>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    headerStyle: {
      backgroundColor: theme[100],
    },
    headerTitleStyle: {
      color: theme.text,
    },
    tabBarStyle:
      Platform.OS === 'web'
        ? {
            overflow: 'hidden',
          }
        : {},
  })
