import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { createAction, createReducer } from '@reduxjs/toolkit'
import { Audio, AVPlaybackStatus } from 'expo-av'
import React, { useEffect, useRef, useState } from 'react'
import { Platform, StyleSheet, TouchableHighlight } from 'react-native'
import { Dialog, Paragraph } from 'react-native-paper'
import { useDispatch, useSelector } from 'react-redux'
import { Text, View } from '~components/Themed'
import { MAX_RECORDING_LENGTH_MS } from '~constants/Settings'
import { setLockedSection, unlockSection, useChatContext } from '~context/ChatProvider'
import { ApplicationState } from '~redux'
import { removeChatAudio, setChatAudio } from '~redux/chat/actions'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { AudioRecordingType } from '~types'
import { AlertConfirmation } from './AlertConfirmation'

export interface AudioRecordingProps {
  type: AudioRecordingType
  index: number
  source?: { uri: string }
  expanded: boolean
  readonly: boolean
}

export interface State {
  // Recording
  isRecording: boolean
  isRecordingPaused: boolean
  recordingDuration: number

  // Playback
  isPlaying: boolean
  isPlaybackPaused: boolean
  playbackPosition: number
  playbackDuration: number
  playbackURI?: string
}

export const initialState: State = {
  isRecording: false,
  isRecordingPaused: false,
  recordingDuration: 0,

  isPlaying: false,
  isPlaybackPaused: false,
  playbackPosition: 0,
  playbackDuration: 0,
}

const setPlaybackStatus = createAction<AVPlaybackStatus>('setPlaybackStatus')
const setRecordingStatus = createAction<Audio.RecordingStatus>('setRecordingStatus')

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setPlaybackStatus, (state, action) => {
      const status = action.payload
      if (status.isLoaded) {
        state.playbackPosition = status.positionMillis
        state.playbackDuration = status.durationMillis ?? 0
        state.isPlaying = status.isPlaying
        state.isPlaybackPaused = false
        state.playbackURI = status.uri
        if (status.didJustFinish) {
          // After stop
          state.playbackPosition = 0
        } else if (!status.isPlaying) {
          // If isLoaded but not playing
          state.isPlaybackPaused = true
        }
      } else {
        // Unloaded
        state.isPlaying = false
        state.isPlaybackPaused = false
        state.playbackPosition = 0
        state.playbackDuration = 0
        state.playbackURI = undefined
      }
    })
    .addCase(setRecordingStatus, (state, action) => {
      const status = action.payload
      if (status.canRecord) {
        state.isRecording = status.isRecording
        state.isRecordingPaused = !status.isRecording
      } else if (status.isDoneRecording) {
        state.isRecording = false
      }
      state.recordingDuration = status.durationMillis
    }),
)

export default function AudioRecording({
  type,
  index,
  source,
  expanded,
  readonly,
}: AudioRecordingProps): JSX.Element {
  const [stateChat, dispatchChat] = useChatContext()
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const { chat } = useSelector((state: ApplicationState) => state.chat)
  const globalDispatch = useDispatch()
  const playback = useRef<Audio.Sound | null>(null)
  const recording = useRef<Audio.Recording | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const detailsStyle = type === 'teacher' ? styles.detailsLeft : styles.detailsRight
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const lock = React.useCallback(() => dispatchChat(setLockedSection(index)), [dispatchChat, index])
  const unlock = React.useCallback(() => dispatchChat(unlockSection(index)), [dispatchChat, index])

  const hasSource = source !== undefined && source !== null
  const unlocked = stateChat.lockedSection === -1
  const hasLock = stateChat.lockedSection === index

  const recordingSettings = {
    android: {
      extension: '.m4a',
      outputFormat: Audio.RECORDING_OPTION_ANDROID_OUTPUT_FORMAT_MPEG_4,
      audioEncoder: Audio.RECORDING_OPTION_ANDROID_AUDIO_ENCODER_HE_AAC,
      sampleRate: 44100,
      numberOfChannels: 2,
      bitRate: 64000,
    },
    ios: {
      extension: '.m4a',
      outputFormat: Audio.RECORDING_OPTION_IOS_OUTPUT_FORMAT_MPEG4AAC_HE,
      audioQuality: Audio.RECORDING_OPTION_IOS_AUDIO_QUALITY_MIN,
      sampleRate: 44100,
      numberOfChannels: 2,
      bitRate: 64000,
      linearPCMBitDepth: 16,
      linearPCMIsBigEndian: false,
      linearPCMIsFloat: false,
    },
    web: (() => {
      if (Platform.OS === 'web') {
        const mimes = [
          {
            mimeType: 'audio/mp4;codecs="mp4a.40.5"', // MPEG-4 HE-AAC v1
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: '.m4a',
          },
          {
            mimeType: 'audio/mp4;codecs="mp4a.40.2"', // MPEG-4 AAC LC
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: '.m4a',
          },
          {
            mimeType: 'audio/webm;codecs="opus"',
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: '.webm',
          },
          {
            mimeType: 'audio/webm;codecs="vp8"',
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: '.webm',
          },
          {
            mimeType: 'audio/webm',
            audioBitsPerSecond: 64000,
            bitsPerSecond: 64000,
            extension: '.webm',
          },
          {
            mimeType: 'audio/mpeg', // Support depends on polyfill
            audioBitsPerSecond: 128000,
            bitsPerSecond: 128000,
            extension: '.mp3',
          },
        ]

        for (let index = 0; index < mimes.length; index++) {
          const mime = mimes[index]

          if ((window as any).MediaRecorder?.isTypeSupported(mime.mimeType)) {
            return mime
          }
        }
      }

      return undefined
    })(),
  }

  const onRecordPressed = async () => {
    if (Platform.OS === 'web' && recordingSettings.web === undefined) {
      alert(
        'Browser is not compatible with audio recording, please ensure you are using Chrome, Firefox, Edge or Safari.',
      )
      return
    }

    const permissionResult = await Audio.requestPermissionsAsync()

    if (permissionResult.granted === false) {
      alert('Permission to record audio is required!')
      return
    }

    if (state.isRecording) {
      pauseRecording()
    } else if (state.isRecordingPaused) {
      resumeRecording()
    } else if (!state.isRecording) {
      stopPlaybackAndBeginRecording()
    }
  }

  const onPlayPausePressed = async () => {
    // Unload if wrong source but not if paused
    if (playback.current && state.playbackURI !== source?.uri && !state.isPlaybackPaused) {
      await playback.current.unloadAsync()
      playback.current = null
    }

    if (!playback.current && source !== undefined) {
      try {
        const { sound, status } = await Audio.Sound.createAsync(
          { uri: source.uri },
          {},
          updateScreenForSoundStatus,
          false,
        )
        if (status.isLoaded) {
          playback.current = sound
        }
      } catch (error) {
        // An error occurred!
        console.warn(error)
      }
    }

    if (playback.current) {
      if (state.isPlaying) {
        await playback.current.pauseAsync()
      } else {
        await preparePlayback()
        await playback.current.playAsync()
      }
    }
  }

  const onStopPressed = async () => {
    if (state.isRecording || state.isRecordingPaused) {
      await stopRecordingAndEnablePlayback()
    } else if (playback.current) {
      await stopPlayback()
    }
  }

  const trashAction = () => {
    globalDispatch(
      removeChatAudio({
        chat: { id: chat.id, path: chat.path },
        index,
        type,
      }),
    )
  }

  const preparePlayback = async () => {
    lock()

    await Audio.setAudioModeAsync({
      allowsRecordingIOS: false,
      interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
      playsInSilentModeIOS: true,
      shouldDuckAndroid: true,
      interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
      playThroughEarpieceAndroid: false,
      staysActiveInBackground: false,
    })
  }

  const prepareRecording = async () => {
    lock()

    await Audio.setAudioModeAsync({
      allowsRecordingIOS: true,
      interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
      playsInSilentModeIOS: true,
      shouldDuckAndroid: true,
      interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
      playThroughEarpieceAndroid: false,
      staysActiveInBackground: false,
    })
  }

  const stopPlaybackAndBeginRecording = async () => {
    setIsLoading(true)

    await stopPlayback()

    if (recording.current) {
      await recording.current.stopAndUnloadAsync()
      recording.current = null
    }

    await prepareRecording()

    try {
      const newRecording = new Audio.Recording()
      const status = await newRecording.prepareToRecordAsync(recordingSettings)
      if (status.canRecord) {
        newRecording.setOnRecordingStatusUpdate(updateScreenForRecordingStatus)
        recording.current = newRecording

        await newRecording.startAsync() // Will call updateScreenForRecordingStatus to update the screen.
      }
    } catch (e) {
      console.warn(e)
    }

    setIsLoading(false)
  }

  const stopRecordingAndEnablePlayback = async () => {
    if (recording.current) {
      setIsLoading(true)

      try {
        await recording.current.stopAndUnloadAsync()
      } catch (error) {
        // Do nothing -- we are already unloaded.
      }

      setIsLoading(false)

      const uri = recording.current.getURI()
      if (uri) {
        await processAudio(uri)
      }

      recording.current = null
    }
  }

  /**
   * Abandon the recording
   */
  const stopRecording = async () => {
    if (recording.current) {
      try {
        await recording.current.stopAndUnloadAsync()
        // recording.current = null
      } catch (error) {
        // Do nothing -- we are already unloaded.
      }
    }
  }

  const stopPlayback = async () => {
    if (playback.current) {
      try {
        await playback.current.stopAsync()
        await playback.current.unloadAsync()
        // playback.current = null
      } catch (error) {
        // Do nothing -- we are already unloaded.
      }
    }
  }

  const pauseRecording = async () => {
    setIsLoading(true)

    if (recording.current) {
      try {
        await recording.current.pauseAsync()
      } catch (error) {
        //
      }
    }

    setIsLoading(false)
  }

  const resumeRecording = async () => {
    setIsLoading(true)

    if (recording.current) {
      try {
        await recording.current.startAsync()
      } catch (error) {
        // Ignore
      }
    }

    setIsLoading(false)
  }

  const processAudio = async (uri: string) => {
    const extension =
      Platform.OS === 'web'
        ? recordingSettings.web.extension
        : Platform.OS === 'android'
        ? recordingSettings.android.extension
        : recordingSettings.ios.extension

    globalDispatch(
      setChatAudio({
        chat: { id: chat.id, path: chat.path },
        index,
        uri,
        extension,
        type,
      }),
    )
  }

  const updateScreenForSoundStatus = React.useCallback(
    async (status: AVPlaybackStatus) => {
      if (!playback.current) {
        return
      }

      dispatch(setPlaybackStatus(status))

      if (status.isLoaded) {
        if (status.didJustFinish) {
          await playback.current?.stopAsync()
          await playback.current?.unloadAsync()
          playback.current = null
          unlock()
        } else if (!status.isPlaying && !status.shouldPlay) {
          unlock()
        }
      } else {
        playback.current?.setOnPlaybackStatusUpdate(null)
        playback.current = null
        unlock()
        if (status.error) {
          console.log(`FATAL PLAYER ERROR: ${status.error}`)
        }
      }
    },
    [unlock],
  )

  const updateScreenForRecordingStatus = (status: Audio.RecordingStatus) => {
    if (!recording.current) {
      return
    }

    dispatch(setRecordingStatus(status))

    if (status.canRecord) {
      // Enforce max recording length
      if (status.durationMillis >= MAX_RECORDING_LENGTH_MS && status.isRecording) {
        stopRecordingAndEnablePlayback()
      }
    } else if (status.isDoneRecording) {
      unlock()
    }
  }

  const getMMSSFromMillis = (millis: number) => {
    const totalSeconds = millis / 1000
    const seconds = Math.floor(totalSeconds % 60)
    const minutes = Math.floor(totalSeconds / 60)

    const padWithZero = (number: number) => {
      const string = number.toString()
      if (number < 10) {
        return `0${string}`
      }
      return string
    }
    return `${padWithZero(minutes)}:${padWithZero(seconds)}`
  }

  const getRecordingTimestamp = () => {
    if (state.recordingDuration) {
      return `${getMMSSFromMillis(state.recordingDuration)}`
    }
    return `${getMMSSFromMillis(0)}`
  }

  const getSoundTimestamp = () => {
    return `${getMMSSFromMillis(state.playbackPosition)}`
  }

  useEffect(() => {
    return () => {
      // Stop any recordings
      if (recording.current) {
        stopRecording()
      }

      // Stop playback?
      if (playback.current) {
        playback.current.setOnPlaybackStatusUpdate(null)
        stopPlayback()
      }

      // Unlock
      unlock()
    }
  }, [unlock])

  if (!expanded) {
    return <></>
  }

  return (
    <View style={styles.containerDetails}>
      <View style={detailsStyle}>
        {/* TODO: reuse same button */}
        {!hasSource && (
          <TouchableHighlight
            onPress={onRecordPressed}
            style={styles.button}
            disabled={readonly || isLoading || !(unlocked || hasLock)}>
            <View style={styles.buttonBackground}>
              <FontAwesomeIcon
                color={unlocked || hasLock ? theme.primary : theme[300]}
                size={22}
                icon={
                  state.isRecording && !state.isRecordingPaused
                    ? ['fas', 'pause']
                    : ['fas', 'microphone']
                }
              />
            </View>
          </TouchableHighlight>
        )}
        {hasSource && (
          <TouchableHighlight
            onPress={onPlayPausePressed}
            style={styles.button}
            disabled={!(unlocked || hasLock)}>
            <View style={styles.buttonBackground}>
              <FontAwesomeIcon
                color={unlocked || hasLock ? theme.primary : theme[300]}
                size={22}
                icon={state.isPlaying ? ['fas', 'pause'] : ['fas', 'play']}
              />
            </View>
          </TouchableHighlight>
        )}
        {(state.isRecording || hasSource || state.isRecordingPaused) && (
          <Text style={styles.timestamp}>
            {state.isPlaying || state.isPlaybackPaused
              ? getSoundTimestamp()
              : getRecordingTimestamp()}
          </Text>
        )}
        {(state.isRecording || hasSource || state.isRecordingPaused || hasLock) && (
          <TouchableHighlight
            onPress={onStopPressed}
            style={styles.button}
            disabled={
              isLoading ||
              !(
                state.isPlaying ||
                state.isPlaybackPaused ||
                state.isRecording ||
                state.isRecordingPaused
              )
            }>
            <View style={styles.buttonBackground}>
              <FontAwesomeIcon
                color={
                  !(
                    state.isPlaying ||
                    state.isPlaybackPaused ||
                    state.isRecording ||
                    state.isRecordingPaused
                  )
                    ? theme[300]
                    : theme.primary
                }
                size={22}
                icon={['fas', 'stop']}
              />
            </View>
          </TouchableHighlight>
        )}
        {(state.isRecording || hasSource || state.isRecordingPaused) && (
          <TouchableHighlight
            onPress={() => setShowDeleteConfirmation(true)}
            style={[styles.button, { marginLeft: 8 }]}
            disabled={readonly || isLoading || !unlocked || !hasSource}>
            <View style={styles.buttonBackground}>
              <FontAwesomeIcon
                color={readonly || !unlocked || !hasSource ? theme[300] : theme.primary}
                size={22}
                icon={['far', 'trash-alt']}
              />
            </View>
          </TouchableHighlight>
        )}
        {showDeleteConfirmation && (
          <AlertConfirmation
            visible={showDeleteConfirmation}
            setVisible={setShowDeleteConfirmation}
            confirmAction={trashAction}>
            <Dialog.Title>Delete audio recording?</Dialog.Title>
            <Dialog.Content>
              <Paragraph>Are you sure you want to delete this audio recording?</Paragraph>
            </Dialog.Content>
          </AlertConfirmation>
        )}
      </View>
    </View>
  )
}

export const avatarSize = 64

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    detailsLeft: {
      borderWidth: 1,
      borderColor: theme[200],
      paddingHorizontal: 8,
      marginLeft: avatarSize / 2,
      paddingLeft: avatarSize / 2 + 8,
      height: avatarSize,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopRightRadius: avatarSize / 3,
      borderBottomRightRadius: avatarSize / 3,
      flexDirection: 'row',
      backgroundColor: theme[100],
    },
    detailsRight: {
      borderWidth: 1,
      borderColor: theme[200],
      paddingHorizontal: 8,
      marginRight: avatarSize / 2,
      paddingRight: avatarSize / 2 + 8,
      height: avatarSize,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopLeftRadius: avatarSize / 3,
      borderBottomLeftRadius: avatarSize / 3,
      flexDirection: 'row',
      backgroundColor: theme[100],
    },
    containerDetails: {
      backgroundColor: 'transparent',
    },
    button: {
      borderRadius: 24,
    },
    buttonBackground: {
      borderRadius: 24,
      borderWidth: 1,
      padding: 8,
      borderColor: theme[300],
      backgroundColor: theme[200],
    },
    timestamp: {
      fontVariant: ['tabular-nums'],
      paddingHorizontal: 8,
    },
  })
