import React from 'react'
import { Dimensions, ScrollView, Text, View } from 'react-native'
import {
  ApiChecker,
  // FirebaseAnalyticsChecker,
  FirebaseAuthenticationChecker,
  FirebaseFirestoreChecker,
  // FirebaseRemoteConfigChecker,
  FirebaseStorageChecker,
} from './checkers'
import { ScaledSheet } from 'react-native-size-matters'
import { useTheme } from '~theme/ThemeManager'
import { Theme } from '~theme'

export default function DiagnosticsScreen(): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)

  return (
    <ScrollView>
      <View style={styles.container}>
        <Text style={styles.title}>
          A series of tests are being performed to check your device can access resources required
          by the app.
        </Text>
        <View>
          <ApiChecker />
          {/* <FirebaseAnalyticsChecker />
        <FirebaseRemoteConfigChecker /> */}
          <FirebaseAuthenticationChecker />
          <FirebaseFirestoreChecker />
          <FirebaseStorageChecker />
        </View>
        <View style={styles.keyContainer}>
          <View style={styles.keyInner}>
            <View style={[styles.circle, styles.circleFailed]} />
            <Text style={styles.keyText}>Failure</Text>
          </View>
          <View style={styles.keyInner}>
            <View style={[styles.circle, styles.circleWarning]} />
            <Text style={styles.keyText}>In Progress</Text>
          </View>
          <View style={styles.keyInner}>
            <View style={[styles.circle, styles.circleSuccess]} />
            <Text style={styles.keyText}>Success</Text>
          </View>
        </View>
      </View>
    </ScrollView>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    container: {
      width: Dimensions.get('window').width < 640 ? '90%' : '60%',
      alignSelf: 'center',
      padding: '5@mvs',
    },
    title: {
      textAlign: 'center',
      paddingVertical: '10@mvs',
    },
    keyContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    keyInner: {
      flexDirection: 'row',
      paddingHorizontal: '5@s',
      paddingVertical: '5@s',
      alignItems: 'center',
    },
    keyText: {
      fontSize: '10@ms0.4',
    },
    circle: {
      width: '12@s',
      height: '12@s',
      borderRadius: '12@s',
      marginRight: '3@s',
    },
    circleSuccess: {
      backgroundColor: '#10B981',
    },
    circleWarning: {
      backgroundColor: '#F59E0B',
    },
    circleFailed: {
      backgroundColor: '#EF4444',
    },
  })
