import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useNavigation } from '@react-navigation/native'
import { throttle } from 'lodash'
import React, { useCallback, useRef, useState } from 'react'
import {
  Dimensions,
  FlatList,
  Keyboard,
  ListRenderItemInfo,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { Chip } from 'react-native-elements'
import { ms, ScaledSheet } from 'react-native-size-matters'
import useSWR, { useSWRInfinite } from 'swr'
import {
  CommunityImageSearchResource,
  CommunityImageSearchResourceCollection,
  CommunityImageTagResource,
  CommunityImageTagResourceCollection,
  SearchEngineImageSearchResource,
  SearchEngineImageSearchResourceCollection,
} from '~api/types'
import { FormInputButton } from '~components/FormInputButton'
import { ImageSearchItem } from '~components/ImageSearchItem'
import Loading from '~components/Loading'
import ModalScreen, { getModalStyleDefinition, ModalHeader } from '~components/ModalScreen'
import AutocompleteTags from '~components/react-native-autocomplete-tags'
import RenderError from '~components/RenderError'
import { EVENT_INTERNET_IMAGE_SEARCH } from '~constants/Analytics'
import useColumns from '~hooks/useColumns'
import useKeyboardState from '~hooks/useKeyboardState'
import { analytics } from '~providers/firebase'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { RootStackScreenProps } from '~types'
import fetch from '~utils/fetch'

type Props = RootStackScreenProps<'ImageSearchModal'>

type InternalProps = {
  target: number
}

export default function MediaSourceScreen({
  route: {
    params: { type, target },
  },
}: Props): JSX.Element {
  return type === 'community' ? (
    <CommunitySource target={target} />
  ) : (
    <InternetSource target={target} />
  )
}

function CommunitySource({ target }: InternalProps): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [tags, setTags] = useState<CommunityImageTagResource[]>([])
  const [query, setQuery] = useState('')
  const suggestions = useRef<CommunityImageTagResourceCollection | undefined>()
  const throttled = useRef(throttle(setQuery, 500))
  const adjustedColumns = useColumns(1.5)

  const keyboardIsOpen = useKeyboardState()

  const { data: tagData, error: tagError } = useSWR<CommunityImageTagResourceCollection>(
    () => {
      const args = new URLSearchParams()
      if (query.length) {
        args.append('query', query)
      }
      tags.forEach((tag) => args.append('exclude[]', tag.name))

      return `/api/v1/images/community/tags?${args.toString()}`
    },
    fetch,
    {
      initialData: suggestions.current,
    },
  )

  if (tagData !== undefined && suggestions) {
    suggestions.current = tagData
  }

  const getFetchUrl = useCallback(() => {
    const args = new URLSearchParams()

    tags.forEach((tag) => args.append('tags[]', tag.name))

    return `/api/v1/images/community/search?${args.toString()}`
  }, [tags])

  const navigation = useNavigation()

  const { data, size, setSize, isValidating, error } =
    useSWRInfinite<CommunityImageSearchResourceCollection>((pageIndex, previousPageData) => {
      if (tags.length === 0) {
        return null
      }

      // First
      if (pageIndex === 0) {
        return getFetchUrl()
      }

      // End
      if (previousPageData && previousPageData.links.next !== null) {
        return previousPageData.links.next
      }

      return null
    })

  const items = (Array.isArray(data) ? data : [])
    .flatMap((o) => o.data)
    .filter((item, index, array) => {
      return array.findIndex((o) => o.id === item.id) === index
    })

  const more =
    size > 0 &&
    data !== undefined &&
    data[size - 1] !== undefined &&
    data[size - 1]?.links.next !== null

  const itemPressed = (item: CommunityImageSearchResource) => {
    navigation.navigate({
      name: 'ChatScreen',
      params: {
        source: 'resource',
        sourceTarget: target,
        sourceResource: item,
      },
      merge: true,
    })
  }

  const renderItem = ({ index, item }: ListRenderItemInfo<CommunityImageSearchResource>) => (
    <ImageSearchItem
      style={{ flex: 1 / adjustedColumns }}
      item={item}
      onPress={() => itemPressed(item)}
    />
  )

  const labelExtractor = (tag: CommunityImageTagResource) => tag.name

  const renderTag = (tag: CommunityImageTagResource, onPress: (tag: any) => void) => (
    <Chip
      containerStyle={styles.tagContainer}
      buttonStyle={[styles.tag, styles.tagActive]}
      titleStyle={styles.tagTitle}
      title={labelExtractor(tag)}
      key={`tag-${labelExtractor(tag)}`}
      onPress={onPress}
    />
  )

  const renderSuggestion = (
    suggestion: CommunityImageTagResource,
    onPress: (suggestion: any) => void,
  ) => (
    <Chip
      containerStyle={styles.tagContainer}
      buttonStyle={styles.tag}
      titleStyle={styles.tagTitle}
      title={labelExtractor(suggestion)}
      key={`suggestion-${labelExtractor(suggestion)}`}
      onPress={onPress}
    />
  )

  const filterSuggestions = (text: string, suggestions?: CommunityImageTagResource[]) => {
    const regex = new RegExp(`${text.trim()}`, 'i')
    return (suggestions ?? [])
      ?.filter((item) => !tags.some((existing) => existing.name === item.name))
      ?.filter((item) => labelExtractor(item).search(regex) >= 0)
  }

  const handleQueryChanged = (query: string) => {
    throttled.current(query)
  }

  const renderHeader = (
    <View style={styles.content}>
      <AutocompleteTags<CommunityImageTagResource, CommunityImageTagResource>
        tags={tags}
        suggestions={suggestions.current?.data}
        onChangeTags={setTags}
        onChangeText={handleQueryChanged}
        labelExtractor={labelExtractor}
        renderTag={renderTag}
        renderSuggestion={renderSuggestion}
        inputProps={{ style: styles.formInput, placeholder: 'Search term' }}
        tagContainerStyle={styles.tagContainerStyle}
        allowCustomTags={false}
        filterSuggestions={filterSuggestions}
        focusOnSuggestionPress={false}
      />
    </View>
  )

  const renderEmptyList =
    tags.length === 0 ? null : isValidating ? (
      <View style={styles.emptyContainer}>
        <Loading />
      </View>
    ) : error ? (
      <View style={styles.emptyContainer}>
        <RenderError title="Network Error" description={error.message} />
      </View>
    ) : (
      <View style={styles.emptyContainer}>
        <RenderError title="Oops, nothing here!" description={`No images have been found.`} />
      </View>
    )

  return (
    <ModalScreen ContainerComponent={React.Fragment} closable={false}>
      <View style={styles.inner}>
        <ModalHeader>
          <View style={styles.content}>
            <View style={styles.titleContainer}>
              <View style={styles.header}>
                <Text style={styles.title}>Chatta Community</Text>
                <TouchableOpacity onPress={navigation.goBack}>
                  <FontAwesomeIcon icon={['fal', 'times-circle']} size={ms(24)} />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ModalHeader>
        <FlatList<CommunityImageSearchResource>
          key={`community-image-search-${adjustedColumns}`}
          contentContainerStyle={styles.listContainer}
          ListHeaderComponent={renderHeader}
          data={items}
          numColumns={adjustedColumns}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
          onEndReached={() => more && setSize(size + 1)}
          onEndReachedThreshold={0.25}
          ListEmptyComponent={renderEmptyList}
          style={
            // No idea why any value for height works on web
            Platform.OS === 'web' ? { height: 0 } : undefined
          }
        />
      </View>
    </ModalScreen>
  )
}

function InternetSource({ target }: InternalProps): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [query, setQuery] = useState('')
  const [queryInternal, setQueryInternal] = useState('') // throttled
  const adjustedColumns = useColumns(1.5)

  const throttled = useRef(throttle(setQueryInternal, 1000))
  const keyboardIsOpen = useKeyboardState()

  const getFetchUrl = useCallback(() => {
    const args = new URLSearchParams()
    args.append('query', queryInternal)

    return `/api/v1/images/internet/search?${args.toString()}`
  }, [queryInternal])

  const navigation = useNavigation()

  const { data, size, setSize, isValidating, error } =
    useSWRInfinite<SearchEngineImageSearchResourceCollection>((pageIndex, previousPageData) => {
      if (queryInternal === undefined || queryInternal.length === 0) {
        return null
      }

      // First
      if (pageIndex === 0) {
        return getFetchUrl()
      }

      // End
      if (previousPageData && previousPageData.links.next !== null) {
        return previousPageData.links.next
      }

      return null
    })

  const items = (Array.isArray(data) ? data : [])
    .flatMap((o) => o.data)
    .filter((item, index, array) => {
      return array.findIndex((o) => o.id === item.id) === index
    })

  const more =
    size > 0 &&
    data !== undefined &&
    data[size - 1] !== undefined &&
    data[size - 1]?.links.next !== null

  const itemPressed = (item: SearchEngineImageSearchResource) => {
    navigation.navigate({
      name: 'ChatScreen',
      params: {
        source: 'resource',
        sourceTarget: target,
        sourceResource: item,
      },
      merge: true,
    })
  }

  const renderItem = ({ index, item }: ListRenderItemInfo<SearchEngineImageSearchResource>) => (
    <ImageSearchItem
      style={{ flex: 1 / adjustedColumns }}
      item={item}
      onPress={() => itemPressed(item)}
    />
  )

  const search = () => {
    Keyboard.dismiss()

    throttled.current(query)

    // Only log analytics event if query changed
    if (query !== queryInternal) {
      analytics().logEvent(EVENT_INTERNET_IMAGE_SEARCH, {
        query,
      })
    }
  }

  const renderHeader = (
    <View style={styles.content}>
      <FormInputButton
        input={{
          value: query,
          placeholder: 'Search term',
          onChangeText: setQuery,
          style: styles.formInput,
          onSubmitEditing: search,
          returnKeyLabel: 'Search',
          returnKeyType: 'search',
        }}
        button={{
          title: 'Search',
          onPress: search,
        }}
      />
    </View>
  )

  const renderEmptyList =
    queryInternal.length === 0 ? null : isValidating ? (
      <View style={styles.emptyContainer}>
        <Loading />
      </View>
    ) : error ? (
      <View style={styles.emptyContainer}>
        <RenderError title="Network Error" description={error.message} />
      </View>
    ) : (
      <View style={styles.emptyContainer}>
        <RenderError title="Oops, nothing here!" description={`No images have been found.`} />
      </View>
    )

  return (
    <ModalScreen ContainerComponent={React.Fragment} closable={false}>
      <View style={styles.inner}>
        <ModalHeader>
          <View style={styles.content}>
            <View style={styles.titleContainer}>
              <View style={styles.header}>
                <Text style={styles.title}>Search the internet</Text>
                <TouchableOpacity onPress={navigation.goBack}>
                  <FontAwesomeIcon icon={['fal', 'times-circle']} size={ms(24)} />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ModalHeader>

        <FlatList<SearchEngineImageSearchResource>
          key={`internet-image-search-${adjustedColumns}`}
          contentContainerStyle={styles.listContainer}
          ListHeaderComponent={renderHeader}
          data={items}
          numColumns={adjustedColumns}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
          onEndReached={() => more && setSize(size + 1)}
          onEndReachedThreshold={0.25}
          ListEmptyComponent={renderEmptyList}
          style={
            // No idea why any value for height works on web
            Platform.OS === 'web' ? { height: 0 } : undefined
          }
        />
      </View>
    </ModalScreen>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    ...getModalStyleDefinition(theme),
    container: {
      backgroundColor: theme[100],
      flexGrow: 0,
      width: Math.min(Dimensions.get('window').width * 0.8, ms(256)),
    },
    emptyContainer: {
      paddingHorizontal: '10@ms',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    listContainer: {
      backgroundColor: theme[100],
      paddingHorizontal: '10@mvs0.8',
      paddingVertical: '10@mvs0.8',
      minHeight: Dimensions.get('window').height,
    },
    content: {
      flexGrow: 1,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    titleContainer: {
      marginBottom: '10@mvs0.8',
    },
    title: {
      fontSize: '18@ms',
      fontWeight: '700',
      textAlign: 'center',
    },
    subtitle: {
      fontSize: '12@ms0.4',
      fontWeight: '300',
      textAlign: 'center',
    },
    formInput: {
      backgroundColor: theme.white,
    },
    tagContainer: {
      marginEnd: '4@ms',
      marginVertical: '2@ms',
    },
    tag: {
      backgroundColor: theme[400],
      paddingVertical: '6@mvs',
      paddingHorizontal: '4@ms',
    },
    tagActive: {
      backgroundColor: theme.primary,
    },
    tagTitle: {
      fontSize: '10@ms0.2',
      color: theme.white,
    },
    tagContainerStyle: {
      marginBottom: '16@mvs',
    },
    itemSeparator: {
      height: '5@mvs0.8',
    },
  })
