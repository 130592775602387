import * as React from 'react'
import { mvs } from 'react-native-size-matters'
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg'

const IconUserGuideLarge = (props: SvgProps): JSX.Element => (
  <Svg width={mvs(90, 0.8)} height={mvs(90, 0.8)} viewBox="0 0 160 160" {...props}>
    <Circle data-name="Ellipse 78" cx={80} cy={80} r={80} fill="#9c99b0" />
    <Circle
      data-name="Ellipse 79"
      cx={78.524}
      cy={78.524}
      transform="translate(1.476 1.476)"
      fill="#444155"
      r={78.524}
    />
    <Circle
      data-name="Ellipse 80"
      cx={74.578}
      cy={74.578}
      r={74.578}
      transform="translate(5.422 5.422)"
      fill="#c7c5d2"
    />
    <Circle
      data-name="Ellipse 81"
      cx={74.578}
      cy={74.578}
      r={74.578}
      transform="translate(5.422 5.422)"
      fill="#c7c5d2"
    />
    <Path
      data-name="Path 909"
      d="m40.854 20.129-6.236.911c-.676 0 1.048-1.062 1.6-1.462a74.8 74.8 0 0 1 84.321-2.221c.5.317 2.042 1.38 2.042 1.38l-5.725-.441C98.811 5.422 61.714 2.512 40.854 20.129Z"
      fill="#d8d7dd"
    />
    <Path
      data-name="Path 910"
      d="M69.971 6.773a73.2 73.2 0 0 1 10.14-.662 72.139 72.139 0 0 1 9.271.607"
      fill="none"
      stroke="#efeff4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      data-name="Path 911"
      d="M140.035 35.671a74.461 74.461 0 1 1-121.083 86.7Z"
      fill="#b3b2bf"
    />
    <Path
      data-name="Path 912"
      d="m118.307 140.423 6.9-1.462c.69 0-1.7 1.683-2.249 2.083a74.829 74.829 0 0 1-84.349.993c-.5-.331-2.456-1.269-1.876-1.38l5.518.538c12.277 12.224 54.948 16.529 76.056-.772Z"
      fill="#8d8ba0"
    />
    <Circle
      data-name="Ellipse 82"
      cx={67.101}
      cy={67.101}
      r={67.101}
      transform="translate(12.899 12.899)"
      fill="none"
      stroke="#dcdbe3"
      strokeMiterlimit={10}
    />
    <Circle
      data-name="Ellipse 83"
      cx={61.72}
      cy={61.72}
      r={61.72}
      transform="translate(18.28 18.28)"
      fill="none"
      stroke="#dcdbe3"
      strokeMiterlimit={10}
      strokeWidth={0.92}
    />
    <Path
      data-name="Path 913"
      d="m126.503 61.318-1.7 6.746a.3.3 0 0 1-.593 0l-1.7-6.746-6.76-1.683a.317.317 0 0 1 0-.607l6.746-1.7 1.698-6.745a.317.317 0 0 1 .607 0l1.683 6.746 6.76 1.7a.317.317 0 0 1 0 .607Z"
      fill="#fff"
    />
    <G data-name="Group 203" opacity={0.44}>
      <Path
        data-name="Path 914"
        d="M118.961 54.17a.278.278 0 0 1 .4-.386l2.414 1.931-.179.69-.69.179Zm2.635 8.071.179.7-2.414 1.931a.278.278 0 0 1-.386-.4l1.918-2.359Zm5.836-5.836-.166-.69 2.414-1.931a.276.276 0 0 1 .386.386l-1.89 2.428Zm2.635 8.071a.278.278 0 0 1-.386.4l-2.414-1.931.166-.7.745-.124Z"
        fill="#fff"
      />
    </G>
    <Path
      data-name="Path 915"
      d="m36.21 82.2-.952 3.821a.166.166 0 1 1-.331 0l-.966-3.821-3.808-.952a.179.179 0 0 1 0-.345l3.821-.911.952-3.821a.166.166 0 0 1 .331 0L36.228 80l3.808.952a.179.179 0 0 1 0 .345Z"
      fill="#fff"
    />
    <G data-name="Group 204" opacity={0.44}>
      <Path
        data-name="Path 916"
        d="M31.958 78.163c-.124-.138 0-.331.221-.221l1.38 1.1-.1.386-.4.1Zm1.49 4.594.1.4-1.38 1.09a.16.16 0 1 1-.226-.224l1.09-1.38Zm3.3-3.3-.11-.4 1.38-1.09c.152-.11.345.083.221.221l-1.09 1.38Zm1.474 4.566c.124.138 0 .331-.221.221l-1.38-1.09.11-.4.386-.1Z"
        fill="#fff"
      />
    </G>
    <Path
      data-name="Path 917"
      d="m28.158 76.382-.552 2.249c0 .1-.166.1-.193 0l-.552-2.195-2.194-.566a.1.1 0 1 1 0-.193l2.194-.552.552-2.194a.1.1 0 0 1 .193 0l.552 2.194 2.207.552a.1.1 0 1 1 0 .193Z"
      fill="#fff"
    />
    <G data-name="Group 205" opacity={0.44}>
      <Path
        data-name="Path 918"
        d="M25.716 74.064c-.069-.1 0-.207.124-.138l.786.635v.221h-.235Zm.855 2.621v.221l-.786.635c-.083 0-.193 0-.124-.138l.621-.773Zm1.89-1.9v-.221l.786-.635c.083-.069.193 0 .124.138l-.621.773Zm.855 2.621c.069.1 0 .207-.124.138l-.786-.635v-.221h.235Z"
        fill="#fff"
      />
    </G>
    <Path
      data-name="Rectangle 1500"
      fill="#fff"
      opacity={0.5}
      d="M47.143 46.81h63.227v56.867H47.143z"
    />
    <Path
      data-name="Path 919"
      d="M109.682 105.474a37.745 37.745 0 0 0 0 11.285h.5c.993 0 1.711.3 1.711 1.3v.842c0 .993-.814 1.283-1.807 1.283H54.194a9.657 9.657 0 0 1-9.657-9.657V55.229a12.016 12.016 0 0 1 12.016-12.03h51.763a3.615 3.615 0 0 1 3.615 3.684v55.321a3.628 3.628 0 0 1-2.249 3.27Zm-.911-58.688H56.553c-5.629 0-8.843 5.353-8.843 9.34v48.7c1.38-.828 2.759-2.759 6.443-2.235h54.618Zm-1.683 58.964H54.194c-2.663 0-6.291 2.607-6.291 5.27s1.076 5.96 6.291 5.96h52.894a46.327 46.327 0 0 1 0-11.23Z"
      fill="#444155"
    />
    <Circle
      data-name="Ellipse 84"
      cx={12.913}
      cy={12.913}
      transform="translate(66.647 61.57)"
      fill="#c7c5d2"
      r={12.913}
    />
    <Path
      data-name="Path 920"
      d="M93.796 74.249a14.017 14.017 0 1 1-13.975-14.017 14.017 14.017 0 0 1 13.975 14.017Zm-26.212 0a12.2 12.2 0 1 0 3.6-8.62 12.209 12.209 0 0 0-3.6 8.62Zm10.844 5.423v-6.789h-.635a.676.676 0 0 1-.676-.676v-.469a.676.676 0 0 1 .676-.69h2.759a.676.676 0 0 1 .676.69v7.905h.69a.676.676 0 0 1 .676.676v.453a.676.676 0 0 1-.676.676h-4.125a.676.676 0 0 1-.676-.676v-.455a.676.676 0 0 1 .676-.676Z"
      fill="#444155"
    />
    <Circle
      data-name="Ellipse 186"
      cx={1.75}
      cy={1.75}
      r={1.75}
      transform="translate(78 66.057)"
      fill="#444155"
    />
  </Svg>
)

export default IconUserGuideLarge
