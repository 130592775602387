import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs'
import { getDefaultHeaderHeight } from '@react-navigation/elements'
import { useFocusEffect } from '@react-navigation/native'
import * as ImageManipulator from 'expo-image-manipulator'
import { Action, ActionResize } from 'expo-image-manipulator'
import * as ImagePicker from 'expo-image-picker'
import { ImagePickerResult } from 'expo-image-picker'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Dimensions,
  FlatList,
  Platform,
  StatusBar,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { Dialog, Paragraph } from 'react-native-paper'
import { useSafeAreaFrame, useSafeAreaInsets } from 'react-native-safe-area-context'
import { HeaderButtons } from 'react-navigation-header-buttons'
import { useDispatch, useSelector } from 'react-redux'
import { SearchEngineImageSearchResource } from '~api/types'
import { AlertConfirmation } from '~components/AlertConfirmation'
import { CacheableImage } from '~components/CacheableImage'
import ChatSection from '~components/ChatSection'
import { FontAwesomeIconHeaderButton } from '~components/header/HeaderButton'
import { Item } from '~components/header/HeaderItems'
import ShareChatPopup, { ShareChatPopupProps } from '~components/ShareChatPopup.web'
import { EVENT_CHAT_SHARED } from '~constants/Analytics'
import {
  IMAGE_MAX_DIMENSION_ONE_SECTION,
  IMAGE_MAX_DIMENSION_OVER_ONE_SECTION,
} from '~constants/Settings'
import { ChatProvider } from '~context/ChatProvider'
import { analytics, firestore, functions } from '~providers/firebase'
import Share from '~providers/share'
import { ApplicationState } from '~redux'
import { removeChatImage, setChat, setChatImage } from '~redux/chat/actions'
import { addRecentChat, removeRecentChat } from '~redux/recent/actions'
import ChatService from '~services/chat'
import { useTheme } from '~theme/ThemeManager'
import { ClassesStackScreenProps, IChatLayout } from '~types'
import { clientUnauthenticated } from '~utils/fetch'
import parseChatPath from '~utils/parseChatPath'
import urlInfo from '~utils/urlInfo'

type Props = ClassesStackScreenProps<'ChatScreen'>

function useBottomTabBarHeightMaybe() {
  try {
    return useBottomTabBarHeight()
  } catch (error) {
    return 0
  }
}

export default function ChatScreen({ navigation, route }: Props): JSX.Element {
  const [dimensions, setDimensions] = useState(useSafeAreaFrame())
  const insets = useSafeAreaInsets()
  const headerHeight = React.useMemo(
    () => getDefaultHeaderHeight(dimensions, false, insets.top),
    [dimensions, insets],
  )

  const { chat, selectedObject } = useSelector((state: ApplicationState) => state.chat)
  const dispatch = useDispatch()
  const { theme } = useTheme()
  const list = useRef<FlatList>()
  const [isShareLoading, setIsShareLoading] = useState(false)
  const [shareChatPopupVisible, setShareChatPopupVisible] = useState(false)
  const [shareUrl, setShareUrl] = useState('')
  const user_id = useSelector((state: ApplicationState) => state.auth.user?.claims.sub)
  const profile = useSelector((state: ApplicationState) => state.auth.profile)
  const isTeacher = route.params.role === 'teacher'
  const { classId, chatId } = parseChatPath(route.params.path)
  const tabHeight = useBottomTabBarHeightMaybe()
  const [showDeleteImageConfirmation, setShowDeleteImageConfirmation] = useState(false)

  useEffect(() => {
    if (Platform.OS === 'web') {
      const onChange = ({ window }: { window: any }) => setDimensions(window)
      Dimensions.addEventListener('change', onChange)
      return () => {
        Dimensions.removeEventListener('change', onChange)
      }
    }
  })

  const processImage = useCallback(
    async (index: number, pickerResult: ImagePickerResult) => {
      if (pickerResult.cancelled) {
        return
      }

      const {
        layout: { rows, columns },
      } = chat
      const maxDimension =
        columns === 1 && rows === 1
          ? IMAGE_MAX_DIMENSION_ONE_SECTION
          : IMAGE_MAX_DIMENSION_OVER_ONE_SECTION

      // Maintain aspect ratio but limit to a maximum size depending on the amount of sections in the chat
      const resize: ActionResize | null =
        pickerResult.width > maxDimension || pickerResult.height > maxDimension
          ? {
              resize:
                pickerResult.width > pickerResult.height
                  ? {
                      width: maxDimension,
                    }
                  : {
                      height: maxDimension,
                    },
            }
          : null

      const actions: Action[] = []
      if (resize != null) {
        actions.push(resize)
      }

      const manipulated = await ImageManipulator.manipulateAsync(
        pickerResult.uri,
        actions,
        // Compress as a JPEG
        { compress: 0.6, format: ImageManipulator.SaveFormat.JPEG },
      )

      // Persist it
      dispatch(
        setChatImage({
          chat: { id: chat.id, path: chat.path },
          index,
          uri: manipulated.uri,
        }),
      )
    },
    [chat, dispatch],
  )

  const processInternetImage = useCallback(
    async (index: number, resource: SearchEngineImageSearchResource) => {
      const {
        layout: { rows, columns },
      } = chat
      let url = resource.url

      const isManipulated = new RegExp(
        functions().app.options.projectId + '.cloudfunctions.net$',
        'i',
      ).test(urlInfo(url).hostname)

      const maxDimension =
        columns === 1 && rows === 1
          ? IMAGE_MAX_DIMENSION_ONE_SECTION
          : IMAGE_MAX_DIMENSION_OVER_ONE_SECTION

      if (isManipulated) {
        // Append the width and height query args
        url += `&width=${maxDimension}&height=${maxDimension}`
      }

      // Download original
      const fileSystem = Platform.OS !== 'web' ? CacheableImage.fileSystem() : undefined

      try {
        const localPath = fileSystem
          ? await fileSystem.getLocalFilePathFromUrl(url)
          : await clientUnauthenticated
              .get(url, {
                responseType: 'arraybuffer',
              })
              .then(
                (response) =>
                  'data:' +
                  (response.headers['content-type'] ?? 'image/jpeg') +
                  ';base64,' +
                  Buffer.from(response.data, 'binary').toString('base64'),
              )

        if (localPath) {
          // Persist it
          dispatch(
            setChatImage({
              chat: { id: chat.id, path: chat.path },
              index,
              uri: localPath,
            }),
          )
        }
      } catch (error) {
        console.warn(error)
      }
    },
    [chat, dispatch],
  )

  useEffect(() => {
    async function openCameraAsync(index: number) {
      const permissionResult = await ImagePicker.requestCameraPermissionsAsync()

      if (permissionResult.granted === false) {
        alert('Permission to access camera is required!')
        return
      }

      const pickerResult = await ImagePicker.launchCameraAsync({
        quality: 0.5,
        exif: false,
      })

      await processImage(index, pickerResult)
    }

    async function openImagePickerAsync(index: number) {
      const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()

      if (permissionResult.granted === false) {
        alert('Permission to access camera roll is required!')
        return
      }

      const pickerResult = await ImagePicker.launchImageLibraryAsync()

      await processImage(index, pickerResult)
    }

    if (route.params.source && route.params.sourceTarget !== undefined) {
      switch (route.params.source) {
        case 'camera':
          openCameraAsync(route.params.sourceTarget)
          break
        case 'device':
          openImagePickerAsync(route.params.sourceTarget)
          break
        case 'community':
          navigation.navigate('ImageSearchModal', {
            type: 'community',
            target: route.params.sourceTarget,
          })
          break
        case 'internet':
          navigation.navigate('ImageSearchModal', {
            type: 'internet',
            target: route.params.sourceTarget,
          })
          break
        case 'resource':
          if (route.params.sourceResource) {
            processInternetImage(route.params.sourceTarget, route.params.sourceResource)
          }
          break
      }
      navigation.setParams({
        source: undefined,
        sourceTarget: undefined,
        sourceResource: undefined,
      })
    }
  }, [
    navigation,
    processImage,
    processInternetImage,
    route.params.source,
    route.params.sourceTarget,
    route.params.sourceResource,
  ])

  React.useLayoutEffect(() => {
    const deleteImage = () => {
      dispatch(
        removeChatImage({
          chat: { id: chat.id, path: chat.path },
          index: selectedObject,
        }),
      )
    }

    const onDeleteImagePressed = () => {
      if (selectedObject > -1) {
        setShowDeleteImageConfirmation(true)
      }
    }

    const share = async () => {
      if (isShareLoading) {
        return
      }

      setIsShareLoading(true)

      try {
        let url = shareUrl

        if (!url) {
          const response = await functions('europe-west1').httpsCallable('shareChat')({
            path: chat.path,
          })
          url = response.data.url
          setShareUrl(url)
          await analytics().logEvent(EVENT_CHAT_SHARED)
        }

        if (url) {
          if (Platform.OS === 'web') {
            setShareChatPopupVisible(true)
          } else {
            const title = `Shared Chat: ${chat.name}`

            await Share.open({
              title,
              subject: title,
              url,
            })
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsShareLoading(false)
      }
    }

    navigation.setOptions({
      headerTitle: Dimensions.get('window').width < 480 || chat.id !== chatId ? '' : chat.name,
      headerRight: () =>
        isTeacher || chat.isAuthor ? (
          <View style={styles.navIconsContainer}>
            <TouchableOpacity
              onPress={onDeleteImagePressed}
              style={styles.navIconsButton}
              disabled={selectedObject === -1 || chat?.sections[selectedObject].image.src === ''}>
              <FontAwesomeIcon
                color={
                  selectedObject === -1 || chat?.sections[selectedObject].image.src === ''
                    ? theme[300]
                    : theme.primary
                }
                size={24}
                icon={['far', 'trash-alt']}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('EditChatModal', { path: chat.path })
              }}
              style={styles.navIconsButton}>
              <FontAwesomeIcon color={theme.primary} size={24} icon={['far', 'edit']} />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={share}
              style={styles.navIconsButton}
              disabled={isShareLoading}>
              <FontAwesomeIcon
                color={isShareLoading ? theme[300] : theme.primary}
                size={24}
                icon={['far', 'share-alt']}
              />
            </TouchableOpacity>
            {showDeleteImageConfirmation && (
              <AlertConfirmation
                visible={showDeleteImageConfirmation}
                setVisible={setShowDeleteImageConfirmation}
                confirmAction={deleteImage}>
                <Dialog.Title>Delete Image?</Dialog.Title>
                <Dialog.Content>
                  <Paragraph>Deleting this image will remove it from your chat.</Paragraph>
                </Dialog.Content>
              </AlertConfirmation>
            )}
          </View>
        ) : (
          <HeaderButtons HeaderButtonComponent={FontAwesomeIconHeaderButton}>
            <Item
              title="Duplicate"
              icon={['far', 'copy']}
              onPress={() => {
                if (user_id && classId) {
                  ChatService.duplicateChat(user_id, profile.displayName, classId, chat)
                }
              }}
            />
          </HeaderButtons>
        ),
    })
  }, [
    dispatch,
    navigation,
    selectedObject,
    theme,
    chat,
    isShareLoading,
    shareUrl,
    isTeacher,
    user_id,
    route.params,
    profile,
    classId,
    showDeleteImageConfirmation,
    chatId,
  ])

  useFocusEffect(
    useCallback(() => {
      let didCancel = false

      let subscriberParent: (() => void) | undefined = undefined

      // Parse the path
      const replyPath =
        classId !== undefined && chatId !== undefined
          ? `users/${user_id}/chats/${chatId}`
          : undefined

      const onError = () => {
        if (replyPath !== undefined) {
          dispatch(removeRecentChat(replyPath))
        }
        dispatch(removeRecentChat(route.params.path))
        navigation.goBack()
      }

      const subscriptionBuilder = (
        path: string,
        callbackNotFound: (() => void) | undefined = undefined,
        callBackSuccess: (() => void) | undefined = undefined,
      ) =>
        firestore()
          .doc(path)
          .onSnapshot((snapshot) => {
            if (!snapshot.exists) {
              dispatch(removeRecentChat(path))

              if (callbackNotFound) {
                callbackNotFound()
              } else {
                // If parent doesn't exist go back
                navigation.goBack()
              }
            } else {
              if (callBackSuccess) {
                callBackSuccess()
              }

              const chat = ChatService.chatFromSnapshot(snapshot)
              if (!didCancel) {
                dispatch(addRecentChat(chat, route.params.role))
                dispatch(setChat(chat))
              }
            }
          }, onError)

      const subscriber = subscriptionBuilder(
        replyPath ?? route.params.path,
        replyPath !== undefined
          ? () => {
              subscriberParent = subscriptionBuilder(route.params.path)
            }
          : undefined,
        () => {
          if (subscriberParent !== undefined) {
            subscriberParent()
          }
        },
      )

      return () => {
        didCancel = true
        subscriber()
        if (subscriberParent !== undefined) {
          subscriberParent()
        }
      }
    }, [chatId, classId, dispatch, navigation, route.params.path, route.params.role, user_id]),
  )

  const data = useMemo(() => {
    if (chat.id !== chatId) {
      return []
    }

    return Object.entries(chat?.sections ?? {})
      .sort(([, a], [, b]) => (a.order ?? 0) - (b.order ?? 0))
      .map(([key]) => parseInt(key))
  }, [chat.id, chat?.sections, chatId])

  const small = Dimensions.get('window').width < 480 || Dimensions.get('window').height < 480

  const displayLayout: IChatLayout = small
    ? {
        rows: 1,
        columns: 1,
      }
    : chat.layout

  const chatSectionOptions = useMemo(() => {
    const availableHeight =
      dimensions.height -
      (StatusBar.currentHeight || 0) -
      tabHeight -
      headerHeight -
      insets.bottom -
      insets.top

    const heightEach = Math.floor(availableHeight / displayLayout.rows)
    const heightMin = 250

    const height = Math.max(heightEach, heightMin)

    return {
      scrollEnabled: heightEach < heightMin,
      style: {
        flexBasis: 0,
        minHeight: height,
        height,
      },
    }
  }, [dimensions, headerHeight, insets.top, insets.bottom, displayLayout.rows, tabHeight])

  const shareChatPopupProps: ShareChatPopupProps = {
    visible: shareChatPopupVisible,
    setVisible: (visible: boolean) => setShareChatPopupVisible(visible),
    url: shareUrl,
  }

  const setIsScrollEnabled = (enabled: boolean) => {
    if (list.current) {
      list.current?.setNativeProps({
        scrollEnabled: enabled,
      })
    }
  }

  useEffect(() => {
    if (Dimensions.get('window').width < 480) {
      setIsScrollEnabled(selectedObject === -1)
    }
  }, [selectedObject])

  const renderItem = ({ index, item }) => (
    <ChatSection
      style={chatSectionOptions.style}
      index={item}
      role={route.params.role}
      readonly={!isTeacher && !chat.isAuthor}
    />
  )

  return (
    <ChatProvider>
      <View style={styles.container}>
        {Platform.OS === 'web' && <ShareChatPopup {...shareChatPopupProps} />}
        <FlatList
          ref={(c) => (list.current = c)}
          key={`chat_${displayLayout.rows}_${displayLayout.columns}`}
          style={styles.chat}
          scrollEnabled={small || chatSectionOptions.scrollEnabled}
          contentContainerStyle={styles.chatContainer}
          numColumns={displayLayout.columns}
          snapToAlignment="start"
          snapToInterval={chatSectionOptions.style.height}
          decelerationRate="fast"
          pagingEnabled
          data={data}
          renderItem={renderItem}
          keyExtractor={(item) => item.toString()}
          getItemLayout={(data, index) => ({
            length: chatSectionOptions.style.height,
            offset: chatSectionOptions.style.height * index,
            index,
          })}
        />
      </View>
    </ChatProvider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  chat: {
    flex: 1,
  },
  chatContainer: {
    padding: 10,
    flexGrow: 1,
  },
  navIconsContainer: {
    flexDirection: 'row',
    paddingEnd: 10,
  },
  navIconsButton: {
    marginLeft: 15,
    padding: 10,
  },
})
