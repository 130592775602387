import { RESET_STATE } from '@redux-offline/redux-offline/lib/constants'
import { createReducer } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/types/types-external'
import {
  attachAudioFulfilled,
  attachAudioRollback,
  attachImageFulfilled,
  attachImageRollback,
  detachAudioRequest,
  detachImageRequest,
  setChatAudio,
  setChatImage,
} from '~redux/chat/actions'
import { StorageState } from './types'

const initialState: StorageState = {
  pending: {},
}

const removePending = (state: WritableDraft<StorageState>, path: string, local?: string) => {
  if (local === undefined || state.pending[path] === local) {
    delete state.pending[path]
  }
}

const reducer = createReducer(initialState, (builder) =>
  builder
    // Redux offline outbox cleared
    .addCase(RESET_STATE, (state, action) => {
      // state.pending = initialState.pending
    })
    // Image
    .addCase(setChatImage, (state, action) => {
      state.pending[`${action.payload.chat.path}.${action.payload.index}.image.src`] =
        action.payload.uri
    })
    .addCase(attachImageFulfilled, (state, action) =>
      removePending(
        state,
        `${action.payload.chat.path}.${action.payload.index}.image.src`,
        action.payload.local,
      ),
    )
    .addCase(attachImageRollback, (state, action) =>
      removePending(
        state,
        `${action.meta.chat.path}.${action.meta.index}.image.src`,
        action.meta.local,
      ),
    )
    .addCase(detachImageRequest, (state, action) =>
      removePending(state, `${action.payload.chat.path}.${action.payload.index}.image.src`),
    )
    // Audio
    .addCase(setChatAudio, (state, action) => {
      state.pending[
        `${action.payload.chat.path}.${action.payload.index}.audio.${action.payload.type}.src`
      ] = action.payload.uri
    })
    .addCase(attachAudioFulfilled, (state, action) =>
      removePending(
        state,
        `${action.payload.chat.path}.${action.payload.index}.audio.${action.payload.type}.src`,
        action.payload.local,
      ),
    )
    .addCase(attachAudioRollback, (state, action) =>
      removePending(
        state,
        `${action.meta.chat.path}.${action.meta.index}.audio.${action.meta.type}.src`,
        action.meta.local,
      ),
    )
    .addCase(detachAudioRequest, (state, action) =>
      removePending(
        state,
        `${action.payload.chat.path}.${action.payload.index}.audio.${action.payload.type}.src`,
      ),
    ),
)

export { reducer as storageReducer }
