import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useNavigation } from '@react-navigation/core'
import React from 'react'
import * as RN from 'react-native'
import { Dimensions, TouchableOpacity } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { ms, ScaledSheet, StringifiedStyles } from 'react-native-size-matters'
import { View } from '~components/Themed'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'

type Props = {
  children: React.ReactNode
  closable?: boolean
  ContainerComponent?: React.ComponentType
  height?: number
  Footer?: React.ComponentType<any> | React.ReactElement | null
}

export default function ModalScreen({
  children,
  closable = true,
  ContainerComponent = KeyboardAwareScrollView,
  height,
  Footer,
}: Props): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme, height)
  const navigation = useNavigation()

  const dismiss = () => navigation.goBack()

  const closeNode =
    closable && navigation.canGoBack() ? (
      <TouchableOpacity onPress={dismiss} style={styles.closeButton}>
        <FontAwesomeIcon icon={['fal', 'times-circle']} size={ms(24)} />
      </TouchableOpacity>
    ) : null

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <ContainerComponent>
          <View style={styles.scrollviewContent}>{children}</View>
        </ContainerComponent>
        {Footer && (React.isValidElement(Footer) ? Footer : <Footer />)}
        {closeNode}
      </View>
    </View>
  )
}

export function ModalHeader({ children }: { children: React.ReactNode }): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)

  return <View style={styles.header}>{children}</View>
}

export function ModalFooter({ children }: { children: React.ReactNode }): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)

  return (
    <View style={styles.listFooterContainer}>
      <View style={[styles.listFooterInnerContainer, styles.footer]}>{children}</View>
    </View>
  )
}

interface ModalStyle {
  container: RN.ViewStyle | StringifiedStyles
  innerContainer: RN.ViewStyle | StringifiedStyles
  header: RN.ViewStyle | StringifiedStyles
  closeButton: RN.ViewStyle | StringifiedStyles
  footer: RN.ViewStyle | StringifiedStyles
  scrollviewContent: RN.ViewStyle | StringifiedStyles
  inner: RN.ViewStyle | StringifiedStyles
  title: RN.TextStyle | StringifiedStyles
  subtitle: RN.TextStyle | StringifiedStyles
  error: RN.TextStyle | StringifiedStyles
  backButton: RN.ViewStyle | StringifiedStyles
  backButtonText: RN.TextStyle | StringifiedStyles
  button: RN.ViewStyle | StringifiedStyles
  titleContainer: RN.ViewStyle | StringifiedStyles
  content: RN.ViewStyle | StringifiedStyles
  option: RN.ViewStyle | StringifiedStyles
  optionSelected: RN.ViewStyle | StringifiedStyles
  optionContent: RN.ViewStyle | StringifiedStyles
  optionTitle: RN.TextStyle | StringifiedStyles
  optionSubtitle: RN.TextStyle | StringifiedStyles
  listEntryHeader: RN.TextStyle | StringifiedStyles
  listEntryContainer: RN.TextStyle | StringifiedStyles
  listEntryTitle: RN.TextStyle | StringifiedStyles
  listEntryButton: RN.TextStyle | StringifiedStyles
  listFooterContainer: RN.ViewStyle | StringifiedStyles
  listFooterInnerContainer: RN.ViewStyle | StringifiedStyles
}

export const getModalStyleDefinition = (
  theme: Theme,
  height: number = Dimensions.get('window').height * 0.85,
): ModalStyle => {
  const width: number | undefined = Dimensions.get('window').width * 0.9

  return {
    container: {
      flexGrow: 1,
      justifyContent: 'center',
      alignSelf: 'center',
    },
    innerContainer: {
      width,
      height,
      backgroundColor: theme.white,
      borderRadius: '5@ms',
      shadowColor: theme.black,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingTop: '10@mvs0.8',
      paddingHorizontal: '10@mvs0.8',
    },
    closeButton: {
      position: 'absolute',
      top: '10@mvs0.8',
      right: '10@mvs0.8',
    },
    footer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    scrollviewContent: {
      flexGrow: 1,
      paddingTop: '10@mvs0.8',
    },
    inner: {
      flex: 1,
    },
    title: {
      fontSize: '18@ms',
      fontWeight: '700',
      textAlign: 'center',
    },
    subtitle: {
      fontSize: '12@ms0.4',
      fontWeight: '300',
      textAlign: 'center',
    },
    error: {
      fontSize: '10@ms0.3',
      fontWeight: '500',
      textAlign: 'center',
      marginBottom: '20@mvs',
      color: theme.pink[400],
    },
    backButton: {
      alignSelf: 'flex-start',
      marginBottom: 0,
      backgroundColor: theme.white,
      borderColor: theme[300],
    },
    backButtonText: {
      color: theme[500],
    },
    button: {
      alignSelf: 'flex-end',
      marginBottom: 0,
    },
    titleContainer: {
      marginVertical: '20@mvs',
    },
    content: {
      paddingVertical: '20@mvs0.8',
      paddingHorizontal: '15@ms',
      width: Dimensions.get('window').width < 640 ? '100%' : '80%',
      alignSelf: 'center',
    },
    option: {
      borderWidth: 2,
      borderColor: theme[300],
      borderRadius: '5@ms',
      paddingVertical: '10@ms',
      paddingHorizontal: '15@ms',
      marginBottom: '10@s',
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: '80@mvs0.3',
    },
    optionSelected: {
      borderColor: theme.primary,
    },
    optionContent: {
      flex: 1,
      marginLeft: '15@ms',
    },
    optionTitle: {
      fontSize: '12@ms0.4',
      fontWeight: '700',
    },
    optionSubtitle: {
      fontSize: '12@ms0.4',
      fontWeight: '300',
    },
    listEntryHeader: {
      marginBottom: '8@mvs',
      fontSize: '10@ms0.4',
      fontWeight: '300',
    },
    listEntryContainer: {
      flexDirection: 'row',
      marginBottom: '16@mvs',
      borderBottomColor: theme[200],
      borderBottomWidth: '1@mvs',
    },
    listEntryTitle: {
      flexGrow: 1,
      flexShrink: 1,
      marginBottom: '8@mvs',
      fontSize: '12@ms0.4',
      fontWeight: '300',
    },
    listEntryButton: {
      marginBottom: '8@mvs',
    },
    listFooterContainer: {
      flexShrink: 0,
      width: '100%',
      borderTopWidth: '1@mvs',
      borderTopColor: theme[200],
    },
    listFooterInnerContainer: {
      paddingVertical: '10@mvs',
      paddingHorizontal: '10@ms',
      width: Dimensions.get('window').width < 640 ? '100%' : '80%',
      alignSelf: 'center',
    },
  }
}

const getStyles = (theme: Theme, height: number = Dimensions.get('window').height * 0.85) =>
  ScaledSheet.create(getModalStyleDefinition(theme, height))
