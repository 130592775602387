import AsyncStorage from '@react-native-async-storage/async-storage'
import { offline } from '@redux-offline/redux-offline'
import { Platform } from 'react-native'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { combineEpics, createEpicMiddleware } from 'redux-observable'
import createMigration from 'redux-persist-migrate'
import { SETTINGS_ACCEPTED_TERMS_AND_CONDITIONS_VERSION } from '~constants/Settings'
import { authEpic, authReducer, AuthState, setTermsAndConditions } from './auth'
import { chatEpic, chatReducer } from './chat/reducer'
import { ChatState } from './chat/types'
import { groupReducer } from './group/reducer'
import { GroupState } from './group/types'
import middlewares from './middlewares'
import { recentReducer } from './recent/reducer'
import { RecentState } from './recent/types'
import reduxOfflineConfig from './reduxOfflineConfig'
import { storageReducer } from './storage/reducer'
import { StorageState } from './storage/types'

const VERSION_REDUCER_KEY = 'appVersion'

export interface ApplicationState {
  [VERSION_REDUCER_KEY]: { version: number }
  auth: AuthState
  chat: ChatState
  group: GroupState
  recent: RecentState
  storage: StorageState
}

export const rootEpic = combineEpics(authEpic, chatEpic)

export const rootReducer = combineReducers<ApplicationState>({
  [VERSION_REDUCER_KEY]: (state = { version: -1 }) => state,
  auth: authReducer,
  chat: chatReducer,
  group: groupReducer,
  recent: recentReducer,
  storage: storageReducer,
})

const migrations = {
  // 0: (state) => {
  //   // migration clear out device state
  //   return {
  //     ...state,
  //   };
  // },
}

const migration = createMigration(migrations, VERSION_REDUCER_KEY)

const epicMiddleware = createEpicMiddleware()

const myMiddlewares = [...middlewares]

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

reduxOfflineConfig.persistCallback = () => {
  AsyncStorage.getItem(SETTINGS_ACCEPTED_TERMS_AND_CONDITIONS_VERSION).then((value) => {
    if (typeof value === 'string') {
      store.dispatch(setTermsAndConditions(value))
    }
  })
}

const store = offline(reduxOfflineConfig)(createStore)(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...myMiddlewares), applyMiddleware(epicMiddleware), migration),
)

epicMiddleware.run(rootEpic)

if (Platform.OS === 'web') {
  reduxOfflineConfig.persistCallback()
}

export default store
