import { useNavigation } from '@react-navigation/native'
import * as React from 'react'
import { useState } from 'react'
import { TouchableOpacityProps } from 'react-native'
import { Dialog, Paragraph } from 'react-native-paper'
import { mvs, ScaledSheet } from 'react-native-size-matters'
import { CacheableImage } from '~components/CacheableImage'
import IconChatLarge from '~components/IconChatLarge'
import { Action, NavigationItem } from '~components/NavigationItem'
import { View } from '~components/Themed'
import { RecentChat, Role } from '~redux/recent/types'
import ChatService from '~services/chat'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { ExistingChat } from '~types'
import opacity from '~utils/opacity'
import parseChatPath from '~utils/parseChatPath'
import { AlertConfirmation } from './AlertConfirmation'

export interface ChatNavigationItemProps extends TouchableOpacityProps {
  chat: ExistingChat | RecentChat
  role: Role
  title?: string
  isReply?: boolean
  isDashboard?: boolean
}

export function ChatNavigationItem({
  chat,
  role,
  title = chat.name,
  isReply = false,
  isDashboard = false,
}: ChatNavigationItemProps): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const navigation = useNavigation()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const thumbnail =
    'sections' in chat
      ? Object.values((chat as ExistingChat).sections ?? [])
          .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
          .map((section) => section.image.src)
          .find((src) => src.length !== 0)
      : chat.thumbnail

  const onPress = () =>
    navigation.navigate('Main', {
      screen: 'Classes',
      initial: false,
      params: {
        screen: 'ChatScreen',
        initial: false,
        params: {
          path: chat.path,
          role: role,
        },
      },
    })

  const canDelete = (): boolean => {
    return role === 'teacher' || (role === 'student' && isReply)
  }

  const deleteChat = async () => {
    if (!canDelete) {
      return
    }

    await ChatService.deleteChat(chat.path)
  }

  const actions: Action[] = [
    {
      enabled: role === 'teacher',
      name: 'Edit chat settings',
      icon: ['far', 'cog'],
      action: () => {
        navigation.navigate('EditChatModal', {
          path: chat.path,
        })
      },
    },
    {
      enabled: role === 'teacher' && parseChatPath(chat.path).classId !== undefined,
      name: 'View replies',
      icon: ['far', 'users-class'],
      action: () => {
        navigation.navigate('Main', {
          screen: 'Classes',
          initial: false,
          params: {
            screen: 'ChatReplies',
            initial: false,
            params: {
              path: chat.path,
            },
          },
        })
      },
    },
    {
      enabled: !isDashboard && canDelete(),
      name: 'Delete Chat',
      icon: ['far', 'trash'],
      destructive: true,
      action: () => {
        setShowDeleteConfirmation(true)
      },
    },
  ]

  return (
    <>
      <NavigationItem
        title={title}
        onPress={onPress}
        background={
          thumbnail ? (
            <View style={styles.navigationItemContainer}>
              <CacheableImage
                source={{ uri: thumbnail }}
                style={styles.navigationItemImage}
                resizeMode="cover"
                placeholder={
                  <IconChatLarge
                    style={styles.navigationItemPlaceholder}
                    width={mvs(75, 0.6)}
                    height={mvs(75, 0.6)}
                  />
                }
              />
              <View style={styles.navigationItemOverlay} />
            </View>
          ) : (
            <IconChatLarge
              style={styles.navigationItemPlaceholder}
              width={mvs(75, 0.6)}
              height={mvs(75, 0.6)}
            />
          )
        }
        actions={actions}
        backgroundContainerStyle={styles.backgroundContainerStyle}
      />
      {showDeleteConfirmation && (
        <AlertConfirmation
          visible={showDeleteConfirmation}
          setVisible={setShowDeleteConfirmation}
          confirmAction={deleteChat}>
          <Dialog.Title>Delete Chat?</Dialog.Title>
          <Dialog.Content>
            <Paragraph>
              Deleting this chat will remove it from your account. If you have previously shared a
              link to this chat, it will no longer work.
            </Paragraph>
          </Dialog.Content>
        </AlertConfirmation>
      )}
    </>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    navigationItemContainer: {
      flex: 1,
      justifyContent: 'center',
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
    },
    navigationItemImage: {
      flex: 1,
      resizeMode: 'cover',
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
    },
    navigationItemPlaceholder: {
      alignSelf: 'center',
    },
    navigationItemOverlay: {
      flex: 1,
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
      backgroundColor: opacity(theme[500], 0.3),
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    backgroundContainerStyle: {
      borderStartWidth: 0,
      borderTopWidth: 0,
      borderEndWidth: 0,
    },
  })
