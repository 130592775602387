import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import React, { useState } from 'react'
import { ms, ScaledSheet } from 'react-native-size-matters'
import { FormButton } from '~components/FormButton'
import ModalScreen, { getModalStyleDefinition, ModalFooter } from '~components/ModalScreen'
import { Text, View } from '~components/Themed'
import { ValidationError } from '~errors/ValidationError'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { RootStackScreenProps } from '~types'
import { useAxios } from '~utils/fetch'

type Props = RootStackScreenProps<'ReportCommunityChatModal'>

export default function ReportCommunityChatScreen({
  navigation,
  route: { params },
}: Props): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [reported, setReported] = useState(false)

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `/api/v1/chats/${params.id}/reports`,
      method: 'POST',
    },
    { manual: true },
  )

  const report = async () => {
    if (reported) {
      navigation.goBack()
      return
    }

    try {
      const result = await execute()

      if (result.status === 200) {
        setReported(true)
      }
    } catch (error) {
      console.info(error)
    }
  }

  return (
    <ModalScreen
      Footer={
        <ModalFooter>
          <View></View>
          <FormButton onPress={report} title={reported ? 'Close' : 'Report'} disabled={loading} />
          <View></View>
        </ModalFooter>
      }>
      <View style={styles.inner}>
        <View style={[styles.content, styles.contentCenter]}>
          <FontAwesomeIcon
            icon={['fal', reported ? 'shield-check' : 'flag']}
            size={ms(53)}
            color={theme.primary}
            style={styles.button}
          />

          <View style={styles.titleContainer}>
            <Text style={styles.title}>{reported ? 'Chat Reported' : 'Report Chat'}</Text>
            <Text style={styles.subtitle}>
              {reported
                ? 'Thank you for reporting the chat - the Chatta Team will check the content shortly'
                : 'Report this chat for inappropriate content?'}
            </Text>
          </View>

          {error && (
            <Text style={styles.error}>
              {error instanceof ValidationError ? error.message : 'An error occurred'}
            </Text>
          )}
        </View>
      </View>
    </ModalScreen>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    ...getModalStyleDefinition(theme),
    button: {
      alignSelf: 'center',
    },
    contentCenter: {
      justifyContent: 'center',
    },
  })
