import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useNavigation } from '@react-navigation/native'
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ResponseValues } from 'axios-hooks'
import React, { useEffect, useState } from 'react'
import {
  DefaultSectionT,
  Dimensions,
  FlatList,
  ListRenderItemInfo,
  Platform,
  SectionList,
  SectionListData,
  TouchableOpacity,
} from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Dialog, Paragraph } from 'react-native-paper'
import { ms, ScaledSheet } from 'react-native-size-matters'
import uuid from 'react-native-uuid'
import { useSelector } from 'react-redux'
import { cache, mutate as importedMutate, useSWRInfinite } from 'swr'
import { validate } from 'validate.js'
import {
  CreatedStudent,
  GroupResource,
  GroupStudentResourceCollection,
  GroupTeacherResourceCollection,
  StoreStudentResource,
  StoreTeacherResource,
  Student,
  Teacher,
} from '~api/types'
import { AlertConfirmation } from '~components/AlertConfirmation'
import { FormButton } from '~components/FormButton'
import { FormInput } from '~components/FormInput'
import { FormInputButton } from '~components/FormInputButton'
import { FormSwitch } from '~components/FormSwitch'
import GroupPDFButton from '~components/GroupPDFButton'
import Loading from '~components/Loading'
import ModalScreen, { getModalStyleDefinition, ModalFooter } from '~components/ModalScreen'
import TabbedMenu from '~components/TabbedMenu'
import { Text, View } from '~components/Themed'
import { ForbiddenError } from '~errors/ForbiddenError'
import { ValidationError } from '~errors/ValidationError'
import usePermission from '~hooks/usePermission'
import { ApplicationState } from '~redux'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import {
  ClassSettingsSubViewType,
  ClassSettingsViewType,
  DeepRequired,
  RootStackScreenProps,
} from '~types'
import { useAxios } from '~utils/fetch'

type Props = RootStackScreenProps<'ClassSettingsModal'>

const initialView = 'details'
const initialSubview = 'list'

type TeacherEntry = {
  id: string
  email: string
}

type RemoveUser = {
  id: string
  name: string
}

type AlertConfirmationType = 'regenerate-code'

type ChangesType = {
  name: string
  open: boolean
  teachers: TeacherEntry[]
  members: Student[]
}

export interface State {
  view: ClassSettingsViewType
  subview: ClassSettingsSubViewType
  inviting: boolean
  id: string
  class?: GroupResource
  keyboard: boolean // wrap the modal in a KeyboardAwareScrollView
  alert?: AlertConfirmationType
  changes: ChangesType
  createdTeachers: SectionListData<string, DefaultSectionT>[]
  createdTeachersError?: string
  createdStudents?: CreatedStudent[]
}

export const initialState: State = {
  view: initialView,
  subview: initialSubview,
  inviting: true,
  id: '',
  keyboard: true,
  changes: {
    name: '',
    open: true,
    teachers: [],
    members: [],
  },
  createdTeachers: [],
}

const labels: ClassSettingsViewType[] = ['details', 'teachers', 'members']

const classsettingsSlice = createSlice({
  name: 'classsettings',
  initialState,
  reducers: {
    setView: (state, action: PayloadAction<ClassSettingsViewType>) => {
      if (state.view !== action.payload) {
        state.view = action.payload
        state.subview = 'list'
        state.keyboard = action.payload === initialView
      }
    },
    setSubView: (state, action: PayloadAction<ClassSettingsSubViewType>) => {
      state.subview = action.payload
      state.keyboard = false
    },
    setClass: (state, action: PayloadAction<GroupResource>) => {
      state.class = action.payload
      state.changes.name = action.payload.data.name
      state.changes.open = action.payload.data.open
    },
    setAlert: (state, action: PayloadAction<AlertConfirmationType | undefined>) => {
      state.alert = action.payload
    },
    // Details
    setName: (state, action: PayloadAction<string>) => {
      state.changes.name = action.payload
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.changes.open = action.payload
    },
    // Teachers
    setInviting: (state, action: PayloadAction<boolean>) => {
      state.inviting = action.payload
    },
    addTeacher: (state, action: PayloadAction<TeacherEntry>) => {
      state.changes.teachers.push(action.payload)
    },
    removeTeacher: (state, action: PayloadAction<string>) => {
      state.changes.teachers = state.changes.teachers.filter(
        (teacher) => teacher.id !== action.payload,
      )
    },
    clearTeacher: (state) => {
      state.changes.teachers = []
    },
    // Members
    setCreatedStudents: (state, action: PayloadAction<CreatedStudent[] | undefined>) => {
      state.createdStudents = action.payload
    },
    addMember: (state, action: PayloadAction<Student>) => {
      state.changes.members.push(action.payload)
    },
    removeMember: (state, action: PayloadAction<string>) => {
      state.changes.members = state.changes.members.filter((member) => member.id !== action.payload)
    },
    clearMembers: (state) => {
      state.changes.members = []
    },
  },
})
const actions = classsettingsSlice.actions
const store = React.createContext<[State, React.Dispatch<AnyAction>]>([initialState, () => {}])
const { Provider } = store
const useClassSettingsContext = (): [State, React.Dispatch<AnyAction>] => React.useContext(store)

export default function ClassSettingsScreen({ navigation, route: { params } }: Props): JSX.Element {
  const [state, dispatch] = React.useReducer(classsettingsSlice.reducer, {
    ...initialState,
    id: params.id,
    view: params.view ?? initialView,
    subview: params.subview ?? initialSubview,
  })
  const value: [State, React.Dispatch<AnyAction>] = [state, dispatch]
  const { theme } = useTheme()
  const styles = getStyles(theme)

  const [{ data, loading, error }, execute] = useAxios<DeepRequired<GroupResource>>(
    {
      url: `/api/v1/groups/${state.id}`,
      method: 'PATCH',
    },
    { manual: true },
  )

  const [teachersResponse, teachersExecute] = useAxios<StoreTeacherResource>(
    {
      url: `/api/v1/groups/${state.id}/teachers`,
      method: 'POST',
    },
    { manual: true },
  )

  const [membersResponse, membersExecute] = useAxios<StoreStudentResource>(
    {
      url: `/api/v1/groups/${state.id}/students`,
      method: 'POST',
    },
    { manual: true },
  )

  useEffect(() => {
    let cancel = false

    async function load() {
      try {
        const { status, data: group } = await execute()

        if (cancel) {
          return
        }

        if (status === 200) {
          dispatch(actions.setClass(group))
        }
      } catch (error) {
        console.info(error)
      }
    }

    load()

    return () => {
      cancel = true
    }
  }, [execute])

  const renderActiveTab = () => {
    if (loading) {
      return <Loading />
    }

    switch (state.view) {
      case 'teachers':
        return <Teachers response={teachersResponse} />
      case 'members':
        return <Members />
      case 'details':
      default:
        return <Details />
    }
  }

  const renderFooter = () => {
    const hasChanges =
      (state.changes.open !== undefined && state.class?.data.open !== state.changes.open) ||
      (state.changes.name !== undefined && state.class?.data.name !== state.changes.name)

    const saveDetails = async () => {
      if (!hasChanges) {
        return
      }

      try {
        const { status, data: group } = await execute({
          data: {
            name: state.changes.name,
            open: state.changes.open,
          },
        })

        if (status === 200) {
          dispatch(actions.setClass(group))
        }
      } catch (error) {
        console.info(error)
      }
    }

    const createTeachers = async () => {
      try {
        const { status, data } = await teachersExecute({
          data: {
            teachers: state.changes.teachers.map(({ email }) => ({
              email,
            })),
          },
        })

        if (status === 200) {
          dispatch(actions.setInviting(false))
        }
      } catch (error) {
        console.info(error)
      }
    }

    const createStudents = async () => {
      if (state.changes.members.length === 0) {
        return
      }

      try {
        const { status, data: created } = await membersExecute({
          data: {
            students: state.changes.members.map(({ id, name }) => ({
              id,
              name,
            })),
          },
        })

        if (status === 200) {
          dispatch(actions.setCreatedStudents(created.data))
          dispatch(actions.clearMembers())
        }
      } catch (error) {
        if (error instanceof ForbiddenError) {
          navigation.navigate('PermissionDeniedModal', {
            message: error.message,
          })
        } else {
          console.info(error)
        }
      }
    }

    switch (state.view) {
      case 'details':
        return (
          <ModalFooter>
            <View></View>
            <FormButton
              onPress={saveDetails}
              title={'Save Changes'}
              style={styles.button}
              disabled={!hasChanges || loading}
            />
          </ModalFooter>
        )
      case 'teachers':
        switch (state.subview) {
          case 'list':
            return (
              <ModalFooter>
                <FormButton
                  title="Invite Teachers"
                  onPress={() => dispatch(actions.setSubView('create'))}
                  style={styles.button}
                  icon={() => (
                    <FontAwesomeIcon
                      color={theme.white}
                      size={ms(12)}
                      icon={['far', 'envelope-open']}
                      style={{ marginRight: ms(4) }}
                    />
                  )}
                />
              </ModalFooter>
            )
          case 'create':
            return (
              <ModalFooter>
                <FormButton
                  title="Current Teachers"
                  onPress={() => dispatch(actions.setSubView('list'))}
                  style={styles.button}
                  icon={() => (
                    <FontAwesomeIcon
                      color={theme.white}
                      size={ms(12)}
                      icon={['far', 'list']}
                      style={{ marginRight: ms(4) }}
                    />
                  )}
                />
                {!state.inviting ? (
                  <FormButton
                    onPress={() => {
                      dispatch(actions.setInviting(true))
                      dispatch(actions.clearTeacher())
                    }}
                    title={'Add More Teachers'}
                    style={styles.button}
                  />
                ) : (
                  <FormButton
                    onPress={createTeachers}
                    title="Add Teachers"
                    style={styles.button}
                    disabled={state.changes.teachers.length === 0 || teachersResponse.loading}
                  />
                )}
              </ModalFooter>
            )
          default:
            return null
        }
      case 'members':
        switch (state.subview) {
          case 'list':
            return (
              <ModalFooter>
                <FormButton
                  title="Add Members"
                  onPress={() => dispatch(actions.setSubView('create'))}
                  style={styles.button}
                  icon={() => (
                    <FontAwesomeIcon
                      color={theme.white}
                      size={ms(12)}
                      icon={['far', 'plus']}
                      style={{ marginRight: ms(4) }}
                    />
                  )}
                />
              </ModalFooter>
            )
          case 'create':
            return (
              <ModalFooter>
                <FormButton
                  title="Current Members"
                  onPress={() => dispatch(actions.setSubView('list'))}
                  style={styles.button}
                  icon={() => (
                    <FontAwesomeIcon
                      color={theme.white}
                      size={ms(12)}
                      icon={['far', 'list']}
                      style={{ marginRight: ms(4) }}
                    />
                  )}
                />
                {state.createdStudents ? (
                  <FormButton
                    onPress={() => dispatch(actions.setCreatedStudents(undefined))}
                    title="Add More Members"
                    style={styles.button}
                  />
                ) : (
                  <FormButton
                    onPress={() => createStudents()}
                    title="Add Members"
                    style={styles.button}
                    disabled={state.changes.members.length === 0 || loading}
                  />
                )}
              </ModalFooter>
            )
          default:
            return null
        }
      default:
        return null
    }
  }

  return (
    <Provider value={value}>
      <ModalScreen ContainerComponent={React.Fragment} Footer={renderFooter}>
        <TabbedMenu<ClassSettingsViewType>
          labels={labels}
          active={state.view}
          onPress={(index: number) => dispatch(actions.setView(labels[index]))}
        />
        {renderActiveTab()}
      </ModalScreen>
    </Provider>
  )
}

const Details = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, dispatch] = useClassSettingsContext()

  const [{ data, loading, error }, execute] = useAxios<DeepRequired<GroupResource>>(
    {
      url: `/api/v1/groups/${state.id}`,
      method: 'PATCH',
    },
    { manual: true },
  )

  const requestNewCode = async () => {
    try {
      const { status, data: group } = await execute({
        data: {
          code: null,
        },
      })

      if (status === 200) {
        dispatch(actions.setClass(group))
      }
    } catch (error) {
      console.info(error)
    }
  }

  const generateNewCode = () => {
    if (state.id !== undefined) {
      dispatch(actions.setAlert('regenerate-code'))
    }
  }

  return (
    <KeyboardAwareScrollView>
      <View style={styles.inner}>
        <View style={styles.content}>
          <View>
            <FormInput
              label="Name"
              value={state.changes.name}
              onChangeText={(text) => dispatch(actions.setName(text))}
              maxLength={128}
              placeholder="Folder name"
              error={error instanceof ValidationError ? error.errors['name'] : error?.message}
            />
            <FormInputButton
              input={{
                label: 'Invite code',
                value: state.class?.data.code,
                editable: false,
                style: styles.classCode,
              }}
              button={{
                title: 'Generate new code',
                onPress: () => generateNewCode(),
                disabled: loading,
              }}
            />
            <FormSwitch
              description={{ enabled: 'Open', disabled: 'Closed' }}
              label="New members"
              onValueChange={() => dispatch(actions.setOpen(!state.changes.open))}
              value={state.changes.open}
              error={error instanceof ValidationError ? error.errors['open'] : error?.message}
            />
            <AlertConfirmation
              visible={state.alert === 'regenerate-code'}
              setVisible={(visible) => dispatch(actions.setAlert(undefined))}
              confirmAction={requestNewCode}>
              <Dialog.Title>Generate a new code?</Dialog.Title>
              <Dialog.Content>
                <Paragraph>This will change the current folder code.</Paragraph>
                <Paragraph>
                  Anyone using the current code will no longer be able to join your folder.
                </Paragraph>
              </Dialog.Content>
            </AlertConfirmation>
          </View>
        </View>
      </View>
    </KeyboardAwareScrollView>
  )
}

const Teachers = ({ response }: { response: ResponseValues<StoreTeacherResource, any> }) => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, dispatch] = useClassSettingsContext()
  const [email, setEmail] = useState('')
  const [emailValidate, setEmailValidate] = useState<{ email: string[] } | undefined>()
  const user_id = useSelector((state: ApplicationState) => state.auth.user?.claims.sub)
  const [showDeleteTeacherConfirmation, setShowDeleteTeacherConfirmation] = useState(false)
  const [removeUser, setRemoveUser] = useState<RemoveUser>()

  const getKey = (
    pageIndex: number,
    previousPageData: GroupTeacherResourceCollection | null = null,
  ) => {
    // First
    if (pageIndex === 0) {
      return `/api/v1/groups/${state.id}/teachers`
    }

    // End
    if (previousPageData && previousPageData.links.next !== null) {
      return previousPageData.links.next
    }

    return null
  }

  const {
    data,
    size,
    setSize,
    error: getError,
    revalidate,
    mutate,
    isValidating,
  } = useSWRInfinite<GroupTeacherResourceCollection>(getKey)

  useEffect(() => {
    if (!state.inviting) {
      revalidate()
    }
  }, [revalidate, state.inviting])

  const teachers = (data ?? [])
    .flatMap((o) => o.data)
    .filter((item, index, array) => {
      return array.findIndex((o) => o.id === item.id) === index
    })

  const more = !data || (size > 0 && data[size - 1]?.links.next !== null)

  const [{ data: deleteData, loading: deleteLoading, error: deleteError }, executeDelete] =
    useAxios<never>({}, { manual: true })

  const addTeacher = () => {
    dispatch(
      actions.addTeacher({
        id: uuid.v4(),
        email,
      }),
    )
    setEmail('')
  }

  const requestTeacherRemovedFromGroup = async () => {
    if (!removeUser || !removeUser.id) {
      return
    }

    try {
      await executeDelete({
        url: `/api/v1/groups/${state.id}/teachers/${removeUser.id}`,
        method: 'DELETE',
        validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
      })

      // Uses SWR internals so check it exists - https://github.com/vercel/swr/issues/1156
      const infiniteKey = ['inf', cache.serializeKey(getKey(0))[0]]
      if (!cache.has(infiniteKey)) {
        console.warn('infiniteKey not found')
        return
      }

      importedMutate(
        infiniteKey,
        (resource: GroupTeacherResourceCollection[]) =>
          resource.map((resource) => ({
            ...resource,
            data: resource.data.filter((o) => o.id !== removeUser.id),
          })),
        false,
      )
    } catch (error) {
      console.info(error)
    }
  }

  const removeTeacher = (user: RemoveUser) => {
    setRemoveUser(user)
    setShowDeleteTeacherConfirmation(true)
  }

  const renderHeader = () => (
    <>
      {getError && (
        <Text style={styles.error}>
          {getError instanceof ValidationError ? getError.message : 'An error occurred'}
        </Text>
      )}
    </>
  )

  const renderItem = ({ item: { id, name } }: ListRenderItemInfo<Teacher>) => {
    return (
      <View style={styles.listEntryContainer} key={id}>
        <Text style={styles.listEntryTitle}>{name}</Text>
        {user_id !== id && (
          <TouchableOpacity onPress={() => removeTeacher({ id, name })}>
            <FontAwesomeIcon
              style={styles.listEntryButton}
              size={ms(12)}
              icon={['far', 'trash-alt']}
              color={theme[400]}
            />
          </TouchableOpacity>
        )}
      </View>
    )
  }

  const renderEmpty = (
    <View>
      {response.data?.data.ignored.length === 0 && response.data?.data.invited.length === 0 && (
        <Text style={styles.error}>No changes were made.</Text>
      )}

      {response.error && (
        <Text style={styles.error}>
          {response.error instanceof ValidationError ? response.error.message : 'An error occurred'}
        </Text>
      )}

      <FormInputButton
        input={{
          value: email,
          placeholder: 'Email address',
          keyboardType: 'email-address',
          autoCapitalize: 'none',
          onChangeText: (changedEmail: string) => {
            setEmailValidate(
              validate(
                { email: changedEmail },
                {
                  email: {
                    email: true,
                  },
                },
              ),
            )
            setEmail(changedEmail)
          },
        }}
        button={{
          title: 'Add',
          onPress: () => addTeacher(),
          disabled: !email || emailValidate !== undefined,
        }}
        error={email && emailValidate?.email}
      />

      {state.changes.teachers.length === 0 ? (
        <>
          <Text>Use the form above to start adding teachers.</Text>
          <Text>
            If any user you add to become a teacher of this folder does not exist, we will email
            them an invitation to create an account.
          </Text>
        </>
      ) : (
        state.changes.teachers.map(({ id, email }) => {
          return (
            <View style={styles.listEntryContainer} key={`teacher-${id}`}>
              <Text style={styles.listEntryTitle}>{email}</Text>
              <TouchableOpacity onPress={() => dispatch(actions.removeTeacher(id))}>
                <FontAwesomeIcon
                  style={styles.listEntryButton}
                  size={ms(12)}
                  icon={['fal', 'minus-circle']}
                  color={theme[400]}
                />
              </TouchableOpacity>
            </View>
          )
        })
      )}
    </View>
  )

  switch (state.subview) {
    case 'create':
      const sections = !state.inviting
        ? (() => {
            const data = []

            if (response.data?.data) {
              const { ignored = [], invited = [] } = response.data.data

              if (ignored.length) {
                data.push({
                  title:
                    'The following emails have recently been sent an invitation, please try again in 5 minutes.',
                  data: ignored.map((o) => o.email) ?? [],
                })
              }

              if (invited.length) {
                data.push({
                  title:
                    'The following emails have been sent a invitation to become a teacher on the folder:',
                  data: invited.map((o) => o.email) ?? [],
                })
              }
            }

            return data
          })()
        : []

      return (
        <SectionList<string>
          sections={sections}
          contentContainerStyle={styles.listContainer}
          keyExtractor={(item, index) => item + index}
          renderItem={({ item }) => <Text style={styles.addedTeacher}>{item}</Text>}
          renderSectionHeader={({ section: { title } }) => (
            <Text style={styles.addedTeacherTitle}>{title}</Text>
          )}
          ListEmptyComponent={renderEmpty}
        />
      )
    case 'list':
    default:
      return (
        <View style={styles.flex}>
          {!data ? (
            <Loading />
          ) : (
            <>
              <FlatList<Teacher>
                key={`classsettings-teacher`}
                contentContainerStyle={styles.listContainer}
                data={teachers}
                ListHeaderComponent={renderHeader}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                onEndReached={() => more && setSize(size + 1)}
                onEndReachedThreshold={0.5}
                style={
                  // No idea why any value for height works on web
                  Platform.OS === 'web' ? { height: 0 } : undefined
                }
              />
              {showDeleteTeacherConfirmation && (
                <AlertConfirmation
                  visible={showDeleteTeacherConfirmation}
                  setVisible={setShowDeleteTeacherConfirmation}
                  confirmAction={requestTeacherRemovedFromGroup}>
                  <Dialog.Title>Remove Teacher</Dialog.Title>
                  <Dialog.Content>
                    <Paragraph>{`Are you sure you want to remove ${removeUser?.name} from the folder?`}</Paragraph>
                  </Dialog.Content>
                </AlertConfirmation>
              )}
            </>
          )}
        </View>
      )
  }
}

const Members = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, dispatch] = useClassSettingsContext()
  const [showDeleteStudentConfirmation, setShowDeleteStudentConfirmation] = useState(false)
  const [removeUser, setRemoveUser] = useState<RemoveUser>()
  const [name, setName] = useState('')
  const navigation = useNavigation()

  const getKey = (
    pageIndex: number,
    previousPageData: GroupTeacherResourceCollection | null = null,
  ) => {
    // First
    if (pageIndex === 0) {
      return `/api/v1/groups/${state.id}/students`
    }

    // End
    if (previousPageData && previousPageData.links.next !== null) {
      return previousPageData.links.next
    }

    return null
  }

  const {
    data,
    size,
    setSize,
    error: getError,
    revalidate,
  } = useSWRInfinite<GroupStudentResourceCollection>(getKey)

  const existingStudents = (data ?? [])
    .flatMap((o) => o.data)
    .filter((item, index, array) => {
      return array.findIndex((o) => o.id === item.id) === index
    })

  const more = !data || (size > 0 && data[size - 1]?.links.next !== null)

  const students_count = data && size > 0 ? data[size - 1]?.meta.total : 0

  const [{ loading: deleteLoading, error: deleteError }, executeDelete] = useAxios<never>(
    {},
    { manual: true },
  )

  const permission_add_student = usePermission(
    'restrictions_max_students_per_classes',
    students_count + state.changes.members.length,
  )

  const requestStudentRemovedFromGroup = async () => {
    if (!removeUser || !removeUser.id) {
      return
    }

    try {
      await executeDelete({
        url: `/api/v1/groups/${state.id}/students/${removeUser.id}`,
        method: 'DELETE',
        validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
      })

      // Uses SWR internals so check it exists - https://github.com/vercel/swr/issues/1156
      const infiniteKey = ['inf', cache.serializeKey(getKey(0))[0]]
      if (!cache.has(infiniteKey)) {
        console.warn('infiniteKey not found')
        return
      }

      importedMutate(
        infiniteKey,
        (resource: GroupStudentResourceCollection[]) =>
          resource.map((resource) => ({
            ...resource,
            data: resource.data.filter((o) => o.id !== removeUser.id),
          })),
        false,
      )
    } catch (error) {
      console.info(error)
    }
  }

  const removeExistingStudent = (user: RemoveUser) => {
    setRemoveUser(user)
    setShowDeleteStudentConfirmation(true)
  }

  const renderHeader = () => (
    <>
      {getError && (
        <Text style={styles.error}>
          {getError instanceof ValidationError ? getError.message : 'An error occurred'}
        </Text>
      )}
    </>
  )

  const renderItem = ({ item: { id, name } }: ListRenderItemInfo<Teacher>) => {
    return (
      <View style={styles.listEntryContainer} key={id}>
        <Text style={styles.listEntryTitle}>{name}</Text>
        <TouchableOpacity
          onPress={() => removeExistingStudent({ id, name })}
          disabled={deleteLoading}>
          <FontAwesomeIcon
            style={styles.listEntryButton}
            size={ms(12)}
            icon={['far', 'trash-alt']}
            color={theme[400]}
          />
        </TouchableOpacity>
      </View>
    )
  }

  const removeStudent = (id: string) => {
    dispatch(actions.removeMember(id))
  }

  const renderAddStudent = ({ item: { id, name } }: ListRenderItemInfo<Student>) => {
    return (
      <View style={styles.listEntryContainer} key={id}>
        <Text style={styles.listEntryTitle}>{name}</Text>
        <TouchableOpacity onPress={() => removeStudent(id)}>
          <FontAwesomeIcon
            style={styles.listEntryButton}
            size={ms(12)}
            icon={['fal', 'minus-circle']}
            color={theme[400]}
          />
        </TouchableOpacity>
      </View>
    )
  }

  const addStudent = () => {
    if (permission_add_student.status === 'allowed') {
      dispatch(
        actions.addMember({
          id: uuid.v4(),
          name,
        }),
      )
      setName('')
    } else {
      navigation.navigate('PermissionDeniedModal', {
        message: permission_add_student.message,
      })
    }
  }

  useEffect(() => {
    revalidate()
  }, [revalidate, state.createdStudents])

  switch (state.subview) {
    case 'create':
      return (
        <View style={styles.inner}>
          <View style={styles.content}>
            {state.createdStudents ? (
              <View>
                {state.createdStudents.length ? (
                  <View>
                    <View style={styles.titleContainer}>
                      <Text style={styles.title}>Setup Instructions</Text>
                      <Text style={styles.subtitle}>
                        Generate a PDF of instructions for your members.
                      </Text>
                    </View>
                    {state.class && (
                      <GroupPDFButton
                        classId={state.class.data.id}
                        students={state.createdStudents}
                      />
                    )}
                  </View>
                ) : (
                  <Text style={styles.addedTeacherTitle}>No members were created.</Text>
                )}
              </View>
            ) : (
              <FlatList<Student>
                key={`classsettings-add-student`}
                data={state.changes.members}
                renderItem={renderAddStudent}
                ListHeaderComponent={
                  <>
                    <Text style={styles.studentIntroText}>
                      Enter the full names of your members below. An account will be created for
                      each one, with their login details available on the next screen.
                    </Text>
                    <View>
                      <FormInputButton
                        input={{
                          value: name,
                          placeholder: 'Full name',
                          onChangeText: (changedName: string) => setName(changedName),
                        }}
                        button={{
                          title: 'Add',
                          onPress: () => addStudent(),
                          disabled: !name,
                        }}
                      />
                    </View>
                  </>
                }
              />
            )}
          </View>
        </View>
      )
    case 'list':
    default:
      return (
        <View style={styles.flex}>
          {!data ? (
            <Loading />
          ) : (
            <>
              <FlatList<Student>
                key={`classsettings-student`}
                contentContainerStyle={styles.listContainer}
                data={existingStudents}
                ListHeaderComponent={renderHeader}
                renderItem={renderItem}
                keyExtractor={(item) => item.id}
                onEndReached={() => more && setSize(size + 1)}
                onEndReachedThreshold={0.5}
                ListEmptyComponent={
                  <Text>
                    This folder has no members. You can start adding them by using the button below.
                  </Text>
                }
              />
              {showDeleteStudentConfirmation && (
                <AlertConfirmation
                  visible={showDeleteStudentConfirmation}
                  setVisible={setShowDeleteStudentConfirmation}
                  confirmAction={requestStudentRemovedFromGroup}>
                  <Dialog.Title>Remove Member</Dialog.Title>
                  <Dialog.Content>
                    <Paragraph>{`Are you sure you want to remove ${removeUser?.name} from the folder?`}</Paragraph>
                  </Dialog.Content>
                </AlertConfirmation>
              )}
            </>
          )}
        </View>
      )
  }
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    ...getModalStyleDefinition(theme),
    classCode: {
      color: theme.primary,
      letterSpacing: 5,
    },
    actions: {
      marginBottom: '16@mvs',
    },
    addedTeacherTitle: {
      fontSize: '12@ms0.4',
      fontWeight: '400',
      marginBottom: '2@mvs',
    },
    addedTeacher: {
      fontSize: '12@ms0.2',
      fontWeight: '300',
      marginBottom: '4@mvs',
    },
    flex: {
      flex: 1,
    },
    studentIntroText: {
      marginBottom: '16@mvs',
    },
    listContainer: {
      paddingVertical: '20@mvs',
      paddingHorizontal: '10@ms',
      width: Dimensions.get('window').width < 640 ? '100%' : '80%',
      alignSelf: 'center',
    },
  })
