import * as React from 'react'
import { mvs } from 'react-native-size-matters'
import Svg, { G, Path, Rect, SvgProps } from 'react-native-svg'

const IconChatLarge = (props: SvgProps): JSX.Element => (
  <Svg width={mvs(90, 0.8)} height={mvs(90, 0.8)} viewBox="0 0 98 107.922" {...props}>
    <G transform="translate(-6.67 -2.56)">
      <Rect
        data-name="Rectangle 1530"
        width={78.07}
        height={60.29}
        rx={5.17}
        transform="translate(17.46 26.35)"
        fill="#f8f7f9"
      />
      <Path
        data-name="Path 1263"
        d="M90.37 24.57H22.63a6.965 6.965 0 0 0-6.96 6.96v49.95a6.965 6.965 0 0 0 6.96 6.96h67.74a6.965 6.965 0 0 0 6.96-6.96V31.52a6.965 6.965 0 0 0-6.96-6.96ZM55.08 84.85H22.63a3.363 3.363 0 0 1-3.36-3.37V57.92h35.81Zm0-29.76H19.27V31.52a3.363 3.363 0 0 1 3.36-3.37h32.45v26.93Zm38.65 26.39a3.369 3.369 0 0 1-3.36 3.37H57.91V57.92h35.82v23.56Zm0-26.39H57.91V28.15h32.46a3.369 3.369 0 0 1 3.36 3.37v23.56Zm.58 27.62a4.314 4.314 0 0 0 .17-.81 3.646 3.646 0 0 1-.17.81Z"
        fill="#9c99b0"
      />
    </G>
  </Svg>
)

export default IconChatLarge
