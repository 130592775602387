import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { AnyAction, createAction, createReducer } from '@reduxjs/toolkit'
import React, { useState } from 'react'
import {
  Dimensions,
  ImageBackground,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import { ms, ScaledSheet } from 'react-native-size-matters'
import ChattaLogo from '~components/ChattaLogo'
import { FormButton } from '~components/FormButton'
import { FormInput } from '~components/FormInput'
import SocialSignIn from '~components/SocialSignIn'
import { Text } from '~components/Themed'
import { ValidationError } from '~errors/ValidationError'
import { auth } from '~providers/firebase'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { MainStackScreenProps } from '~types'
import { useAxiosUnauthenticated } from '~utils/fetch'
import opacity from '~utils/opacity'

export interface State {
  view: 'login' | 'reset'
}

export const initialState: State = {
  view: 'login',
}

export const setView = createAction<'login' | 'reset', 'setView'>('setView')

const reducer = createReducer(initialState, (builder) =>
  builder.addCase(setView, (state, action) => {
    state.view = action.payload
  }),
)

type Props = MainStackScreenProps<'Login'>

const dismissKeyboard = Platform.select({
  web: () => {},
  default: Keyboard.dismiss,
})

const store = React.createContext<[State, React.Dispatch<AnyAction>]>([initialState, () => {}])
const { Provider } = store
const useLoginContext = (): [State, React.Dispatch<AnyAction>] => React.useContext(store)

const Login = ({ navigation }: Props) => {
  const { theme } = useTheme()
  const styles = getStyles(theme)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [{ data, loading, error }, execute] = useAxiosUnauthenticated(
    {
      url: '/api/v1/login',
      method: 'POST',
    },
    { manual: true },
  )

  const login = async () => {
    try {
      const res = await execute({
        data: {
          username,
          password,
        },
      })

      if (res.status === 200) {
        await auth().signInWithCustomToken(res.data.token)
      }
    } catch (error) {
      console.info(error)
    }
  }

  const canSubmit = username.length > 0 && password.length > 0

  const [state, stateDispatch] = useLoginContext()

  return (
    <TouchableWithoutFeedback onPress={dismissKeyboard}>
      <View style={styles.form}>
        <View style={styles.inner}>
          <View>
            <ChattaLogo />
          </View>
          <View>
            <View style={styles.formBackground}>
              <Text style={styles.title}>Log in</Text>

              <FormInput
                style={styles.input}
                value={username}
                placeholder="Username"
                onChangeText={setUsername}
                autoCapitalize="none"
                keyboardType="email-address"
                autoCorrect={false}
              />
              <FormInput
                style={styles.input}
                value={password}
                placeholder="Password"
                onChangeText={setPassword}
                secureTextEntry={true}
              />

              {error instanceof ValidationError && (
                <Text style={styles.error}>
                  {[error.errors['username'] ?? error?.message].flat().join('\n')}
                </Text>
              )}

              <FormButton
                inactiveButtonStyle={styles.inactiveButtonStyle}
                textStyle={styles.buttonText}
                title={loading ? 'Loading...' : 'Log in'}
                disabled={!canSubmit}
                onPress={login}
              />

              <TouchableOpacity
                onPress={() => stateDispatch(setView('reset'))}
                style={styles.forgotPasswordContainer}>
                <Text style={styles.forgotPasswordText}>Forgot your password?</Text>
              </TouchableOpacity>
              <SocialSignIn seperator={true} seperatorText="Or Sign-in using:" />
            </View>
            <View style={styles.signUpBackground}>
              <TouchableOpacity
                style={styles.signUpContainer}
                onPress={() => navigation.navigate('Register')}>
                <Text style={styles.forgotPasswordText}>Don’t have an account? </Text>
                <Text style={[styles.forgotPasswordText, styles.signUpNowText]}>Sign up Now!</Text>
              </TouchableOpacity>
            </View>
            {Platform.OS !== 'web' && (
              <View>
                <TouchableOpacity
                  style={styles.diagnosticsContainer}
                  onPress={() => navigation.navigate('Diagnostics')}>
                  <Text style={styles.diagnosticsText}>Diagnostics</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const Reset = () => {
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const { theme } = useTheme()

  const styles = getStyles(theme)

  const [state, stateDispatch] = useLoginContext()

  const [{ data, loading, error }, execute] = useAxiosUnauthenticated(
    {
      url: '/api/v1/forgot-password',
      method: 'POST',
    },
    { manual: true },
  )

  const recover = async () => {
    try {
      const res = await execute({
        data: {
          email,
        },
      })

      if (res.status === 200) {
        setForgotPasswordSuccess(true)
      }
    } catch (error) {
      console.info(error)
    }
  }

  const canSubmit = email.length > 0 && !loading

  return (
    <TouchableWithoutFeedback onPress={dismissKeyboard}>
      <View style={styles.form}>
        <View style={styles.inner}>
          {forgotPasswordSuccess ? (
            <View>
              <View>
                <ChattaLogo />
              </View>
              <View style={[styles.formBackground, styles.formBackgroundResetSubmitted]}>
                <Text style={styles.title}>Reset Submitted</Text>

                <Text style={styles.instructions}>
                  Please check your email for further instructions. If you do not receive an email
                  within the next few minutes, please check your spam folder.
                </Text>

                <FormButton
                  inactiveButtonStyle={styles.inactiveButtonStyle}
                  textStyle={styles.buttonText}
                  title={'Log in'}
                  onPress={() => stateDispatch(setView('login'))}
                />
              </View>
            </View>
          ) : (
            <View>
              <View>
                <ChattaLogo />
              </View>
              <View style={styles.formBackground}>
                <Text style={styles.title}>Reset Your Password</Text>
                <Text style={styles.instructions}>
                  Enter your user account's email address and we will send you a password reset
                  link.
                </Text>
                <FormInput
                  style={styles.input}
                  value={email}
                  placeholder="Enter your email address"
                  onChangeText={setEmail}
                  autoCapitalize="none"
                  keyboardType="email-address"
                  autoCorrect={false}
                  error={error instanceof ValidationError ? error.errors['email'] : undefined}
                />

                <FormButton
                  inactiveButtonStyle={styles.inactiveButtonStyle}
                  textStyle={styles.buttonText}
                  title={loading ? 'Loading...' : 'Send password reset email'}
                  disabled={!canSubmit}
                  onPress={recover}
                />
              </View>
              <View style={[styles.signUpBackground, styles.cancelBackground]}>
                <TouchableOpacity
                  style={styles.signUpContainer}
                  onPress={() => stateDispatch(setView('login'))}>
                  <FontAwesomeIcon
                    color={theme.pink[800]}
                    size={ms(10, 0.4)}
                    icon={['fal', 'chevron-left']}
                  />
                  <Text style={[styles.cancelText]}>Cancel</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

export default function LoginScreen(props: Props): JSX.Element {
  const { mode, theme } = useTheme()
  const styles = getStyles(theme)

  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value: [State, React.Dispatch<AnyAction>] = [state, dispatch]

  const [dimensions, setDimensions] = useState({
    window: Dimensions.get('window'),
    screen: Dimensions.get('screen'),
  })

  const onChange = ({ window, screen }) => {
    setDimensions({ window, screen })
  }

  React.useEffect(() => {
    Dimensions.addEventListener('change', onChange)
    return () => {
      Dimensions.removeEventListener('change', onChange)
    }
  })

  const backgroundImage = React.useMemo(
    () =>
      dimensions.window.width < 640
        ? mode === 'dark'
          ? require('../assets/images/background-login-mobile-dark.jpg')
          : require('../assets/images/background-login-mobile.jpg')
        : mode === 'dark'
        ? require('../assets/images/background-login-tablet-dark.jpg')
        : require('../assets/images/background-login-tablet.jpg'),
    [dimensions, mode],
  )

  return (
    <View style={styles.container}>
      <ImageBackground source={backgroundImage} style={styles.background}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={styles.container}>
          <Provider value={value}>
            {state.view === 'login' ? <Login {...props} /> : <Reset />}
          </Provider>
        </KeyboardAvoidingView>
      </ImageBackground>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    container: {
      flex: 1,
    },
    inner: {
      flex: 1,
      alignSelf: Dimensions.get('window').width < 640 ? 'center' : 'flex-start',
      justifyContent: 'center',
      paddingVertical: '10@mvs',
      paddingHorizontal: '20@ms',
    },
    form: {
      flex: 1,
    },
    input: {
      backgroundColor: theme.white,
      borderColor: theme.primary,
    },
    buttonText: {
      color: theme.white,
    },
    title: {
      fontSize: '20@ms',
      fontWeight: '600',
      color: theme.white,
      marginBottom: '10@mvs',
    },
    background: {
      flex: 1,
      width: '100%',
      height: '100%',
      resizeMode: 'cover',
      justifyContent: 'center',
    },
    error: {
      textAlign: 'center',
      color: theme.pink[400],
      marginBottom: '10@s',
      fontSize: '10@ms0.4',
    },
    instructions: {
      marginBottom: '10@s',
      color: theme.white,
      fontSize: '10@ms0.4',
    },
    forgotPasswordContainer: {
      marginBottom: '5@mvs',
    },
    forgotPasswordText: {
      textAlign: 'center',
      color: theme.white,
      fontSize: '10@ms0.4',
      fontWeight: '400',
    },
    formBackground: {
      width: '300@ms0.3',
      marginTop: '10@mvs0.4',
      backgroundColor: opacity(theme[800], 0.75),
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
      paddingHorizontal: '20@ms',
      paddingVertical: '10@mvs0.8',
    },
    formBackgroundResetSubmitted: {
      borderBottomLeftRadius: '5@ms',
      borderBottomRightRadius: '5@ms',
    },
    signUpBackground: {
      backgroundColor: opacity(theme.pink[800], 0.75),
      borderBottomLeftRadius: '5@ms',
      borderBottomRightRadius: '5@ms',
      borderTopWidth: 1,
      borderTopColor: opacity(theme.pink[900], 0.75),
      fontWeight: '400',
    },
    cancelBackground: {
      backgroundColor: opacity(theme[100], 0.75),
      borderTopColor: opacity(theme[200], 0.75),
    },
    cancelText: {
      textAlign: 'center',
      color: theme.pink[800],
      fontSize: '10@ms0.4',
      fontWeight: '400',
    },
    signUpNowText: {
      textDecorationLine: 'underline',
    },
    signUpContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: '8@ms',
      paddingVertical: '8@mvs',
    },
    inactiveButtonStyle: {
      backgroundColor: theme[400],
      borderColor: theme[400],
    },
    diagnosticsContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: '8@ms',
      paddingVertical: '4@mvs',
    },
    diagnosticsText: {
      textAlign: 'center',
      color: theme.white,
      fontSize: '10@ms0.4',
      fontWeight: '400',
    },
  })
