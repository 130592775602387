import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { DefaultHeaderLeft } from '~routers/components/DefaultHeaderLeft'
import ChatRepliesScreen from '~screens/ChatRepliesScreen'
import ChatScreen from '~screens/ChatScreen'
import JoinClassScreen from '~screens/JoinClassScreen'
import MyClassesScreen from '~screens/MyClassesScreen'
import { useTheme } from '~theme/ThemeManager'
import { ClassesStackParamList } from '~types'
import ClassTabsNavigator from './ClassTabsNavigator'

const Navigator = createStackNavigator<ClassesStackParamList>()

export default function ClassNavigator(): JSX.Element {
  const { theme } = useTheme()

  return (
    <Navigator.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: theme[100],
        },
        headerTitleStyle: {
          color: theme.text,
        },
        headerTintColor: theme.primary,
        headerLeft: (props) => <DefaultHeaderLeft {...props} />,
      }}
      initialRouteName="MyClasses">
      <Navigator.Screen
        name="MyClasses"
        component={MyClassesScreen}
        options={{
          title: 'My folders',
        }}
      />
      <Navigator.Screen name="Class" component={ClassTabsNavigator} />
      <Navigator.Screen name="ChatScreen" component={ChatScreen} />
      <Navigator.Screen
        name="ChatReplies"
        component={ChatRepliesScreen}
        options={{
          title: 'Replies',
        }}
      />
      <Navigator.Screen
        name="JoinClass"
        component={JoinClassScreen}
        options={{
          title: 'Join a folder',
        }}
      />
    </Navigator.Navigator>
  )
}
