import React, { ReactNode, useState } from 'react'
import { View } from 'react-native'
import { TextInput, useTheme as usePaperTheme } from 'react-native-paper'
import DropDown from 'react-native-paper-dropdown'
import { ScaledSheet } from 'react-native-size-matters'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'

export interface DropdownProps {
  list: Array<{
    label: string
    value: string | number
    custom?: ReactNode
  }>
  value: any
  setValue: (_value: any) => void
  placeholder?: string
}

export function Dropdown(props: DropdownProps): JSX.Element {
  const [showDropdown, setShowDropdown] = useState(false)
  const { theme } = useTheme()
  const paperTheme = usePaperTheme()
  const styles = getStyles(theme)
  const { list, value, setValue, placeholder = 'Please select...', ...rest } = props

  return (
    <View style={styles.container}>
      <DropDown
        placeholder={placeholder}
        visible={showDropdown}
        showDropDown={() => setShowDropdown(true)}
        onDismiss={() => setShowDropdown(false)}
        value={value}
        setValue={(value) => setValue(value)}
        list={list}
        inputProps={{
          style: styles.dropdown,
          underlineColor: 'transparent',
          underlineColorAndroid: 'transparent',
          right: (
            <TextInput.Icon
              name={showDropdown ? 'menu-up' : 'menu-down'}
              theme={{
                ...paperTheme,
                colors: {
                  ...paperTheme.colors,
                  text: theme[500],
                },
              }}
            />
          ),
        }}
        {...rest}
      />
    </View>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    container: {
      marginBottom: '16@mvs',
    },
    dropdown: {
      height: '34@mvs0.4',
      fontSize: '12@ms0.4',
      width: '260@ms0.3',
      backgroundColor: theme.white,
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
      borderBottomLeftRadius: '5@ms',
      borderBottomRightRadius: '5@ms',
      borderColor: theme[200],
      borderWidth: 1,
    },
  })
