import { getDefaultHeaderHeight } from '@react-navigation/elements'
import React from 'react'
import { Dimensions, FlatList, StatusBar, useWindowDimensions } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ScaledSheet } from 'react-native-size-matters'
import { ChatNavigationItem } from '~components/ChatNavigationItem'
import Loading from '~components/Loading'
import RenderError from '~components/RenderError'
import { View } from '~components/Themed'
import useColumns from '~hooks/useColumns'
import ChatService from '~services/chat'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { ClassesStackScreenProps } from '~types'
import { usePagination } from '~utils/react-firebase-pagination-hooks'

type Props = ClassesStackScreenProps<'ChatReplies'>

export default function ChatRepliesScreen({ navigation, route }: Props): JSX.Element {
  const { theme } = useTheme()
  const dimensions = useWindowDimensions()
  const insets = useSafeAreaInsets()
  const headerHeight = React.useMemo(
    () => getDefaultHeaderHeight(dimensions, false, insets.top),
    [dimensions, insets],
  )
  const styles = getStyles(theme, headerHeight)
  const columns = useColumns()

  const [items, { loaded, hasMore, loadingMore, loadMore }, error] = usePagination(
    ChatService.listChatReplies(route.params.path),
    {
      limit: 20,
    },
  )

  const renderItem = ({ item }) => {
    const chat = ChatService.chatFromSnapshot(item)

    return <ChatNavigationItem chat={chat} role={'teacher'} title={chat.author_name} />
  }

  const renderItemSeparatorComponent = () => <View style={styles.itemSeparator} />

  const renderEmptyList = !loaded ? (
    <View style={styles.emptyContainer}>
      <Loading />
    </View>
  ) : (
    <View style={styles.emptyContainer}>
      <RenderError
        title="Oops, nothing here!"
        description="No replies to this chat have been found."
      />
    </View>
  )

  return (
    <>
      <FlatList
        key={`class-${columns}`}
        contentContainerStyle={styles.listContainer}
        data={items}
        numColumns={columns}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        ItemSeparatorComponent={renderItemSeparatorComponent}
        onEndReached={() => {
          if (loaded && hasMore && !loadingMore) {
            loadMore()
          }
        }}
        onEndReachedThreshold={0.5}
        ListEmptyComponent={renderEmptyList}
      />
    </>
  )
}

const getStyles = (theme: Theme, headerHeight: number) =>
  ScaledSheet.create({
    listContainer: {
      paddingVertical: '20@mvs',
      paddingHorizontal: '10@ms',
      minHeight: Dimensions.get('window').height - (StatusBar.currentHeight ?? 0) - headerHeight,
    },
    itemSeparator: {
      height: '16@ms',
    },
    emptyContainer: {
      paddingHorizontal: '10@ms',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
