import { createAction } from '@reduxjs/toolkit'
import React, { useState } from 'react'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { ScaledSheet } from 'react-native-size-matters'
import { GroupResource } from '~api/types'
import { FormButton } from '~components/FormButton'
import { FormInput } from '~components/FormInput'
import ModalScreen, { getModalStyleDefinition, ModalFooter } from '~components/ModalScreen'
import { Text, View } from '~components/Themed'
import { ForbiddenError } from '~errors/ForbiddenError'
import { ValidationError } from '~errors/ValidationError'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { DeepRequired, RootStackScreenProps } from '~types'
import { useAxios } from '~utils/fetch'

export type CreateClassPopupProps = RootStackScreenProps<'CreateClass'>

export const setName = createAction<string, 'setName'>('setName')

export default function CreateClassScreen({
  navigation,
  route,
}: CreateClassPopupProps): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [name, setName] = useState('')

  const [{ data, loading, error }, execute] = useAxios<DeepRequired<GroupResource>>(
    {
      url: `/api/v1/groups`,
      method: 'POST',
    },
    { manual: true },
  )

  const create = async () => {
    try {
      const { data: folder } = await execute({
        data: {
          name,
        },
      })

      switch (route.params?.context) {
        case 'chat':
          navigation.navigate({
            name: 'NewChatModal',
            params: {
              class: {
                id: folder?.data.id,
                name: folder?.data.name,
              },
            },
            merge: true,
          })
          break
        case 'none':
        default:
          navigation.navigate('Main', {
            screen: 'Classes',
            initial: false,
            params: {
              screen: 'Class',
              initial: false,
              params: {
                screen: 'ClassChats',
                initial: false,
                params: {
                  id: folder?.data.id,
                  name: folder?.data.name,
                },
              },
            },
          })
          break
      }
    } catch (error) {
      if (error instanceof ForbiddenError) {
        navigation.goBack()
        navigation.navigate('PermissionDeniedModal', {
          message: error.message,
        })
      } else {
        console.info(error)
      }
    }
  }

  return (
    <ModalScreen
      ContainerComponent={KeyboardAwareScrollView}
      Footer={
        <ModalFooter>
          <View></View>
          <FormButton
            onPress={create}
            title={'Create'}
            style={styles.button}
            disabled={name.length === 0 || loading}
          />
        </ModalFooter>
      }>
      <View style={styles.inner}>
        <View style={styles.content}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>Create a folder</Text>
            <Text style={styles.subtitle}>
              You can invite members to join this folder later from the folder settings.
            </Text>
          </View>

          <View>
            <FormInput
              label="Name of folder"
              value={name}
              onChangeText={setName}
              maxLength={128}
              error={error instanceof ValidationError ? error.errors['name'] : error?.message}
            />
          </View>
        </View>
      </View>
    </ModalScreen>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    ...getModalStyleDefinition(theme),
  })
