import { useNavigation } from '@react-navigation/native'
import React, { useMemo } from 'react'
import { FlatList, FlatListProps, ListRenderItem, StyleProp, ViewStyle } from 'react-native'
import { mvs, ScaledSheet } from 'react-native-size-matters'
import { useSelector } from 'react-redux'
import { ClassNavigationItem } from '~components/ClassNavigationItem'
import { View } from '~components/Themed'
import useColumns from '~hooks/useColumns'
import usePermission from '~hooks/usePermission'
import { ApplicationState } from '~redux'
import ClassService from '~services/class'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import { ChatTemplate, ClassId, CreateClassContext, FirestoreClass } from '~types'
import { usePagination } from '~utils/react-firebase-pagination-hooks'
import IconCreateAFolderLargeAlt from './IconCreateAFolderLargeAlt'
import IconJoinClassLargeAlt from './IconJoinClassLargeAlt'
import { NavigationItem } from './NavigationItem'

type Props = Partial<FlatListProps<FirestoreClass>> & {
  template?: ChatTemplate
  onFolderSelected?: (folder: ClassId) => void
  backgroundContainerStyle?: (folder: ClassId) => StyleProp<ViewStyle>
  textContainerStyle?: (folder: ClassId) => StyleProp<ViewStyle>
  create?: CreateClassContext
  join?: boolean
  actions?: boolean // Show actions
}

export default function MyClassesList({
  template,
  onFolderSelected,
  backgroundContainerStyle,
  textContainerStyle,
  create,
  join = true,
  actions = true,
  ...other
}: Props): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const user_id = useSelector((state: ApplicationState) => state.auth.user?.claims.sub)
  const usingTemplate = template !== undefined
  const columns = useColumns()
  const navigation = useNavigation()
  const permission_create_class = usePermission('restrictions_max_classes')

  const showJoinClass = () =>
    navigation.navigate('Main', {
      screen: 'Classes',
      initial: false,
      params: {
        screen: 'JoinClass',
        initial: false,
      },
    })
  const showCreateClass = () => {
    if (create) {
      if (permission_create_class.status === 'allowed') {
        navigation.navigate('CreateClass', {
          context: create,
        })
      } else {
        navigation.navigate('PermissionDeniedModal', {
          message: permission_create_class.message,
        })
      }
    }
  }

  const [rawItems, { loaded, hasMore, loadingMore, loadMore }] = usePagination<FirestoreClass>(
    usingTemplate || create === 'chat'
      ? ClassService.listClassesTeacher(user_id)
      : ClassService.listClasses(user_id),
    {
      limit: 20,
    },
  )

  const items = useMemo(() => {
    return [
      ...(create
        ? [
            {
              id: 'create',
            },
          ]
        : []),
      ...(join
        ? [
            {
              id: 'join',
            },
          ]
        : []),
      ...rawItems,
    ]
  }, [create, join, rawItems])

  const renderItem: ListRenderItem<FirestoreClass> = ({ item }) => {
    const id = item.id

    if (id === 'create') {
      return (
        <NavigationItem
          title="Create a folder"
          background={
            <IconCreateAFolderLargeAlt
              style={styles.alignSelfCenter}
              width={mvs(80, 0.6)}
              height={mvs(80, 0.6)}
            />
          }
          backgroundContainerStyle={styles.altBackgroundContainerStyle}
          textContainerStyle={styles.altTextContainerStyle}
          onPress={showCreateClass}
        />
      )
    } else if (id === 'join') {
      return (
        <NavigationItem
          title="Join a folder"
          background={
            <IconJoinClassLargeAlt
              style={styles.alignSelfCenter}
              width={mvs(80, 0.6)}
              height={mvs(80, 0.6)}
            />
          }
          backgroundContainerStyle={styles.altBackgroundContainerStyle}
          textContainerStyle={styles.altTextContainerStyle}
          onPress={showJoinClass}
        />
      )
    }

    const { name, chats_count = 0 } = item.data()!
    const role = user_id && item.data()?.teachers.includes(user_id) ? 'teacher' : 'student'

    const classId: ClassId = {
      id,
      name,
    }

    const onPress = onFolderSelected
      ? () => {
          onFolderSelected(classId)
        }
      : undefined

    return (
      <ClassNavigationItem
        name={name}
        id={id}
        template={template}
        role={role}
        chats_count={chats_count}
        backgroundContainerStyle={
          backgroundContainerStyle ? backgroundContainerStyle(classId) : undefined
        }
        textContainerStyle={textContainerStyle ? textContainerStyle(classId) : undefined}
        onPress={onPress}
        actions={actions ? undefined : []}
      />
    )
  }

  return (
    <FlatList
      key={`myclasses-${columns}`}
      contentContainerStyle={styles.listContainer}
      data={items}
      numColumns={columns}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
      onEndReached={() => {
        if (loaded && hasMore && !loadingMore) {
          loadMore()
        }
      }}
      onEndReachedThreshold={0.5}
      {...other}
    />
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    listContainer: {
      paddingVertical: '20@mvs',
      paddingHorizontal: '10@ms',
    },
    navIconsContainer: {
      flexDirection: 'row',
      paddingEnd: 10,
    },
    navIconsButton: {
      marginLeft: 15,
      padding: 10,
    },
    itemSeparator: {
      height: '16@ms',
    },
    alignSelfCenter: {
      alignSelf: 'center',
    },
    altBackgroundContainerStyle: {
      backgroundColor: theme[300],
      borderColor: theme[300],
    },
    altTextContainerStyle: {
      backgroundColor: theme[100],
      borderColor: theme[100],
    },
  })
