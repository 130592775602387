import { useNavigation } from '@react-navigation/native'
import * as React from 'react'
import { useState } from 'react'
import { GestureResponderEvent } from 'react-native'
import { Dialog, Paragraph } from 'react-native-paper'
import { mvs, ScaledSheet } from 'react-native-size-matters'
import IconFolderLarge from '~components/IconFolderLarge'
import { Action, NavigationItem, NavigationItemProps } from '~components/NavigationItem'
import usePermission from '~hooks/usePermission'
import { Role } from '~redux/recent/types'
import { ChatTemplate } from '~types'
import { useAxios } from '~utils/fetch'
import { AlertConfirmation } from './AlertConfirmation'

export interface ClassNavigationItemProps extends Partial<NavigationItemProps> {
  id: string
  name: string
  template?: ChatTemplate
  role: Role
  chats_count?: number
  isDashboard?: boolean
}

export function ClassNavigationItem({
  name,
  actions,
  ...props
}: ClassNavigationItemProps): JSX.Element {
  const { id, template, role, chats_count = 0, isDashboard = false, pressableStyle } = props
  const navigation = useNavigation()
  const permission_create_chat = usePermission('restrictions_max_chats_per_class', chats_count)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const onPress = (event: GestureResponderEvent) => {
    if (props.onPress) {
      props.onPress(event)
    } else {
      // TODO: Consider confirmation?
      if (template) {
        if (permission_create_chat.status === 'allowed') {
          navigation.navigate('NewChatModal', {
            class: {
              id,
            },
            template: template,
          })
        } else {
          navigation.navigate('PermissionDeniedModal', {
            message: permission_create_chat.message,
          })
        }
      } else {
        navigation.navigate('Main', {
          screen: 'Classes',
          initial: false,
          params: {
            screen: 'Class',
            initial: false,
            params: {
              screen: 'ClassChats',
              initial: false,
              params: {
                id,
                name,
              },
            },
          },
        })
      }
    }
  }

  const [{ data, loading, error }, execute] = useAxios<never>(
    {
      url: `/api/v1/groups/${id}`,
      method: 'DELETE',
    },
    { manual: true },
  )

  const deleteClass = async () => {
    if (role !== 'teacher') {
      return
    }

    try {
      const { status, data } = await execute()
    } catch (error) {
      console.info(error)
    }
  }

  const actualActions: Action[] = actions ?? [
    {
      enabled: role === 'teacher',
      name: 'Edit folder settings',
      icon: ['far', 'cog'],
      action: () =>
        navigation.navigate('ClassSettingsModal', {
          id,
        }),
    },
    {
      enabled: role === 'teacher',
      name: 'Invite members',
      icon: ['far', 'envelope-open'],
      action: () =>
        navigation.navigate('ClassSettingsModal', {
          id,
          view: 'members',
          subview: 'create',
        }),
    },
    {
      enabled: !isDashboard && role === 'teacher',
      name: 'Delete Folder',
      icon: ['far', 'trash'],
      destructive: true,
      action: () => {
        setShowDeleteConfirmation(true)
      },
    },
  ]

  return (
    <>
      <NavigationItem
        {...props}
        title={name}
        onPress={onPress}
        actions={actualActions}
        background={
          <IconFolderLarge style={styles.background} width={mvs(75, 0.6)} height={mvs(75, 0.6)} />
        }
      />
      {showDeleteConfirmation && (
        <AlertConfirmation
          visible={showDeleteConfirmation}
          setVisible={setShowDeleteConfirmation}
          confirmAction={deleteClass}>
          <Dialog.Title>Delete Folder?</Dialog.Title>
          <Dialog.Content>
            <Paragraph>
              Deleting this folder will remove it from your account and it will no longer be visible
              to anyone else.
            </Paragraph>
          </Dialog.Content>
        </AlertConfirmation>
      )}
    </>
  )
}
const styles = ScaledSheet.create({
  background: {
    alignSelf: 'center',
  },
})
