import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useNavigation, useRoute } from '@react-navigation/native'
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { Platform, StyleProp, TouchableOpacity, ViewStyle } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { ms, ScaledSheet } from 'react-native-size-matters'
import { useDispatch, useSelector } from 'react-redux'
import {
  ChatLayoutOneByOne,
  ChatLayoutOneByThree,
  ChatLayoutOneByTwo,
  ChatLayoutTwoByThree,
  ChatLayoutTwoByTwo,
} from '~components/ChatLayoutImages'
import { FormButton } from '~components/FormButton'
import { FormInput } from '~components/FormInput'
import { FormInputButton } from '~components/FormInputButton'
import ModalScreen, { getModalStyleDefinition, ModalFooter } from '~components/ModalScreen'
import MyClassesList from '~components/MyClassesList'
import Progress from '~components/Progress'
import { Text, View } from '~components/Themed'
import { EVENT_CHAT_CREATED } from '~constants/Analytics'
import { analytics } from '~providers/firebase'
import { ApplicationState } from '~redux'
import { setChat } from '~redux/chat/actions'
import ChatService from '~services/chat'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import {
  ChatLayoutOption,
  ChatTemplate,
  ClassId,
  CreateChatType,
  RootStackScreenProps,
} from '~types'

type Props = RootStackScreenProps<'NewChatModal'>

type ViewType = 'details' | 'layout' | 'folder'

export interface State {
  view: ViewType
  loading: boolean
  name: string
  class: ClassId
  grade: string
  layout?: { rows: number; columns: number }
  template?: ChatTemplate
  showFolderSelection: boolean
  selected?: ClassId
}

export const initialState: State = {
  view: 'details',
  loading: false,
  name: '',
  class: {
    id: '',
    name: '',
  },
  grade: '',
  showFolderSelection: false,
}

const newChatSlice = createSlice({
  name: 'newchat',
  initialState,
  reducers: {
    setView: (state, action: PayloadAction<ViewType>) => {
      state.view = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
    setLayout: (state, action: PayloadAction<ChatLayoutOption>) => {
      state.layout = {
        rows: action.payload.rows,
        columns: action.payload.columns,
      }
    },
    setTemplate: (state, action: PayloadAction<ChatTemplate | undefined>) => {
      state.name = action.payload?.name ?? ''
      state.template = action.payload
    },
    setClassId: (state, action: PayloadAction<Partial<ClassId>>) => {
      if (action.payload?.id) {
        const id = {
          id: action.payload?.id ?? '',
          name: action.payload?.name ?? '',
        }
        state.class = id
        state.selected = id
      }
    },
    setSelectedClassId: (state, action: PayloadAction<Partial<ClassId>>) => {
      if (action.payload?.id) {
        state.selected = {
          id: action.payload?.id ?? '',
          name: action.payload?.name ?? '',
        }
      }
    },
    setShowFolderSelection: (state, action: PayloadAction<boolean>) => {
      state.showFolderSelection = action.payload
    },
  },
})
const actions = newChatSlice.actions
const store = React.createContext<[State, React.Dispatch<AnyAction>]>([initialState, () => {}])
const { Provider } = store
const useNewChatContext = (): [State, React.Dispatch<AnyAction>] => React.useContext(store)

const progress = (step: number) => <Progress labels={['Details', 'Layout']} step={step} />

export default function NewChatScreen({ navigation, route: { params } }: Props): JSX.Element {
  const [state, dispatch] = React.useReducer(newChatSlice.reducer, initialState)

  useEffect(() => {
    if (params?.class?.id) {
      dispatch(actions.setClassId(params.class))
    } else {
      dispatch(actions.setShowFolderSelection(true))
    }
    if (params?.template) {
      dispatch(actions.setTemplate(params.template))
    }
  }, [params])

  const value: [State, React.Dispatch<AnyAction>] = [state, dispatch]
  const globalDispatch = useDispatch()
  const { theme } = useTheme()
  const styles = getStyles(theme)

  const { user, profile } = useSelector((state: ApplicationState) => state.auth)
  const create = async () => {
    if (!user || !state.class.id || !(state.layout || state.template)) {
      return
    }

    dispatch(actions.setLoading(true))

    const props: CreateChatType = {
      name: state.name.length > 0 ? state.name : moment().format('MMMM Do YYYY, h:mm:ss a'),
      level: '',
      layout: state.template?.layout ?? state.layout ?? { rows: 1, columns: 1 },
      sections: state.template?.sections,
      author_id: user.claims.sub,
      author_name: profile.displayName,
      template_id: state.template?.id,
    }

    const chat = await ChatService.createChat(`classes/${state.class.id}`, props)
    await analytics().logEvent(EVENT_CHAT_CREATED)
    globalDispatch(setChat(chat))
    dispatch(actions.setLoading(false))
    navigation.navigate('Main', {
      screen: 'Classes',
      initial: false,
      params: {
        screen: 'ChatScreen',
        initial: false,
        params: {
          path: chat.path,
          role: 'teacher',
        },
      },
    })
  }

  const renderActiveTab = () => {
    switch (state.view) {
      case 'layout':
        return <Layout />
      case 'folder':
        return <Folder />
      case 'details':
      default:
        return <Details />
    }
  }

  const renderFooter = () => {
    const next = async () => {
      switch (state.view) {
        case 'details':
          if (state.template) {
            await create()
          } else {
            dispatch(actions.setView('layout'))
          }
          break
        case 'folder':
          if (state.selected) {
            dispatch(actions.setClassId(state.selected))
          }
          dispatch(actions.setView('details'))
          break
      }
    }

    const back = async () => {
      dispatch(actions.setView('details'))
    }

    return state.view === 'details' ? (
      <ModalFooter>
        <View></View>
        <FormButton
          onPress={next}
          title={'Continue'}
          style={styles.button}
          disabled={state.class.id.length === 0}
        />
      </ModalFooter>
    ) : state.view === 'folder' ? (
      <ModalFooter>
        <FormButton
          onPress={back}
          title={'Back'}
          style={styles.backButton}
          textStyle={styles.backButtonText}
          disabled={state.loading}
        />
        <FormButton
          onPress={next}
          title={'Confirm'}
          style={styles.button}
          disabled={state.selected?.id === undefined}
        />
      </ModalFooter>
    ) : (
      <ModalFooter>
        <FormButton
          onPress={back}
          title={'Back'}
          style={styles.backButton}
          textStyle={styles.backButtonText}
          disabled={state.loading}
        />
        <FormButton
          onPress={create}
          title={'Create Chat'}
          style={styles.button}
          disabled={state.layout === undefined || state.loading}
        />
      </ModalFooter>
    )
  }

  return (
    <ModalScreen
      closable={state.view !== 'folder'}
      ContainerComponent={state.view !== 'folder' ? KeyboardAwareScrollView : React.Fragment}
      Footer={renderFooter}>
      <Provider value={value}>{renderActiveTab()}</Provider>
    </ModalScreen>
  )
}

const Details = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useNewChatContext()

  const folder = () => {
    stateDispatch(actions.setView('folder'))
  }

  return (
    <View style={styles.inner}>
      <View style={styles.content}>
        {!state.template && progress(0)}

        <View style={styles.titleContainer}>
          <Text style={styles.title}>{state.template ? 'New chat form template' : 'New Chat'}</Text>
          <Text style={styles.subtitle}>
            {state.template ? 'Personalise this template' : 'A few details to start'}
          </Text>
        </View>

        <View>
          <FormInput
            label="Name of chat"
            value={state.name}
            onChangeText={(text) => stateDispatch(actions.setName(text))}
            maxLength={128}
          />

          {state.showFolderSelection && (
            <FormInputButton
              input={{
                label: 'Which folder would you like to add this chat to?',
                value: state.class.name,
                editable: false,
                placeholder: 'Select a folder',
                style: styles.folderInput,
              }}
              button={{
                title: 'Select a folder',
                onPress: folder,
              }}
            />
          )}
        </View>
      </View>
    </View>
  )
}

const Layout = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useNewChatContext()

  const layout = (layout: ChatLayoutOption) => stateDispatch(actions.setLayout(layout))

  const layouts: ChatLayoutOption[][] = [
    [
      { rows: 1, columns: 1, image: <ChatLayoutOneByOne /> },
      { rows: 1, columns: 2, image: <ChatLayoutOneByTwo /> },
      { rows: 1, columns: 3, image: <ChatLayoutOneByThree /> },
    ],
    [
      { rows: 2, columns: 2, image: <ChatLayoutTwoByTwo /> },
      { rows: 2, columns: 3, image: <ChatLayoutTwoByThree /> },
    ],
  ]

  return (
    <View style={styles.inner}>
      <View style={styles.content}>
        {progress(1)}

        <View style={styles.titleContainer}>
          <Text style={styles.title}>Blank layout</Text>
          <Text style={styles.subtitle}>Choose from one of the layouts below</Text>
        </View>

        {layouts.map((items, row) => (
          <View style={styles.layoutsContainer} key={`layout-row-${row}`}>
            {items.map((item, index) => (
              <TouchableOpacity onPress={() => layout(item)} key={`layout-${row}-${index}`}>
                <View
                  style={[
                    styles.listItem,
                    item.rows === state.layout?.rows &&
                      item.columns === state.layout.columns &&
                      styles.listItemSelected,
                  ]}>
                  {item.image}
                </View>
              </TouchableOpacity>
            ))}
          </View>
        ))}
      </View>
    </View>
  )
}

const Folder = () => {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const [state, stateDispatch] = useNewChatContext()
  const navigation = useNavigation()
  const route = useRoute<RootStackScreenProps<'NewChatModal'>['route']>()

  const selectFolder = (folder: ClassId) => {
    stateDispatch(actions.setSelectedClassId(folder))
  }

  // Return from creating a new folder
  useEffect(() => {
    if (route.params?.class?.id && state.class.id !== route.params?.class?.id) {
      stateDispatch(actions.setView('details'))
    }
  }, [route.params, state.class.id, stateDispatch])

  const backgroundContainerStyle = useCallback(
    (folder: ClassId): StyleProp<ViewStyle> => {
      return state.selected?.id === folder.id ? styles.backgroundContainerStyle : undefined
    },
    [state.selected?.id, styles.backgroundContainerStyle],
  )

  const textContainerStyle = useCallback(
    (folder: ClassId): StyleProp<ViewStyle> => {
      return state.selected?.id === folder.id ? styles.textContainerStyle : undefined
    },
    [state.selected?.id, styles.textContainerStyle],
  )

  return (
    <View style={styles.inner}>
      <View style={styles.header}>
        <Text style={styles.subtitle}>
          Please select a folder to this chat to OR create a new folder.
        </Text>
        <TouchableOpacity onPress={navigation.goBack}>
          <FontAwesomeIcon icon={['fal', 'times-circle']} size={ms(24)} />
        </TouchableOpacity>
      </View>
      <MyClassesList
        onFolderSelected={selectFolder}
        backgroundContainerStyle={backgroundContainerStyle}
        textContainerStyle={textContainerStyle}
        join={false}
        create="chat"
        actions={false}
        style={
          // No idea why any value for height works on web
          Platform.OS === 'web' ? { height: 0 } : undefined
        }
      />
    </View>
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    ...getModalStyleDefinition(theme),
    listItem: {
      marginVertical: '5@mvs',
      padding: '5@ms',
      backgroundColor: theme.white,
    },
    listItemSelected: {
      backgroundColor: theme.pink[200],
    },
    layoutsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    backgroundContainerStyle: {
      borderColor: theme.primary,
    },
    textContainerStyle: {
      borderColor: theme.primary,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
      paddingTop: '10@mvs0.8',
      paddingStart: '15@ms',
      paddingHorizontal: '10@mvs0.8',
    },
    folderInput: {
      color: theme.primary,
    },
  })
