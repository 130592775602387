import * as React from 'react'
import { mvs } from 'react-native-size-matters'
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg'

const IconJoinClassLarge = (props: SvgProps): JSX.Element => (
  <Svg width={mvs(90, 0.8)} height={mvs(90, 0.8)} viewBox="0 0 160 160" {...props}>
    <Circle data-name="Ellipse 179" cx={80} cy={80} r={80} fill="#9c99b0" />
    <Circle
      data-name="Ellipse 180"
      cx={78.552}
      cy={78.552}
      r={78.552}
      transform="translate(1.448 1.448)"
      fill="#444155"
    />
    <Circle
      data-name="Ellipse 181"
      cx={74.683}
      cy={74.683}
      r={74.683}
      transform="translate(5.317 5.317)"
      fill="#c7c5d2"
    />
    <Circle
      data-name="Ellipse 182"
      cx={74.683}
      cy={74.683}
      r={74.683}
      transform="translate(5.317 5.317)"
      fill="#c7c5d2"
    />
    <Path
      data-name="Path 1119"
      d="m41.548 19.739-6.115.893c-.663 0 1.028-1.042 1.569-1.434a73.356 73.356 0 0 1 82.691-2.178c.487.311 2 1.353 2 1.353l-5.615-.433C98.384 5.317 62.004 2.463 41.548 19.739Z"
      fill="#d8d7dd"
    />
    <Path
      data-name="Path 1120"
      d="M70.158 6.643a71.786 71.786 0 0 1 9.944-.649 70.745 70.745 0 0 1 9.092.6"
      fill="none"
      stroke="#efeff4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      data-name="Path 1121"
      d="M140.101 38.074c23.966 32.876 16.189 78.563-17.368 102.041s-80.185 15.859-104.148-17.02Z"
      fill="#b3b2bf"
    />
    <G data-name="Group 255" opacity={0.5}>
      <Path
        data-name="Path 1122"
        d="M110.714 66.693h-31.05l-10.336-10.35h-20.7a7.766 7.766 0 0 0-7.766 7.766v41.386a7.766 7.766 0 0 0 7.766 7.766h62.086a7.766 7.766 0 0 0 7.766-7.766V74.378a7.766 7.766 0 0 0-7.766-7.685Z"
        fill="#fff"
      />
    </G>
    <Path
      data-name="Path 1123"
      d="M110.714 66.693h-31.05l-10.336-10.35h-20.7a7.766 7.766 0 0 0-7.766 7.766v41.386a7.766 7.766 0 0 0 7.766 7.766h62.086a7.766 7.766 0 0 0 7.766-7.766V74.378a7.766 7.766 0 0 0-7.766-7.685Z"
      fill="none"
      stroke="#444155"
      strokeMiterlimit={10}
      strokeWidth={2}
    />
    <Circle
      data-name="Ellipse 183"
      cx={14.49}
      cy={14.49}
      r={14.49}
      transform="translate(66.616 76.979)"
      fill="#fff"
      opacity={0.5}
    />
    <Path
      data-name="Path 1124"
      d="m117.608 140.798 6.765-1.434c.676 0-1.664 1.651-2.205 2.043a73.383 73.383 0 0 1-82.718.974c-.487-.325-2.408-1.245-1.84-1.353l5.412.528c12.04 11.991 53.886 16.212 74.586-.758Z"
      fill="#8d8ba0"
    />
    <Circle
      data-name="Ellipse 184"
      cx={67.35}
      cy={67.35}
      r={67.35}
      transform="translate(12.65 12.65)"
      fill="none"
      stroke="#dcdbe3"
      strokeMiterlimit={10}
    />
    <Circle
      data-name="Ellipse 185"
      cx={62.074}
      cy={62.074}
      r={62.074}
      transform="translate(17.926 17.926)"
      fill="none"
      stroke="#dcdbe3"
      strokeMiterlimit={10}
      strokeWidth={0.92}
    />
    <Path
      data-name="Path 1125"
      d="m49.38 44.622-1.475 5.858a.257.257 0 0 1-.514 0l-1.475-5.858-5.858-1.461a.271.271 0 0 1 0-.528l5.872-1.461 1.461-5.872a.271.271 0 0 1 .514 0l1.475 5.872 5.858 1.461a.271.271 0 0 1 0 .528Z"
      fill="#fff"
    />
    <G data-name="Group 256" opacity={0.44}>
      <Path
        data-name="Path 1126"
        d="M42.882 38.453a.244.244 0 0 1 .338-.338l2.1 1.678-.149.609-.609.149Zm2.259 7.049.149.6-2.084 1.678a.25.25 0 0 1-.325-.379l1.678-2.1Zm5.06-5.073-.149-.6 2.1-1.678a.244.244 0 0 1 .338.338l-1.678 2.1Zm2.286 6.968a.244.244 0 0 1-.338.338l-2.1-1.678.149-.6.609-.162Z"
        fill="#fff"
      />
    </G>
    <Path
      data-name="Path 1127"
      d="m120.192 128.211-.934 3.748a.176.176 0 0 1-.338 0l-.934-3.748-3.748-.934a.176.176 0 0 1 0-.338l3.748-.934.934-3.748a.176.176 0 0 1 .338 0l.934 3.748 3.815.852a.176.176 0 0 1 0 .338Z"
      fill="#fff"
    />
    <G data-name="Group 257" opacity={0.44}>
      <Path
        data-name="Path 1128"
        d="M115.991 124.131a.153.153 0 0 1 .216-.216l1.353 1.082-.095.379-.379.095Zm1.461 4.478.108.392-1.353 1.069c-.149.108-.338-.081-.216-.216l1.069-1.353Zm3.239-3.125-.095-.392 1.353-1.069c.149-.108.338.081.216.216l-1.074 1.353Zm1.461 4.478c.122.135 0 .325-.216.216l-1.353-1.069.095-.392.392-.095Z"
        fill="#fff"
      />
    </G>
    <Path
      data-name="Path 1129"
      d="m112.068 122.329-.541 2.151a.095.095 0 0 1-.189 0l-.541-2.151-2.151-.541a.095.095 0 1 1 0-.189l2.151-.541.541-2.151a.095.095 0 0 1 .189 0l.541 2.151 2.151.541a.095.095 0 1 1 0 .189Z"
      fill="#fff"
    />
    <G data-name="Group 258" opacity={0.44}>
      <Path
        data-name="Path 1130"
        d="M109.651 120.032c-.068-.081 0-.189.135-.122l.758.622v.213h-.216Zm.839 2.638v.216l-.758.622a.1.1 0 1 1-.135-.135l.622-.771Zm1.867-1.867v-.216l.663-.622a.086.086 0 1 1 .122.122l-.622.771Zm.839 2.571c.068.095 0 .2-.122.135l-.771-.622v-.216h.217Z"
        fill="#fff"
      />
    </G>
    <Path
      data-name="Path 1131"
      d="M53.403 90.344h27.911l-6.128-6.115a1.15 1.15 0 0 1 1.624-1.61l8.118 8.118a1.137 1.137 0 0 1 0 1.61l-8.118 8.118a1.15 1.15 0 0 1-1.624 0 1.136 1.136 0 0 1 0-1.61l6.115-6.129H53.417a1.136 1.136 0 0 1-.812-1.944 1.123 1.123 0 0 1 .798-.438Z"
      fill="#444155"
    />
    <Path
      data-name="Path 1132"
      d="M92.42 80.163a15.977 15.977 0 1 1-22.581 22.609 15.585 15.585 0 0 1-2.935-4.06 1.15 1.15 0 0 1 .5-1.529 1.191 1.191 0 0 1 1.529.487 13.692 13.692 0 1 0 2.5-15.91 13.069 13.069 0 0 0-2.5 3.45 1.15 1.15 0 0 1-2.043-1.042 15.992 15.992 0 0 1 25.53-4.059Z"
      fill="#444155"
    />
  </Svg>
)

export default IconJoinClassLarge
