import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useHeaderHeight } from '@react-navigation/elements'
import { useNavigation } from '@react-navigation/native'
import { AnyAction, createAction, createReducer } from '@reduxjs/toolkit'
import throttle from 'lodash/throttle'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  ColorSchemeName,
  Dimensions,
  FlatList,
  ListRenderItemInfo,
  StatusBar,
  Text,
} from 'react-native'
import { ms, ScaledSheet } from 'react-native-size-matters'
import { useSWRInfinite } from 'swr'
import { CommunityChatResource, CommunityChatResourceCollection } from '~api/types'
import { CacheableImage } from '~components/CacheableImage'
import { FormButton } from '~components/FormButton'
import { FormInput } from '~components/FormInput'
import Loading from '~components/Loading'
import { NavigationItem } from '~components/NavigationItem'
import RenderError from '~components/RenderError'
import { View } from '~components/Themed'
import useColumns from '~hooks/useColumns'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import opacity from '~utils/opacity'

type Props = {
  placeholder?: JSX.Element | null
  category?: string
}

export interface State {
  filterTypeChatta: boolean
  filterTypeCommunity: boolean
}

export const initialState: State = {
  filterTypeChatta: true,
  filterTypeCommunity: true,
}

export const toggleFilterTypeChatta = createAction('toggleFilterTypeChatta')
export const toggleFilterTypeCommunity = createAction('toggleFilterTypeCommunity')

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(toggleFilterTypeChatta, (state) => {
      state.filterTypeChatta = !state.filterTypeChatta
    })
    .addCase(toggleFilterTypeCommunity, (state) => {
      state.filterTypeCommunity = !state.filterTypeCommunity
    }),
)
const store = React.createContext<[State, React.Dispatch<AnyAction>]>([initialState, () => {}])
const { Provider } = store
export const useSearchContext = (): [State, React.Dispatch<AnyAction>] => React.useContext(store)

export function SearchProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value: [State, React.Dispatch<AnyAction>] = [state, dispatch]

  return <Provider value={value}>{children}</Provider>
}

export default function ChattaCommunitySearch({ placeholder, category }: Props): JSX.Element {
  const navigation = useNavigation()
  const { mode, theme } = useTheme()
  const headerHeight = useHeaderHeight()
  const styles = getStyles(mode, theme, headerHeight)
  const [state, stateDispatch] = useSearchContext()
  const [query, setQuery] = useState('')
  const [queryInternal, setQueryInternal] = useState('') // throttled
  const columns = useColumns()

  const throttled = useRef(throttle(setQueryInternal, 1000))
  useEffect(() => throttled.current(query), [query])

  const getFetchUrl = useCallback(() => {
    const args = new URLSearchParams()
    args.append('query', queryInternal)
    if (category !== undefined) {
      args.append('category_id', category)
    }

    // facetFilters
    if (state.filterTypeChatta) {
      args.append('facetFilters[type][]', 'chatta')
    }
    if (state.filterTypeCommunity) {
      args.append('facetFilters[type][]', 'community')
    }

    return `/api/v1/community/search?${args.toString()}`
  }, [queryInternal, category, state.filterTypeChatta, state.filterTypeCommunity])

  const { data, size, setSize, isValidating, error } =
    useSWRInfinite<CommunityChatResourceCollection>((pageIndex, previousPageData) => {
      if (queryInternal === undefined || queryInternal.length === 0) {
        return null
      }

      // First
      if (pageIndex === 0) {
        return getFetchUrl()
      }

      // End
      if (previousPageData && previousPageData.links.next !== null) {
        return previousPageData.links.next
      }

      return null
    })

  const items = (Array.isArray(data) ? data : [])
    .flatMap((o) => o.data)
    .filter((item, index, array) => {
      return array.findIndex((o) => o.id === item.id) === index
    })

  const more =
    size > 0 &&
    data !== undefined &&
    data[size - 1] !== undefined &&
    data[size - 1]?.links.next !== null

  const renderItem = ({ item }: ListRenderItemInfo<CommunityChatResource>) => {
    const src = item.thumbnail

    return (
      <NavigationItem
        title={item.name}
        onPress={() =>
          navigation.navigate('Main', {
            screen: 'Community',
            params: {
              screen: 'ChattaCommunityTemplateScreen',
              params: {
                id: item.id,
              },
            },
          })
        }
        background={
          src ? (
            <View style={styles.navigationItemContainer}>
              <CacheableImage
                source={{ uri: src }}
                style={[
                  styles.navigationItemImage,
                  item.type === 'chatta' ? undefined : styles.navigationItemImageWithoutHeader,
                ]}
                resizeMode="cover"
                placeholder={
                  <FontAwesomeIcon
                    style={styles.navigationItemPlaceholder}
                    color={theme[400]}
                    size={ms(53)}
                    icon={['fal', 'file-image']}
                  />
                }
              />
              <View
                style={[
                  styles.navigationItemOverlay,
                  item.type === 'chatta' ? undefined : styles.navigationItemImageWithoutHeader,
                ]}
              />
            </View>
          ) : undefined
        }
        icon={['fal', 'file-image']}
        header={
          item.type === 'chatta' ? (
            <View style={styles.createdByChatta}>
              <FontAwesomeIcon
                color={theme.white}
                secondaryOpacity={0.7}
                size={ms(16, 0.3)}
                icon={['fad', 'stars']}
              />
              <Text style={styles.createdByChattaText}>Created by Chatta</Text>
            </View>
          ) : undefined
        }
      />
    )
  }

  const renderHeader = (
    <View style={styles.searchContainer}>
      <FormInput
        key={`search`}
        placeholder={'Search templates'}
        value={query}
        onChangeText={setQuery}
        autoCorrect={false}
        clearButtonMode="always"
        style={styles.searchInput}
      />
      <View style={styles.searchFiltersContainer}>
        <FormButton
          title="Chatta Official"
          icon={() => (
            <FontAwesomeIcon
              color={
                state.filterTypeChatta ? theme.white : mode === 'dark' ? theme.text : theme[700]
              }
              secondaryOpacity={0.7}
              size={ms(12)}
              icon={['fad', 'stars']}
              style={{ marginRight: ms(2) }}
            />
          )}
          style={[
            styles.searchFiltersButton,
            state.filterTypeChatta ? null : styles.searchFiltersButtonInactive,
          ]}
          textStyle={[
            styles.searchFiltersButtonText,
            state.filterTypeChatta ? null : styles.searchFiltersButtonInactiveText,
          ]}
          onPress={() => stateDispatch(toggleFilterTypeChatta())}
        />
        <FormButton
          title="Chatta Community"
          icon={() => (
            <FontAwesomeIcon
              color={
                state.filterTypeCommunity ? theme.white : mode === 'dark' ? theme.text : theme[700]
              }
              size={ms(12)}
              icon={['fas', 'hands-heart']}
              style={{ marginRight: ms(2) }}
            />
          )}
          style={[
            styles.searchFiltersButton,
            state.filterTypeCommunity ? null : styles.searchFiltersButtonInactive,
          ]}
          textStyle={[
            styles.searchFiltersButtonText,
            state.filterTypeCommunity ? null : styles.searchFiltersButtonInactiveText,
          ]}
          onPress={() => stateDispatch(toggleFilterTypeCommunity())}
        />
      </View>
    </View>
  )

  const renderEmptyList =
    queryInternal.length === 0 ? (
      placeholder
    ) : isValidating ? (
      <View style={styles.emptyContainer}>
        <Loading />
      </View>
    ) : error ? (
      <View style={styles.emptyContainer}>
        <RenderError title="Network Error" description={error.message} />
      </View>
    ) : (
      <View style={styles.emptyContainer}>
        <RenderError title="Oops, nothing here!" description="No templates have been found." />
      </View>
    )

  return (
    <FlatList<CommunityChatResource>
      key={`chattacommunitysearch-${columns}`}
      contentContainerStyle={styles.listContainer}
      ListHeaderComponent={renderHeader}
      data={items}
      numColumns={columns}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
      onEndReached={() => more && setSize(size + 1)}
      onEndReachedThreshold={0.25}
      ListEmptyComponent={renderEmptyList}
    />
  )
}

const getStyles = (mode: ColorSchemeName, theme: Theme, headerHeight: number) =>
  ScaledSheet.create({
    listContainer: {
      paddingVertical: '20@mvs',
      paddingHorizontal: '10@ms',
      minHeight: Dimensions.get('window').height - (StatusBar.currentHeight ?? 0) - headerHeight,
    },
    emptyContainer: {
      paddingHorizontal: '10@ms',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    headerPlaceholderContainer: {
      paddingHorizontal: '10@ms',
    },
    headerActions: {
      flexGrow: 1,
      alignItems: Dimensions.get('window').width < 640 ? 'center' : 'flex-end',
    },
    headerActionTextStyle: {
      fontWeight: '600',
    },
    subtitle: {
      fontSize: '12@ms0.4',
      fontWeight: '300',
    },
    navigationItemContainer: {
      flex: 1,
      justifyContent: 'center',
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
    },
    navigationItemImage: {
      flex: 1,
      resizeMode: 'cover',
    },
    navigationItemImageWithoutHeader: {
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
    },
    navigationItemPlaceholder: {
      alignSelf: 'center',
    },
    navigationItemOverlay: {
      flex: 1,
      backgroundColor: opacity(theme[500], 0.3),
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    createdByChatta: {
      flexDirection: 'row',
      backgroundColor: theme.primary,
      justifyContent: 'center',
      alignItems: 'center',
      borderTopLeftRadius: '5@ms',
      borderTopRightRadius: '5@ms',
      paddingHorizontal: '4@ms',
      paddingVertical: '3@mvs',
    },
    createdByChattaText: {
      fontSize: '9@ms',
      fontWeight: '700',
      color: theme.white,
      marginLeft: '4@ms',
    },
    searchInput: {
      flexGrow: 1,
    },
    searchContainer: {
      paddingHorizontal: '10@ms',
      flexDirection: Dimensions.get('window').width < 640 ? 'column' : 'row',
    },
    searchFiltersContainer: {
      flexDirection: 'row',
      paddingVertical: '4@mvs0.4',
      alignItems: 'center',
      justifyContent: Dimensions.get('window').width < 640 ? 'center' : 'flex-start',
      marginLeft: Dimensions.get('window').width < 640 ? 0 : '20@ms',
    },
    searchFiltersButton: {
      paddingVertical: '4@mvs0.4',
      paddingHorizontal: '4@mvs0.4',
      marginLeft: '10@ms',
    },
    searchFiltersButtonInactive: {
      backgroundColor: theme[300],
      borderColor: theme[300],
    },
    searchFiltersButtonText: {
      fontSize: '10@ms',
      fontWeight: '400',
    },
    searchFiltersButtonInactiveText: {
      color: mode === 'dark' ? theme.text : theme[700],
    },
    itemSeparator: {
      height: '16@ms',
    },
  })
