import { AnyAction, createAction, createReducer } from '@reduxjs/toolkit'
import React from 'react'

export interface State {
  lockedSection: number
}

export const initialState: State = {
  lockedSection: -1,
}

export const setLockedSection = createAction<number>('setLockedSection')
export const unlockSection = createAction<number>('unlockSection')

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setLockedSection, (state, action) => {
      state.lockedSection = action.payload
    })
    .addCase(unlockSection, (state, action) => {
      if (state.lockedSection === action.payload) {
        state.lockedSection = -1
      }
    }),
)

const ChatContext = React.createContext<[State, React.Dispatch<AnyAction>]>([
  initialState,
  () => {},
])

export const useChatContext = (): [State, React.Dispatch<AnyAction>] =>
  React.useContext(ChatContext)

export const ChatProvider = (props: { children: any }): JSX.Element => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value: [State, React.Dispatch<AnyAction>] = [state, dispatch]

  return <ChatContext.Provider value={value}>{props.children}</ChatContext.Provider>
}
