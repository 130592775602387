import React from 'react'
import { Dimensions, FlatList, Linking, ListRenderItem, TouchableOpacity } from 'react-native'
import { ScaledSheet } from 'react-native-size-matters'
import { useSelector } from 'react-redux'
import { ChatNavigationItem } from '~components/ChatNavigationItem'
import { ClassNavigationItem } from '~components/ClassNavigationItem'
import IconCreateAChat from '~components/IconCreateAChat'
import IconCreateAFolderLarge from '~components/IconCreateAFolderLarge'
import IconJoinClassLarge from '~components/IconJoinClassLarge'
import IconUserGuideLarge from '~components/IconUserGuideLarge'
import { Text, View } from '~components/Themed'
import defaultConfig from '~config'
import useColumns from '~hooks/useColumns'
import usePermission from '~hooks/usePermission'
import { remoteConfig } from '~providers/firebase'
import { ApplicationState } from '~redux'
import { RecentChat, RecentClass } from '~redux/recent/types'
import { Theme } from '~theme'
import { useTheme } from '~theme/ThemeManager'
import type { MainStackScreenProps } from '~types'

type Props = MainStackScreenProps<'Dashboard'>

type Action = {
  id: string
  text: string
  icon: (props: any) => JSX.Element
  onPress: () => void
}

export default function DashboardScreen({ navigation }: Props): JSX.Element {
  const { theme } = useTheme()
  const styles = getStyles(theme)
  const { profile } = useSelector((state: ApplicationState) => state.auth)
  const { classes, chats } = useSelector((state: ApplicationState) => state.recent)
  const columns = useColumns()
  const permission_create_class = usePermission('restrictions_max_classes')
  const permission_create_chat = usePermission('restrictions_max_chats_per_class', 0) // TODO: needs count somehow

  const renderHeader = () => (
    <View style={styles.header}>
      <Text style={styles.title}>Hello {profile?.displayName}!</Text>
      <Text style={styles.subtitle}>What would you like to do today?</Text>
    </View>
  )

  const renderFooter = () => (
    <View style={styles.footerContainer}>
      <View style={{ flex: 1 / 2 }}>
        {classes.length > 0 && (
          <>
            <Text style={styles.sectionTitle}>Recent folders</Text>
            <View style={styles.footerItemContainer}>
              {renderItemClass({ item: classes[0], index: 0 })}
              {renderItemClass({ item: classes[1], index: 1 })}
            </View>
          </>
        )}
      </View>
      <View style={{ flex: 1 / 2 }}>
        {chats.length > 0 && (
          <>
            <Text style={styles.sectionTitle}>Recent chats</Text>
            <View style={styles.footerItemContainer}>
              {renderItemChat({ item: chats[0], index: 0 })}
              {renderItemChat({ item: chats[1], index: 1 })}
            </View>
          </>
        )}
      </View>
    </View>
  )

  const renderItemChat = ({ item, index }: { item?: RecentChat; index: number }) => {
    const cols = Math.ceil(columns / 2)
    return (
      <View key={`recent-chat-${index}`} style={{ flex: 1 / cols }}>
        {item && <ChatNavigationItem chat={item} role={item.role} isDashboard={true} />}
      </View>
    )
  }

  const renderItemClass = ({ item, index }: { item?: RecentClass; index: number }) => {
    const cols = Math.ceil(columns / 2)
    return (
      <View key={`recent-class-${index}`} style={{ flex: 1 / cols }}>
        {item && (
          <ClassNavigationItem name={item.name} id={item.id} role={item.role} isDashboard={true} />
        )}
      </View>
    )
  }

  const renderItem: ListRenderItem<Action> = ({ item }) => {
    return (
      <View style={styles.actionContainer}>
        <TouchableOpacity style={styles.actionTouchableContainer} onPress={item.onPress}>
          {item.icon(styles.actionIcon)}
          <Text style={styles.actionButtonText}>{item.text}</Text>
        </TouchableOpacity>
      </View>
    )
  }

  const actions: Action[] = [
    {
      id: 'create-chat',
      text: 'Create a Chat',
      icon: (props) => <IconCreateAChat {...props} />,
      onPress: () => {
        if (permission_create_chat.status === 'allowed') {
          navigation.navigate('NewChatModal')
        } else {
          navigation.navigate('PermissionDeniedModal', {
            message: permission_create_chat.message,
          })
        }
      },
    },
    {
      id: 'create-folder',
      text: 'Create a Folder',
      icon: (props) => <IconCreateAFolderLarge {...props} />,
      onPress: () => {
        if (permission_create_class.status === 'allowed') {
          navigation.navigate('CreateClass')
        } else {
          navigation.navigate('PermissionDeniedModal', {
            message: permission_create_class.message,
          })
        }
      },
    },
    {
      id: 'join-folder',
      text: 'Join a folder',
      icon: (props) => <IconJoinClassLarge {...props} />,
      onPress: () => {
        navigation.navigate('Main', {
          screen: 'Classes',
          initial: false,
          params: {
            screen: 'JoinClass',
            initial: false,
          },
        })
      },
    },
    {
      id: 'user-guide',
      text: 'User Guide',
      icon: (props) => <IconUserGuideLarge {...props} />,
      onPress: () => {
        const url = remoteConfig().getString('user_guide_url') || defaultConfig.user_guide_url
        if (url) {
          Linking.openURL(url)
        }
      },
    },
  ]

  return (
    <FlatList<Action>
      key={`dashboard-${columns}`}
      contentContainerStyle={styles.listContainer}
      numColumns={columns}
      data={actions}
      ListHeaderComponent={renderHeader}
      ListFooterComponent={renderFooter}
      renderItem={renderItem}
    />
  )
}

const getStyles = (theme: Theme) =>
  ScaledSheet.create({
    container: {
      flex: 1,
      paddingVertical: '20@mvs',
      paddingHorizontal: '20@ms',
    },
    title: {
      fontSize: '18@ms',
      fontWeight: '700',
    },
    subtitle: {
      fontSize: '12@ms',
      fontWeight: '300',
    },

    // actions
    actionContainer: {
      flex: 1,
      alignItems: Dimensions.get('window').width < 640 ? 'flex-start' : 'center',
    },
    actionTouchableContainer: {
      flexGrow: 1,
      flexDirection: Dimensions.get('window').width < 640 ? 'row' : 'column',
      justifyContent: Dimensions.get('window').width < 640 ? 'flex-start' : 'center',
      alignItems: 'center',
      alignContent: 'stretch',
      padding: '5@s',
      marginBottom: '5@ms',
    },
    actionButtonText: {
      fontSize: '12@ms0.3',
      fontWeight: '500',
      marginStart: Dimensions.get('window').width < 640 ? '10@mvs' : 0,
      marginTop: Dimensions.get('window').width < 640 ? 0 : '10@mvs',
    },
    actionIcon: {
      width: Dimensions.get('window').width < 640 ? '50@ms0.8' : '100@ms0.6',
      height: Dimensions.get('window').width < 640 ? '50@ms0.8' : '100@ms0.6',
    },
    // break

    listContainer: {
      paddingVertical: '20@mvs',
      paddingHorizontal: '10@ms',
    },
    header: {
      paddingHorizontal: '10@ms',
      paddingBottom: '10@ms',
    },
    sectionTitle: {
      fontSize: '10@ms',
      fontWeight: '500',
      color: theme[500],
      paddingTop: '20@mvs',
      paddingBottom: '5@mvs',
      paddingHorizontal: '10@ms',
    },
    itemSeparator: {
      height: '16@ms',
    },

    // footer
    footerContainer: {
      flex: 1,
      flexDirection: Dimensions.get('window').width < 640 ? 'column' : 'row',
    },
    footerItemContainer: {
      flex: 1,
      flexDirection: 'row',
    },
  })
