import * as React from 'react'
import { mvs } from 'react-native-size-matters'
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg'

const IconCreateAFolderLarge = (props: SvgProps): JSX.Element => (
  <Svg width={mvs(90, 0.8)} height={mvs(90, 0.8)} viewBox="0 0 160 160" {...props}>
    <Circle data-name="Ellipse 126" cx={80} cy={80} r={80} fill="#9c99b0" />
    <Circle
      data-name="Ellipse 127"
      cx={78.519}
      cy={78.519}
      transform="translate(1.481 1.481)"
      fill="#444155"
      r={78.519}
    />
    <Circle
      data-name="Ellipse 128"
      cx={74.56}
      cy={74.56}
      r={74.56}
      transform="translate(5.44 5.44)"
      fill="#c7c5d2"
    />
    <Circle
      data-name="Ellipse 129"
      cx={74.56}
      cy={74.56}
      r={74.56}
      transform="translate(5.44 5.44)"
      fill="#c7c5d2"
    />
    <Path
      data-name="Path 1005"
      d="m40.731 20.197-6.257.914c-.678 0 1.052-1.066 1.606-1.467a75.058 75.058 0 0 1 84.61-2.229c.5.318 2.049 1.384 2.049 1.384l-5.745-.443C98.887 5.44 61.662 2.519 40.731 20.197Z"
      fill="#d8d7dd"
    />
    <Path
      data-name="Path 1006"
      d="M69.938 6.797a73.452 73.452 0 0 1 10.17-.665 72.387 72.387 0 0 1 9.3.609"
      fill="none"
      stroke="#efeff4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      data-name="Path 1007"
      d="M140.021 35.244a75.17 75.17 0 0 1-17.295 104.41 74.026 74.026 0 0 1-103.709-17.416Z"
      fill="#b3b2bf"
    />
    <Path
      data-name="Path 1008"
      d="m118.43 140.356 6.922-1.467c.692 0-1.7 1.689-2.256 2.09a75.086 75.086 0 0 1-84.638 1c-.5-.332-2.464-1.274-1.883-1.384l5.537.54c12.32 12.262 55.138 16.584 76.318-.779Z"
      fill="#8d8ba0"
    />
    <Circle
      data-name="Ellipse 130"
      cx={67.057}
      cy={67.057}
      r={67.057}
      transform="translate(12.943 12.943)"
      fill="none"
      stroke="#dcdbe3"
      strokeMiterlimit={10}
    />
    <Circle
      data-name="Ellipse 131"
      cx={61.658}
      cy={61.658}
      r={61.658}
      transform="translate(18.342 18.342)"
      fill="none"
      stroke="#dcdbe3"
      strokeMiterlimit={10}
      strokeWidth={0.92}
    />
    <Path
      data-name="Path 1009"
      d="m49.501 44.755-1.509 5.994a.263.263 0 1 1-.526 0l-1.509-5.994-5.994-1.5a.277.277 0 0 1 0-.54l6.01-1.49 1.5-6.008a.277.277 0 0 1 .526 0l1.509 6.008 5.994 1.5a.277.277 0 0 1 0 .54Z"
      fill="#fff"
    />
    <G data-name="Group 228" opacity={0.44}>
      <Path
        data-name="Path 1010"
        d="M42.82 38.407a.249.249 0 0 1 .346-.346l2.146 1.717-.152.623-.623.152Zm2.312 7.212.152.609-2.132 1.717a.255.255 0 0 1-.332-.388l1.716-2.15Zm5.177-5.191-.152-.609 2.146-1.717a.249.249 0 0 1 .346.346l-1.717 2.146Zm2.34 7.129a.249.249 0 0 1-.346.346l-2.146-1.717.152-.609.623-.166Z"
        fill="#fff"
      />
    </G>
    <Path
      data-name="Path 1011"
      d="m120.156 128.164-.955 3.835a.18.18 0 0 1-.346 0l-.955-3.835-3.835-.955a.18.18 0 0 1 0-.346l3.835-.955.955-3.835a.18.18 0 0 1 .346 0l.955 3.835 3.9.872a.18.18 0 0 1 0 .346Z"
      fill="#fff"
    />
    <G data-name="Group 229" opacity={0.44}>
      <Path
        data-name="Path 1012"
        d="M115.882 124.018a.157.157 0 0 1 .221-.221l1.384 1.107-.1.388-.388.1Zm1.5 4.582.111.4-1.384 1.094c-.152.111-.346-.083-.221-.221l1.094-1.384Zm3.309-3.2-.1-.4 1.384-1.094c.152-.111.346.083.221.222l-1.094 1.384Zm1.5 4.582c.125.138 0 .332-.221.221l-1.388-1.09.1-.4.4-.1Z"
        fill="#fff"
      />
    </G>
    <Path
      data-name="Path 1013"
      d="m112.054 122.307-.554 2.2a.1.1 0 0 1-.194 0l-.554-2.2-2.2-.554a.1.1 0 0 1 0-.194l2.2-.554.554-2.2a.1.1 0 1 1 .194 0l.554 2.2 2.2.554a.1.1 0 0 1 0 .194Z"
      fill="#fff"
    />
    <G data-name="Group 230" opacity={0.44}>
      <Path
        data-name="Path 1014"
        d="M109.593 119.971c-.069-.083 0-.194.138-.125l.775.637v.221h-.222Zm.858 2.7v.221l-.775.637a.1.1 0 1 1-.138-.138l.637-.789Zm1.91-1.91v-.221l.678-.637a.088.088 0 1 1 .125.125l-.637.789Zm.858 2.63c.069.1 0 .208-.125.138l-.789-.637v-.222h.221Z"
        fill="#fff"
      />
    </G>
    <G data-name="Group 231" opacity={0.5}>
      <Path
        data-name="Path 1015"
        d="M111.442 66.211h-31.77l-10.577-10.59h-21.18a7.946 7.946 0 0 0-7.946 7.946v42.347a7.946 7.946 0 0 0 7.946 7.946h63.527a7.946 7.946 0 0 0 7.946-7.946v-31.84a7.946 7.946 0 0 0-7.946-7.863Z"
        fill="#fff"
      />
    </G>
    <Path
      data-name="Path 1016"
      d="M111.821 66.211H79.675l-10.7-10.59H47.541a7.994 7.994 0 0 0-8.04 7.946v42.347a7.994 7.994 0 0 0 8.04 7.946h64.28a7.994 7.994 0 0 0 8.04-7.946v-31.84a8 8 0 0 0-8.04-7.863Z"
      fill="none"
      stroke="#444155"
      strokeMiterlimit={10}
      strokeWidth={2}
    />
    <Circle
      data-name="Ellipse 132"
      cx={14.826}
      cy={14.826}
      r={14.826}
      transform="translate(66.865 76.584)"
      fill="#fff"
      opacity={0.5}
    />
    <Path
      data-name="Path 1017"
      d="M81.099 107.743a16.335 16.335 0 1 1 16.349-16.335 16.335 16.335 0 0 1-16.349 16.335Zm0-30.594a14.231 14.231 0 1 0 10.069 4.178 14.231 14.231 0 0 0-10.069-4.178Zm8.445 14.624a.8.8 0 0 1-.8.8H82.29v6.451a.8.8 0 0 1-.789.789h-.789a.789.789 0 0 1-.789-.789v-6.425h-6.465a.8.8 0 0 1-.789-.8v-.8a.8.8 0 0 1 .789-.789h6.534v-6.443a.775.775 0 0 1 .789-.789h.787a.789.789 0 0 1 .789.789v6.451h6.451a.8.8 0 0 1 .8.789Z"
      fill="#444155"
    />
  </Svg>
)

export default IconCreateAFolderLarge
