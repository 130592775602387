import * as React from 'react'
import { mvs } from 'react-native-size-matters'
import Svg, { Path, SvgProps } from 'react-native-svg'

const IconCreateAFolderLargeAlt = (props: SvgProps): JSX.Element => (
  <Svg width={mvs(90, 0.8)} height={mvs(90, 0.8)} viewBox="0 0 96.902 110.906" {...props}>
    <Path
      data-name="Path 1148"
      d="m11.592 11.073-1.7 6.816a.314.314 0 0 1-.609 0l-1.7-6.816L.761 9.369a.313.313 0 0 1 0-.609l6.818-1.7L9.283.239a.313.313 0 0 1 .609 0l1.707 6.819 6.816 1.7a.314.314 0 0 1 0 .609Z"
      fill="#fff"
    />
    <Path
      data-name="Path 1149"
      d="M3.986 3.867a.283.283 0 0 1 .4-.4l2.435 1.954-.178.7-.7.178Zm2.66 8.142.178.7-2.435 1.955a.283.283 0 0 1-.4-.4l1.944-2.437Zm5.888-5.887-.179-.7 2.437-1.952a.283.283 0 0 1 .4.4l-1.955 2.433Zm2.654 8.144a.283.283 0 0 1-.4.4l-2.437-1.951.179-.7.7-.176Z"
      fill="#dfdee5"
    />
    <Path
      data-name="Path 1150"
      d="m92.395 106.4-1.087 4.353a.2.2 0 0 1-.389 0l-1.088-4.353-4.353-1.087a.2.2 0 0 1 0-.39l4.354-1.087 1.089-4.35a.2.2 0 0 1 .389 0l1.09 4.354 4.353 1.087a.2.2 0 0 1 0 .39Z"
      fill="#fff"
    />
    <Path
      data-name="Path 1151"
      d="M87.537 101.8a.18.18 0 0 1 .253-.253l1.557 1.246-.113.45-.45.113Zm1.7 5.2.113.451-1.556 1.246a.181.181 0 0 1-.253-.255l1.243-1.556Zm3.76-3.76-.112-.45 1.554-1.243a.18.18 0 0 1 .253.253l-1.246 1.556Zm1.7 5.2a.18.18 0 0 1-.253.253l-1.56-1.244.112-.45.45-.113Z"
      fill="#dfdee5"
    />
    <Path
      data-name="Path 1152"
      d="m83.127 99.743-.626 2.5a.115.115 0 0 1-.223 0l-.626-2.5-2.5-.625a.116.116 0 0 1 0-.225l2.506-.626.626-2.506a.115.115 0 0 1 .223 0l.626 2.506 2.506.626a.116.116 0 0 1 0 .225Z"
      fill="#fff"
    />
    <Path
      data-name="Path 1153"
      d="M80.332 97.096a.103.103 0 0 1 .145-.146l.9.717-.066.258-.258.066Zm.975 2.992.065.258-.894.717a.103.103 0 0 1-.146-.146l.714-.9Zm2.163-2.163-.064-.26.894-.716a.104.104 0 0 1 .148.146l-.717.9Zm.977 2.992a.104.104 0 0 1-.148.146l-.894-.717.064-.26.26-.064Z"
      fill="#dfdee5"
    />
    <Path
      data-name="Path 1154"
      d="M82.214 35.765H46.119L34.087 23.734H10.023A9.025 9.025 0 0 0 1 32.757v48.127a9.025 9.025 0 0 0 9.023 9.023h72.191a9.025 9.025 0 0 0 9.023-9.023v-36.1a9.025 9.025 0 0 0-9.023-9.023"
      fill="#e2e2e8"
    />
    <Path
      data-name="Path 1155"
      d="M82.214 35.764H46.119L34.087 23.733H10.023A9.025 9.025 0 0 0 1 32.756v48.127a9.025 9.025 0 0 0 9.023 9.023h72.191a9.025 9.025 0 0 0 9.023-9.023v-36.1a9.025 9.025 0 0 0-9.023-9.019Z"
      fill="none"
      stroke="#444155"
      strokeMiterlimit={10}
      strokeWidth={2}
    />
    <Path
      data-name="Path 1156"
      d="M65.279 64.44a16.849 16.849 0 1 1-16.848-16.843A16.848 16.848 0 0 1 65.279 64.44"
      fill="#f0f0f3"
    />
    <Path
      data-name="Path 1157"
      d="M47.752 83.013a18.567 18.567 0 1 1 18.567-18.57 18.563 18.563 0 0 1-18.567 18.57m0-34.739a16.171 16.171 0 1 0 16.172 16.169 16.17 16.17 0 0 0-16.172-16.171m9.585 16.621a.9.9 0 0 1-.9.9H49.1v7.337a.9.9 0 0 1-.9.9h-.9a.9.9 0 0 1-.9-.9v-7.339h-7.332a.9.9 0 0 1-.9-.9v-.9a.9.9 0 0 1 .9-.9h7.335v-7.333a.9.9 0 0 1 .9-.9h.9a.9.9 0 0 1 .9.9v7.337h7.337a.9.9 0 0 1 .9.9Z"
      fill="#444155"
    />
  </Svg>
)

export default IconCreateAFolderLargeAlt
