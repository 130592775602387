import React, { useEffect, useState } from 'react'
import { Status } from '../types'
import DiagnosticsResult from '../DiagnosticsResult'
import uuid from 'react-native-uuid'
import { storage } from '~providers/firebase'

export default function FirebaseStorageChecker(): JSX.Element {
  const [status, setStatus] = useState<Status>('inprogress')
  const [error, setError] = useState<Error>()

  useEffect(() => {
    let cancel = false

    async function ping() {
      try {
        const ref = storage().ref(`diagnostics`).child(`${uuid.v4()}.txt`)

        // Write "pong"
        await ref.putString('cG9uZw==', 'base64', {
          contentType: 'text/plain',
        })

        // Get URL
        const url = await ref.getDownloadURL()

        // Read
        const result = await fetch(url)
        const text = await result.text()
        if (result.status !== 200 || text !== 'pong') {
          throw new Error(
            `Unexpected download response [code=${result.status} data=${JSON.stringify(text)}]`,
          )
        }

        // Delete
        await ref.delete()

        if (!cancel) {
          setStatus('success')
          setError(undefined)
        }
      } catch (error) {
        if (!cancel) {
          setStatus('failed')
          setError(error)
        }
      }
    }

    ping()

    return () => {
      cancel = true
    }
  }, [])

  return (
    <DiagnosticsResult
      title="Firebase Storage"
      description="Read/write/delete operation on storage"
      status={status}
      error={error}
    />
  )
}
