import offlineConfig from '@redux-offline/redux-offline/lib/defaults'
import { Config, OfflineAction, OfflineState } from '@redux-offline/redux-offline/lib/types'
import { storage } from '~providers/firebase'
import { NOOP } from './offline/types'
import { remove, upload } from './storage/actions'

const getKey = (action: OfflineAction) => action.meta.offline.effect.key

const config: Partial<Config> = {
  ...offlineConfig,
  persistOptions: {
    blacklist: ['group', 'chat'],
  },
  effect: (effect, action) => {
    if (upload.match(effect)) {
      const {
        payload: { path, filename, uri },
      } = effect
      const ref = storage().ref(path).child(filename)

      return fetch(uri)
        .then((res) => res.blob())
        .then((blob) => ref.put(blob))
        .then(() => ref.getDownloadURL())
        .then((url) => ({
          url,
          path,
          filename,
        }))
    } else if (remove.match(effect)) {
      const {
        payload: { path, filename },
      } = effect
      const ref = storage().ref(path).child(filename)

      return ref.delete().then(() => ({}))
    } else {
      return Promise.reject(Error('Effect not handled'))
    }
  },
  discard: (error, action, retries) => {
    return true
  },
  queue: {
    ...offlineConfig.queue,
    enqueue: (
      array: OfflineAction[],
      item: OfflineAction,
      context: { offline: OfflineState },
    ): OfflineAction[] => {
      const key = getKey(item)
      if (key) {
        // Remove any offline actions that already exist for this key
        const newArray = array.filter((action) => !(getKey(action) === key))

        // Ignore noop actions
        if (NOOP !== item.meta.offline.effect.type) {
          newArray.push(item)
        }

        return newArray
      }

      return [...array, item]
    },
  },
  persist: () => false,
  detectNetwork: (callback) => {
    callback(true)
  },
  rehydrate: false,
}

export default config
